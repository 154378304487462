import { Dispatch, SetStateAction, useEffect, useMemo, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
	WaitlistSettingsContext,
} from '../../../../contexts/waitlist-settings/waitlist-settings.context'
import ColorInput from '../../../../components/color-input'
import { EmailTemplateInterface } from '../../../../contexts/waitlist-settings/types'
import CropImage, { CropType } from '../../../../components/crop-image'
import ToggleSwitch from '../../../../components/toggle-switch'
import WaitlistSettingsTextArea from '../../../../components/text-area'
import { BackgroundWrapper, InnerWrapper } from '../../../waitlist-settings/components/section-wrapper'
import { MainText, MarginBottom } from './styled'
import { EmailTemplatesType } from '../../../waitlist-settings/components/section-messages'

interface EmailTemplateProps {
	setBase64: Dispatch<SetStateAction<string>>
	state: EmailTemplateInterface
	setState: Dispatch<SetStateAction<EmailTemplateInterface>>
	setHasChanged: Dispatch<SetStateAction<boolean>>
}

enum ActionType {
	mainColor = 'mainColor',
	textColor = 'textColor',
	dateColor = 'dateColor',
	buttonColor = 'buttonColor',
	buttonTextColor = 'buttonTextColor',
	backgroundColor = 'backgroundColor',
}

const EditEmailTemplate = ({ setBase64, state, setState, setHasChanged }: EmailTemplateProps) => {
	const { type } = useParams()

	const { smsEntryMessage, smsCallMessage, smsDelayMessage, smsCancelMessage, smsSeatMessage } =
		useContext(WaitlistSettingsContext)

	let smsMessage = ''

	switch (type) {
		case EmailTemplatesType.ENTRY:
			smsMessage = smsEntryMessage
			break
		case EmailTemplatesType.CALL:
			smsMessage = smsCallMessage
			break
		case EmailTemplatesType.DELAY:
			smsMessage = smsDelayMessage
			break
		case EmailTemplatesType.CANCEL:
			smsMessage = smsCancelMessage
			break
		case EmailTemplatesType.SEAT:
			smsMessage = smsSeatMessage
			break
		default:
			break
	}

	const handleChange = useCallback(
		(e: any) => {
			setState(prevState => ({ ...prevState, template: { ...prevState.template, [e.target.name]: e.target.value } }))
			setHasChanged(true)
		},
		[setHasChanged, setState]
	)

	const handleUseSmsTextChange = () => {
		setState(prevState => ({ ...prevState, useSmsText: !prevState.useSmsText }))
		setHasChanged(true)
	}

	const handleMessageChange = (e: any) => {
		setState(prevState => ({ ...prevState, template: { ...prevState.template, message: e.target.value } }))
		setHasChanged(true)
	}

	useEffect(() => {
		if (state.useSmsText) {
			setState({ ...state, template: { ...state.template, message: smsMessage } })
			setHasChanged(true)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setHasChanged, setState, smsMessage, state.useSmsText])

	const handleMainColorInput = useMemo(() => {
		return <ColorInput name={ActionType.mainColor} color={state.template.mainColor} handleChange={handleChange} />
	}, [handleChange, state.template.mainColor])

	const handleTextColorInput = useMemo(() => {
		return <ColorInput name={ActionType.textColor} color={state.template.textColor} handleChange={handleChange} />
	}, [handleChange, state.template.textColor])

	const handleDateColorInput = useMemo(() => {
		return <ColorInput name={ActionType.dateColor} color={state.template.dateColor} handleChange={handleChange} />
	}, [handleChange, state.template.dateColor])

	const handleButtonColorInput = useMemo(() => {
		return <ColorInput name={ActionType.buttonColor} color={state.template.buttonColor} handleChange={handleChange} />
	}, [handleChange, state.template.buttonColor])

	const handleButtonTextColorInput = useMemo(() => {
		return (
			<ColorInput
				name={ActionType.buttonTextColor}
				color={state.template.buttonTextColor}
				handleChange={handleChange}
			/>
		)
	}, [handleChange, state.template.buttonTextColor])

	const handleBackgroundColorInput = useMemo(() => {
		return (
			<ColorInput
				name={ActionType.backgroundColor}
				color={state.template.backgroundColor}
				handleChange={handleChange}
			/>
		)
	}, [handleChange, state.template.backgroundColor])

	return (
		<BackgroundWrapper>
			<InnerWrapper>
				<MainText>Logo</MainText>
				<CropImage setBase64={setBase64} cropType={CropType.FREE_CROP} />
				<MarginBottom margin="20px" />
				<MainText>Cor do email</MainText>
				{handleMainColorInput}

				<MarginBottom margin="20px" />
				<MainText>Cor da mensagem de texto</MainText>
				{handleTextColorInput}
				<MarginBottom margin="20px" />
				<ToggleSwitch state={state.useSmsText} handleChange={handleUseSmsTextChange}>
					Utilizar o mesmo texto do SMS
				</ToggleSwitch>
				<WaitlistSettingsTextArea
					state={state.template.message}
					handleChange={handleMessageChange}
					disabled={state.useSmsText}
					maxLength={400}
					hasCharacterCounter={true}
				/>
				<MarginBottom margin="20px" />
				<MainText>Cor da data</MainText>
				{handleDateColorInput}

				<MarginBottom margin="20px" />
				<MainText>Cor do botão e link</MainText>
				{handleButtonColorInput}
				<MarginBottom margin="20px" />
				<MainText>Cor do texto do botão</MainText>
				{handleButtonTextColorInput}
				<MarginBottom margin="20px" />
				<MainText>Cor do background (área de fundo)</MainText>
				{handleBackgroundColorInput}
			</InnerWrapper>
		</BackgroundWrapper>
	)
}

export default EditEmailTemplate
