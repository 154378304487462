import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { WaitlistSettingsContextComponent } from '../contexts/waitlist-settings/waitlist-settings.context'
import { GlobalStyles } from '../styles/global'
import HistoryCustomersPage from '../pages/history-customers'
import WaitlistSettings from '../pages/waitlist-settings'
import HostessWaitlistPage from '../pages/hostess-waitlist'
import CreateNewTag from '../pages/create-tag'
import EmailTemplate from '../pages/email-template'
import ProfilePage from '../pages/profile'
import PrivateRoute from '../components/auth/private-route'
import { AuthContextComponent } from '../contexts/auth/auth.context'
import Reports from '../pages/reports'
import { UserRole } from '../types/user-role.type'
import Login from '../pages/login'
import Loggout from '../pages/loggout/indext'
import HomePage from '../pages/home'

function Router() {
	return (
		<>
			<GlobalStyles />
			<BrowserRouter>
				<Routes>
					<Route
						path="/"
						element={
							<HomePage />
						}
					/>
					<Route
						path="/operational/:venueId"
						element={
							<AuthContextComponent>
								<PrivateRoute authorizedRole={UserRole.VIEWER} outlet={<HostessWaitlistPage />} />
							</AuthContextComponent>
						}
					/>
					<Route
						path="/settings/:venueId"
						element={
							<AuthContextComponent>
								<WaitlistSettingsContextComponent>
									<PrivateRoute authorizedRole={UserRole.MANAGER} outlet={<WaitlistSettings />} />
								</WaitlistSettingsContextComponent>
							</AuthContextComponent>
						}
					/>
					<Route
						path="/profile/:id"
						element={
							<AuthContextComponent>
								<PrivateRoute authorizedRole={UserRole.VIEWER} outlet={<ProfilePage />} />
							</AuthContextComponent>
						}
					/>
					<Route
						path="/history"
						element={
							<AuthContextComponent>
								<PrivateRoute authorizedRole={UserRole.VIEWER} outlet={<HistoryCustomersPage />} />
							</AuthContextComponent>
						}
					/>
					<Route
						path="/tag/:type"
						element={
							<AuthContextComponent>
								<WaitlistSettingsContextComponent>
									<PrivateRoute authorizedRole={UserRole.MANAGER} outlet={<CreateNewTag />} />
								</WaitlistSettingsContextComponent>
							</AuthContextComponent>
						}
					/>

					<Route
						path="/email/:type"
						element={
							<AuthContextComponent>
								<WaitlistSettingsContextComponent>
									<PrivateRoute authorizedRole={UserRole.MANAGER} outlet={<EmailTemplate />} />
								</WaitlistSettingsContextComponent>
							</AuthContextComponent>
						}
					/>
					<Route
						path="/reports/:venueId"
						element={
							<AuthContextComponent>
								<PrivateRoute authorizedRole={UserRole.MANAGER} outlet={<Reports />} />
							</AuthContextComponent>
						}
					/>
					<Route path='/login' element={<AuthContextComponent><Login /></AuthContextComponent>} />
					<Route path="/loggout" element={<Loggout />} />
				</Routes>
			</BrowserRouter>
		</>
	)
}

export default Router
