import styled from 'styled-components/macro'

const MaxWidthWrapper = styled.div`
	max-width: calc(1020 / 16 * 1rem);
	padding: 0 16px;
	margin: 0 auto;
	width: 100%;
`

export default MaxWidthWrapper
