import styled from 'styled-components/macro'
import MaxWidthWrapper from '../../components/max-width-wrapper'
import { UnstyledButton } from '../../components/unstyled-button'
// import MaxWidthWrapper from '../max-width-wrapper'
// import { UnstyledButton } from '../unstyled-button'

export const Wrapper = styled(MaxWidthWrapper)`
	padding-left: 16px;
	padding-right: 16px;
`

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-top: 19px;
	padding-bottom: 19px;
`

export const Arrow = styled.div``

export const GoBack = styled.div`
	display: flex;
	gap: 8px;
	text-align: left;
	text-decoration: none;
	font-size: ${14 / 16}rem;

	&:visited,
	&:active {
		color: inherit;
	}

	cursor: pointer;
`

export const EditProfileLink = styled(UnstyledButton)`
	text-decoration: none;
	color: var(--color-primary);
	margin-left: auto;
`

export const WrapperModalContent = styled.div`
	padding: 20px 16px;
`
