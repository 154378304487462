import ButtonIcon from '../../../../../components/button-icon'
import { Note } from '../styled'

interface NoteDisplayProps {
	onShowModal: () => void
	customerTab?: string
	showCustomerTab?: boolean
}

const CustomerTabDisplay = ({ customerTab, onShowModal, showCustomerTab }: NoteDisplayProps) => {
	const name = showCustomerTab ? 'Com...' : 'Comanda'
	return (
		<Note>
			{customerTab !== undefined ? (
				<ButtonIcon id="EXCLAMATIONMARKCIRCLE" width="18px" type="button" onClick={onShowModal}>
					{name}
				</ButtonIcon>
			) : (
				<ButtonIcon id="OPLUS" width="18px" type="button" onClick={onShowModal}>
					{name}
				</ButtonIcon>
			)}
		</Note>
	)
}

export default CustomerTabDisplay
