import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ListWaitlistSettings from './components'
import SubmitFooter from './components/submit-footer'
import { HostessWrapper } from './styled'
import { VenueContext } from '../../contexts/venue/venue.context'
import { WaitlistSettingsContext } from '../../contexts/waitlist-settings/waitlist-settings.context'
import { validateCustomerTabs } from '../../utils/validate-customer-tabs'
import Navbar, { CurrentPageType } from '../../components/navbar'
import { WaitlistService } from '../../service/waitlist'


const WaitlistSettings: React.FC = () => {
	const { setVenueId } = useContext(VenueContext);
	const { venueId } = useParams();
	setVenueId(venueId as string);
	const [isEmpty, setIsEmpty] = useState(false);

	useEffect(() => {
		const fetchWaitlistList = async () => {
			const waitlistList = await WaitlistService.getWaitlistList(venueId as string)
			setIsEmpty(waitlistList.length === 0)
		}
		fetchWaitlistList()
	}, [venueId])

	const {
		queues,
		widgetTagmeMaxPartySize,
		firstTabNumber,
		lastTabNumber,
	} = useContext(WaitlistSettingsContext)

	const maxRange = queues[queues.length - 1].max

	let hasInconsistency = false

	if (widgetTagmeMaxPartySize > maxRange) hasInconsistency = true

	if (validateCustomerTabs(firstTabNumber, lastTabNumber).hasAnyInconsistency) hasInconsistency = true

	return (
		<HostessWrapper>
			<Navbar hasTitle={true} hasDropDown={true} hasGrid={true} venueId={venueId as string} currentPage={CurrentPageType.SETTINGS} />
			<ListWaitlistSettings isEmpty={isEmpty} />
			<SubmitFooter hasRangeInconsistency={hasInconsistency} />
		</HostessWrapper>
	)
}

export default WaitlistSettings
