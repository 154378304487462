import styled from 'styled-components'

export const TitleContainer = styled.div`
	display: flex;
	width: 100%;
	height: 58px;
	justify-content: start;
	align-items: center;
`

export const PageTitle = styled.h2`
	font-size: ${14 / 16}rem;
	line-height: 18px;
	color: var(--color-primary);
	font-weight: var(--font-weight-bold);
`

export const CalendarContainer = styled.div``

export const SpinnerContainer = styled.div`
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
`
