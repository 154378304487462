import { Dispatch, SetStateAction, useState, useContext, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { WaitlistSettingsContext } from '../../contexts/waitlist-settings/waitlist-settings.context'
import NavigateBack from '../../components/navigate-back'
import WaitlistSettingsSelector from '../waitlist-settings/components/selector'
import EditEmailTemplate from './components/edit-email-template'
import PreviewTemplate from './components/preview-template'
import { BackgroundWrapper, InnerWrapper, MainText, PreviewWrapper, Wrapper, HeaderWrapper } from './styled'
import { EmailTemplatesType } from '../waitlist-settings/components/section-messages'
import { EmailTemplateInterface } from '../../contexts/waitlist-settings/types'
import { VenueContext } from '../../contexts/venue/venue.context'
import { Button } from '../../components/button'
import { ImageUploadService } from '../../service/image-upload'
import FailModal from '../../components/fail-modal'
import { EmailTemplateService } from '../../service/email-template'
import Spinner from '../../components/spinner'

const EmailTemplate: React.FC = () => {
	const navigate = useNavigate()

	const [base64, setBase64] = useState('')
	const [hasChanged, setHasChanged] = useState(false)
	const [openSection, setOpenSection] = useState(true)
	const [showFailModal, setShowFailModal] = useState(false)
	const [loading, setLoading] = useState(false)

	const { type } = useParams()


	const {
		entryEmailTemplate,
		setEntryEmailTemplate,
		callEmailTemplate,
		setCallEmailTemplate,
		delayEmailTemplate,
		setDelayEmailTemplate,
		seatEmailTemplate,
		setSeatEmailTemplate,
		cancelEmailTemplate,
		setCancelEmailTemplate,
	} = useContext(WaitlistSettingsContext)

	const { venueId } = useContext(VenueContext)

	let state: EmailTemplateInterface
	let setState: Dispatch<SetStateAction<EmailTemplateInterface>>

	// Select the correct state and setState based on the type of email template obtained from the url
	switch (type) {
		case EmailTemplatesType.CALL:
			state = callEmailTemplate
			setState = setCallEmailTemplate
			break
		case EmailTemplatesType.DELAY:
			state = delayEmailTemplate
			setState = setDelayEmailTemplate
			break
		case EmailTemplatesType.CANCEL:
			state = cancelEmailTemplate
			setState = setCancelEmailTemplate
			break
		case EmailTemplatesType.SEAT:
			state = seatEmailTemplate
			setState = setSeatEmailTemplate
			break
		default:
			state = entryEmailTemplate
			setState = setEntryEmailTemplate
			break
	}

	useEffect(() => {
		const uploadImage = async () => {
			if (base64) {
				try {
					const response = await ImageUploadService.uploadImage(venueId, base64)

					setState(prevState => ({ ...prevState, template: { ...prevState.template, imageUrl: response.url } }))
					setHasChanged(true)
				} catch (error) {
					setShowFailModal(true)
				}
			}
		}
		uploadImage()
	}, [base64, setState, venueId])


	const handleSaveTemplate = async () => {
		const { templateId } = state

		if (!templateId) {
			let title = 'Waitlist default template'

			switch (type) {
				case EmailTemplatesType.ENTRY:
					title = 'Waitlist welcome template'
					break
				case EmailTemplatesType.CALL:
					title = 'Waitlist call template'
					break
				case EmailTemplatesType.DELAY:
					title = 'Waitlist delay template'
					break
				case EmailTemplatesType.CANCEL:
					title = 'Waitlist cancel template'
					break
				case EmailTemplatesType.SEAT:
					title = 'Waitlist seat template'
					break
				default:
					break
			}

			const newTemplate = {
				isEnabled: true,
				deleted: false,
				title,
				details: state.template,
				type: 'email',
				venueId,
			}

			try {
				setLoading(true)
				const response = await EmailTemplateService.createTemplate(newTemplate)
				setLoading(false)
				if (response._id) {
					setState(prevState => ({ ...prevState, templateId: response._id }))
				}
				setHasChanged(true)
				return navigate(`/settings/${venueId}`);
			} catch (error) {
				setShowFailModal(true)
			}

		}

		try {
			setLoading(true)
			if (templateId)
				await EmailTemplateService.updateTemplate(templateId, state.template)
			setLoading(false)
			return navigate(`/settings/${venueId}`);
		} catch (error) {
			setShowFailModal(true)
		}
	}

	return (
		<Wrapper>
			<BackgroundWrapper>
				<InnerWrapper>
					{loading ? <Spinner /> : <HeaderWrapper>
						<NavigateBack path={`/settings/${venueId}`} />
						<Button
							onClick={handleSaveTemplate}
							primary
							height="30px"
							width="102px"
							border="none"
							disabled={!hasChanged}
						>
							Salvar
						</Button>
					</HeaderWrapper>}


					<WaitlistSettingsSelector
						handleClick={() => setOpenSection(!openSection)}
						height="60px"
						fontWeight="var(--font-weight-bold)"
						padding="20px"
						hasArrow={true}
						open={openSection}
						rotateArrow={openSection}
					>
						Editar email
					</WaitlistSettingsSelector>
					{openSection && <EditEmailTemplate setBase64={setBase64} state={state} setState={setState} setHasChanged={setHasChanged} />}
				</InnerWrapper>
			</BackgroundWrapper>
			<PreviewWrapper>
				<BackgroundWrapper>
					<InnerWrapper>
						<MainText>Visual</MainText>
					</InnerWrapper>
				</BackgroundWrapper>

				<PreviewTemplate state={state} />
			</PreviewWrapper>
			{showFailModal && <FailModal onClose={() => setShowFailModal(false)} />}
		</Wrapper>
	)
}

export default EmailTemplate
