export const BREAKPOINTS = {
	mobile: 415,
	tablet: 650,
}
export const QUERIES = {
	tabletAndUp: `(min-width: ${BREAKPOINTS.tablet / 16}rem)`,
}

export enum WAITLIST_EVENT_TYPE {
	UPDATE_VIEW_HOSTESS = 'UPDATE_VIEW_HOSTESS',
	UPDATE_STATUS_HOSTESS = 'UPDATE_STATUS_HOSTESS',
}
