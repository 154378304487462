import { notAuthorized } from '../../utils/unauthorized'

const Loggout: React.FC = () => {
	localStorage.removeItem('ssoTagmeToken')

	notAuthorized()

	return <></>
}

export default Loggout
