import { convertArrayToString } from '../../../../utils/convert-array-to-string'
import { Details } from '../../types'
import { DetailsBasicText, DetailsBoldText, DetailsCardColumn, DetailsCardRow, DetailsListContainer, TableContainer } from './styled'


interface DetailCardProps {
    details: Details
    index: number
}

const DetailCard = (props: DetailCardProps) => {
    const { details, index } = props

    const { fullName,
        phoneNumber,
        email,
        profile,
        partySize,
        arriveDate,
        arriveTime,
        finalDate,
        finalTime,
        waitingTime,
        finalStatus,
        sentSmsAmount,
        firstSmsSentTime,
        origin,
        waitingPlaces,
        accomodationPlaces,
        tableNumber,
        customerTab,
        note } = details

    return (
        <DetailsCardRow index={index}>
            <DetailsCardColumn>
                {fullName && <DetailsBoldText>{fullName}</DetailsBoldText>}
                {phoneNumber && <DetailsBasicText>{phoneNumber}</DetailsBasicText>}
                {email && <DetailsBasicText>{email}</DetailsBasicText>}
            </DetailsCardColumn>
            <DetailsCardColumn>
                {profile.length > 0 && <DetailsBasicText>Perfil: {convertArrayToString(profile)}</DetailsBasicText>}
                {partySize && <DetailsBasicText>Qtd: {partySize}</DetailsBasicText>}
                {arriveDate && <DetailsBasicText>Dia Entrada: {arriveDate}</DetailsBasicText>}
                {arriveTime && <DetailsBasicText>Horário Entrada: {arriveTime}</DetailsBasicText>}
            </DetailsCardColumn>
            <DetailsCardColumn>
                {finalDate && <DetailsBasicText>Dia Final: {finalDate}</DetailsBasicText>}
                {finalTime && <DetailsBasicText>Horário Final: {finalTime}</DetailsBasicText>}
                {waitingTime && <DetailsBasicText>Tempo na fila: {waitingTime}</DetailsBasicText>}
                {finalStatus && <DetailsBasicText>Status final: {finalStatus}</DetailsBasicText>}
            </DetailsCardColumn>
            <DetailsCardColumn>
                {sentSmsAmount > 0 && <DetailsBasicText>SMS's enviados: {sentSmsAmount}</DetailsBasicText>}
                {firstSmsSentTime && <DetailsBasicText>Horário primeiro disparo: {firstSmsSentTime}</DetailsBasicText>}
                {origin && <DetailsBasicText>Origem: {origin}</DetailsBasicText>}
                {waitingPlaces.length > 0 && <DetailsBasicText>Local de espera: {convertArrayToString(waitingPlaces)}</DetailsBasicText>}
            </DetailsCardColumn>
            <DetailsCardColumn>
                {accomodationPlaces.length > 0 && <DetailsBasicText>Local de acomod.: {convertArrayToString(accomodationPlaces)}</DetailsBasicText>}
                {tableNumber && <DetailsBasicText>Mesa: {tableNumber}</DetailsBasicText>}
                {customerTab && <DetailsBasicText>Comanda: {customerTab}</DetailsBasicText>}
                {note && <DetailsBasicText>Observação: {note}</DetailsBasicText>}
            </DetailsCardColumn>
        </DetailsCardRow>
    )
}

interface DetailsListProps {
    detailsArray: Details[]
}

const DetailsList = (props: DetailsListProps) => {
    const { detailsArray } = props

    return (
        <TableContainer>
            <DetailsListContainer>{detailsArray?.map((details, index) => <DetailCard key={index} details={details} index={index} />)}</DetailsListContainer>
        </TableContainer>
    )
}

export default DetailsList