export class EmailTemplateFactory {
	static createEmpty(message?: string) {
		return {
			enabled: false,
			template: {
				imageUrl: '',
				mainColor: '#ffffff',
				textColor: '#696970',
				message: message || 'Olá',
				dateColor: '#30323b',
				buttonColor: '#ff7f40',
				buttonTextColor: '#ffffff',
				backgroundColor: '#eaeaea',
			},
			useSmsText: false,
			templateId: null,
		}
	}
}
