import { useCallback, useEffect, useMemo, useState } from 'react'
import IconNotification from '../icon-notification'

// Styled components
import { UnstyledButton } from '../../../../components/unstyled-button'
import { Button } from '../../../../components/button'
import {
	CardWrapper,
	InfoWrapper,
	Name,
	NameWrapper,
	PartySize,
	PartySizeWrapper,
	Position,
	SeeProfile,
	Row,
	Time,
	TooltipNote,
	IconNotificationWrapper,
	Notified,
	NotificationsList,
	NotificationWaitingTime,
	Seated,
	ButtonRestore,
	TooltipNewWaitlist,
	PositionAndNameWrapper,
	NoteAndActionsWrapper,
	Canceled,
	ExtendedNoteWrapper,
	Edited,
	SpinnerContainer,
} from './styled'

// React Components
import DisplayTag from '../tag'
import Modal from '../../../../components/modal'
import { ActionTypeLog, Waitlist } from '../../../../types/waitlist.interface'
import ModalContentButtons from '../modal-content-buttons'
import DeleteCustomer from '../modal-content-actions/delete'
import CallCustomer from '../modal-content-actions/call'
import SeatCustomer from '../modal-content-actions/seat'
import { millisecondsToTime } from '../../../../utils/milisecond-to-time'
import { getTime } from '../../../../utils/get-time'
import HandleTagsDisplay from './handle-tags-display'
import { lessThanSeconds } from '../../../../utils/less-than-seconds'
import { WaitlistSettings } from '../../../../types/waitlist-settings.interface'
import { AppliedTags } from '../../../../types/applied-tags.interface'
import { WaitlistService } from '../../../../service/waitlist'
import { CancelReason } from '../../../../types/cancel-reason.enum'
import EditWaitlist from '../modal-content-actions/edit'
import { UpdateWaitlistDto } from '../../../../service/waitlist/types'
import FailModal from '../../../../components/fail-modal'
import Spinner from '../../../../components/spinner'

enum Action {
	SEAT = 'seat',
	CALL = 'call',
	DELETE = 'delete',
	EDIT = 'edit',
}

const ShortNote = ({ note }: { note: string }) => {
	return (
		<UnstyledButton>
			<IconNotificationWrapper>
				<IconNotification />
				<TooltipNote>{note}</TooltipNote>
			</IconNotificationWrapper>
		</UnstyledButton>
	)
}

const LongNote = ({ note }: { note: string }) => {
	return <ExtendedNoteWrapper>{note}</ExtendedNoteWrapper>
}

interface CardWaitlistProps {
	waitlist: Waitlist
	waitlistSettings: WaitlistSettings | undefined
	onUpdateHistory?: () => void
	appliedTags?: AppliedTags
}

const CardWaitlist = ({ waitlist, waitlistSettings, appliedTags, onUpdateHistory }: CardWaitlistProps) => {
	let {
		id,
		position,
		// globalPosition,
		fullName,
		partySize,
		tags,
		customerTab,
		origin,
		note,
		history,
		notifiedAt,
		canceledAt,
		seatedAt,
		cancelReason,
		logs,
		arrivedAt,
		table,
		priority,
		phone
	} = waitlist
	// create states for which Modal to Show
	const [showModalButtons, setShowModalButtons] = useState(false)
	const [showModalActions, setShowModalActions] = useState(false)
	// create state for which component to choose (seat, call, delete)
	const [chooseComponentActions, setChooseComponentAction] = useState<Action>(Action.SEAT)

	// get waitingTime from arrivedAt Date
	const [waitingTime, setWaitingTime] = useState<string>(millisecondsToTime(Date.now() - arrivedAt.getTime()))

	const [edited, setEdited] = useState(false)

	const [showFailModal, setShowFailModal] = useState(false)
	const [loadingAction, setLoadingAction] = useState(false)

	// change raw data to string data
	const partySizeDisplay = partySize === 1 ? `${partySize} pessoa` : `${partySize} pessoas`
	const customerTabDisplay = `Comanda #${customerTab}`
	const originDisplay = `Origem: ${origin}`

	// get Times
	const timeSeated = seatedAt ? getTime(seatedAt) : false
	const timeNotified = notifiedAt ? getTime(notifiedAt) : false
	const timeCanceled = canceledAt ? getTime(canceledAt) : false
	let cancelReasonText = ''

	if (timeCanceled && cancelReason) {
		switch (cancelReason) {
			case CancelReason.CUSTOMER_REQUESTED:
				cancelReasonText = 'O cliente pediu para sair'
				break
			case CancelReason.CUSTOMER:
				cancelReasonText = 'O cliente se removeu'
				break
			case CancelReason.NO_SHOW:
				cancelReasonText = 'No show'
				break
			case CancelReason.VENUE_ERROR:
				cancelReasonText = 'Erro no cadastro'
				break
			case CancelReason.OTHER:
				cancelReasonText = 'Outros'
				break
		}
	}

	const notificationsTimes = logs
		?.filter(log => log.actionType === ActionTypeLog.CALL)
		?.map(log => log.created_at)
	const waitingTimeNotified = notificationsTimes.length
		? millisecondsToTime(Date.now() - notificationsTimes[0].getTime())
		: false

	useEffect(() => {
		if (!history) {
			const id = setInterval(() => {
				setWaitingTime(millisecondsToTime(Date.now() - arrivedAt.getTime()))
			}, 1000)

			return () => clearInterval(id)
		}
	}, [arrivedAt, history])

	const handleClickAction = useCallback((id: Action) => {
		setShowModalButtons(false)
		setShowModalActions(true)
		setEdited(false)
		setChooseComponentAction(id)
	}, [])

	const handleModalActions = useCallback(() => {
		setShowModalButtons(true)
		setShowModalActions(false)
	}, [])

	const handleClickCall = useCallback(
		async (customerTab, table) => {
			try {
				setLoadingAction(true)
				await WaitlistService.callWaitlist(id)
				await WaitlistService.updateWaitlist(id, { table, customerTab })
				setShowModalButtons(false)
				setShowModalActions(false)
				setLoadingAction(false)
			} catch (error) {
				setShowFailModal(true)
			}
		},
		[id]
	)

	const handleClickSeat = useCallback(async () => {
		try {
			setLoadingAction(true)
			await WaitlistService.seatWaitlist(id)
			setShowModalButtons(false)
			setShowModalActions(false)
			setLoadingAction(false)
		} catch (error) {
			setShowFailModal(true)
		}
	}, [id])

	const handleClickDelete = useCallback(
		async (cancelReason: CancelReason) => {
			try {
				setLoadingAction(true)
				await WaitlistService.cancelWaitlist(id, { cancelReason })
				setShowModalButtons(false)
				setShowModalActions(false)
				setLoadingAction(false)
			} catch (error) {
				setShowFailModal(true)
			}
		},
		[id]
	)

	const handleClickEdit = useCallback(
		async (waitlistId: string, updateWaitlist: UpdateWaitlistDto) => {
			try {
				setLoadingAction(true)
				await WaitlistService.updateWaitlist(waitlistId, updateWaitlist)
				setShowModalButtons(true)
				setShowModalActions(false)
				setEdited(true)
				setLoadingAction(false)
			} catch (error) {
				setShowFailModal(true)
			}
		},
		[]
	)

	// Creating new modal content based on each action (delete, call, seat) with custom propeties
	// useMemo is for performance issues
	const deleteCustomerMemo = useMemo(
		() => <DeleteCustomer name={fullName} onClose={handleModalActions} onConfirm={handleClickDelete} />,
		[fullName, handleModalActions, handleClickDelete]
	)


	// check if the number has a registered DDI
	const formatPhoneNumber = (phone: string | undefined): string => {
		if (!phone) {
			return '';
		}
		if (phone.startsWith('55')) {
			return phone;
		} else {
			return `+55${phone}`;
		}
	}

	const callCustomerMemo = useMemo(
		() => (
			<CallCustomer
				name={fullName}
				customerTab={customerTab}
				table={table}
				onConfirm={handleClickCall}
				onClose={handleModalActions}
				automatic={waitlistSettings?.customerTabs.automatic}
				phone={formatPhoneNumber(phone)}
			/>
		),
		[
			fullName,
			customerTab,
			table,
			handleClickCall,
			handleModalActions,
			waitlistSettings?.customerTabs.automatic,
			phone
		]
	)

	const seatCustomerMemo = useMemo(
		() => <SeatCustomer name={fullName} onClose={handleModalActions} onConfirm={handleClickSeat} />,
		[fullName, handleModalActions, handleClickSeat]
	)

	const handleGoBack = () => {
		setShowModalButtons(true)
		setShowModalActions(false)
	}

	const editCustomerMemo = useMemo(
		() => (
			<EditWaitlist
				waitlistSettings={waitlistSettings}
				waitlist={waitlist}
				onUpdateWaitlist={handleClickEdit}
				onGoBack={handleGoBack}
			/>
		),
		[handleClickEdit, waitlist, waitlistSettings]
	)

	const modalContentActions: Record<Action, JSX.Element> = {
		delete: deleteCustomerMemo,
		call: callCustomerMemo,
		seat: seatCustomerMemo,
		edit: editCustomerMemo,
	}
	const ChooseAction = modalContentActions[chooseComponentActions]

	const handleSave = async ({ customerTab, table }: { customerTab?: string; table?: string }) => {
		try {
			await WaitlistService.updateWaitlist(id, { table, customerTab })
			setShowModalButtons(false)
		} catch (error) {
			setShowFailModal(true)
		}
	}

	const handleRestore = async () => {
		try {
			await WaitlistService.restoreWaitlist(id)
			if (onUpdateHistory) {
				onUpdateHistory()
			}
		} catch (error) {
			setShowFailModal(true)
		}
	}

	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 720) // Breakpoint value
		}

		window.addEventListener('resize', handleResize)

		handleResize()

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	// if history priority is false
	priority = history ? false : priority

	const filterName = fullName.toLowerCase().includes(appliedTags?.name?.value)

	const filterPartySize =
		appliedTags?.partySizes.some(tag => partySize >= tag.value.min && partySize <= tag.value.max) || false

	const handleClose = () => {
		setShowModalButtons(false)
		setShowModalActions(false)
		setEdited(false)
	}

	const calculateTime = () => {
		if (history) {
			const finalTime = seatedAt || canceledAt || new Date()
			const finalWaitingTime = finalTime.getTime() - arrivedAt.getTime()

			return finalWaitingTime ? <Time>{millisecondsToTime(finalWaitingTime)}</Time> : null
		}

		return <Time>{waitingTime}</Time >
	}

	return (
		<>
			{showModalButtons && (
				<Modal onClose={handleClose}>
					{edited && <Edited>Dados Atualizados!</Edited>}
					<ModalContentButtons
						name={fullName}
						table={table}
						partySize={partySize}
						customerTab={customerTab}
						waitingTime={waitingTime}
						waitlistSettings={waitlistSettings}
						onDelete={() => handleClickAction(Action.DELETE)}
						onCall={() => handleClickAction(Action.CALL)}
						onSeat={() => handleClickAction(Action.SEAT)}
						onEdit={() => handleClickAction(Action.EDIT)}
						onSave={handleSave}
					/>
				</Modal>
			)}
			{showModalActions && (
				<Modal onClose={handleModalActions}>
					{loadingAction ? (
						<SpinnerContainer>
							<Spinner />
						</SpinnerContainer>
					) : (
						ChooseAction
					)}
				</Modal>
			)}
			<CardWrapper notified={!!timeNotified && !history} priority={priority} arrivedAt={arrivedAt}>
				{lessThanSeconds(arrivedAt, 5) && <TooltipNewWaitlist>Cliente inserido na fila</TooltipNewWaitlist>}

				{timeNotified && (
					<Notified>
						<NotificationsList>
							{notificationsTimes?.map((notificationTime, index) => (
								<span key={index}>
									{index + 1}ª chamada às {getTime(notificationTime)}
								</span>
							))}
						</NotificationsList>
						{!history && (
							<NotificationWaitingTime>Tempo total: {waitingTimeNotified}</NotificationWaitingTime>
						)}
					</Notified>
				)}
				{timeSeated && (
					<Seated>
						Sentado às {timeSeated}
						<ButtonRestore bg="var(--color-info)" onClick={handleRestore}>
							Voltar para lista
						</ButtonRestore>
					</Seated>
				)}
				{timeCanceled && (
					<Canceled>
						Cancelado às {timeCanceled} {cancelReasonText ? `(${cancelReasonText})` : false}
						<ButtonRestore bg="var(--color-danger)" onClick={handleRestore}>
							Voltar para lista
						</ButtonRestore>
					</Canceled>
				)}
				<InfoWrapper>
					<PositionAndNameWrapper>
						{!history && (
							<Position notified={!!timeNotified} priority={priority}>
								{position}°
							</Position>
						)}
						<NameWrapper>
							<Name $filter={filterName}>{fullName}</Name>
							<SeeProfile to={`/profile/${id}`}>Ver perfil</SeeProfile>
						</NameWrapper>
					</PositionAndNameWrapper>
					<PartySizeWrapper>
						<PartySize $filter={filterPartySize}>{partySizeDisplay}</PartySize>
						{calculateTime()}
						{table && <Time> • M{table}</Time>}
					</PartySizeWrapper>
					<NoteAndActionsWrapper>
						{note && (isMobile ? <ShortNote note={note} /> : <LongNote note={note} />)}
						{!history ? (
							<Button primary={true} width="51px" height="30px" onClick={() => setShowModalButtons(true)}>
								Ações
							</Button>
						) : (
							<div></div>
						)}
					</NoteAndActionsWrapper>
				</InfoWrapper>
				<Row>
					{customerTab && (
						<DisplayTag iconId="CUSTOMERTAB" name={customerTabDisplay} height="10px" width="1em" />
					)}
					<DisplayTag iconId="ORIGIN" name={originDisplay} height="10px" width="1em" />
				</Row>
				<Row>
					<HandleTagsDisplay tags={tags} appliedTags={appliedTags} />
				</Row>
				{showFailModal && <FailModal onClose={() => setShowFailModal(false)} />}
			</CardWrapper>
		</>
	)
}
export default CardWaitlist
