import { useState } from 'react'
import MessageSettings from './section-messages'
import WaitlistSettingsSelector from './selector'
import { SettingsWrapper, InnerWrapper } from './styled'
import AlertsSettings from './section-iterface-alerts'
import CustomerTabsSettings from './section-customer-tabs'
import QueueSettings from './section-queue-settings'
import TagsSettings from './section-tags'

export interface PartySize {
	min: number
	max: number
}

export enum OperationMode {
	BASIC = 'Fila única',
	ADVANCED = 'Múltiplas filas (Mais utilizado)',
}

const ListWaitlistSettings = ({ isEmpty }: { isEmpty: boolean }) => {
	const [openQueueSettings, setOpenQueueSettings] = useState(false)
	const [openMessageSettings, setOpenMessageSettigns] = useState(false)
	const [openAlertsSettings, setOpenAlertsSettings] = useState(false)
	const [openTagsSettings, setOpenTagsSettings] = useState(false)
	const [openTabsSettings, setOpenTabsSettings] = useState(false)


	return (

		<SettingsWrapper>
			<InnerWrapper>
				<WaitlistSettingsSelector
					handleClick={() => setOpenQueueSettings(!openQueueSettings)}
					height="60px"
					fontWeight="var(--font-weight-bold)"
					padding="20px"
					hasArrow={true}
					open={openQueueSettings}
					rotateArrow={openQueueSettings}
				>
					Lista de espera
				</WaitlistSettingsSelector>
				{openQueueSettings && <QueueSettings isEmpty={isEmpty} />}
			</InnerWrapper>

			<InnerWrapper>
				<WaitlistSettingsSelector
					handleClick={() => setOpenMessageSettigns(!openMessageSettings)}
					height="60px"
					fontWeight="var(--font-weight-bold)"
					padding="20px"
					hasArrow={true}
					open={openMessageSettings}
					rotateArrow={openMessageSettings}
				>
					Configuração da Comunicação com o cliente
				</WaitlistSettingsSelector>
				{openMessageSettings && <MessageSettings />}
			</InnerWrapper>

			<InnerWrapper>
				<WaitlistSettingsSelector
					handleClick={() => setOpenAlertsSettings(!openAlertsSettings)}
					height="60px"
					fontWeight="var(--font-weight-bold)"
					padding="20px"
					hasArrow={true}
					open={openAlertsSettings}
					rotateArrow={openAlertsSettings}
				>
					Informações na tela do cliente
				</WaitlistSettingsSelector>
				{openAlertsSettings && <AlertsSettings />}
			</InnerWrapper>

			<InnerWrapper>
				<WaitlistSettingsSelector
					handleClick={() => setOpenTagsSettings(!openTagsSettings)}
					height="60px"
					fontWeight="var(--font-weight-bold)"
					padding="20px"
					hasArrow={true}
					open={openTagsSettings}
					rotateArrow={openTagsSettings}
				>
					Tag para sinalização
				</WaitlistSettingsSelector>
				{openTagsSettings && <TagsSettings />}
			</InnerWrapper>

			<InnerWrapper>
				<WaitlistSettingsSelector
					handleClick={() => setOpenTabsSettings(!openTabsSettings)}
					height="60px"
					fontWeight="var(--font-weight-bold)"
					padding="20px"
					hasArrow={true}
					open={openTabsSettings}
					rotateArrow={openTabsSettings}
				>
					Comandas
				</WaitlistSettingsSelector>
				{openTabsSettings && <CustomerTabsSettings />}
			</InnerWrapper>
		</SettingsWrapper>
	)
}

export default ListWaitlistSettings
