import { Dispatch, SetStateAction, useContext, useEffect, useState, useCallback } from 'react'
import {
	WaitlistSettingsContext,
} from '../../../../contexts/waitlist-settings/waitlist-settings.context'
import { AlertInterface } from '../../../../contexts/waitlist-settings/types'
import { MarginBottom } from '../margin-botton/styled'
import ToggleSwitch from '../../../../components/toggle-switch'
import { BackgroundWrapper, InnerWrapper } from '../section-wrapper'
import WaitlistSettingsTextArea from '../../../../components/text-area'
import { customerApi } from '../../../../config/axios.config'

export enum SelectedLanguageEnum {
	PT = 'pt',
	EN = 'en',
}

const AlertsSettings = () => {
	const {
		generalInformationWidget,
		setGeneralInformationWidget,
		generalInformation,
		setGeneralInformation,
		priorityClient,
		setPriorityClient,
		standartClient,
		setStandartClient,
		callSoon,
		setCallSoon,
	} = useContext(WaitlistSettingsContext)

	const handleToggleSwitch = (setState: Dispatch<SetStateAction<AlertInterface>>) => {
		setState(prevState => ({ ...prevState, enabled: !prevState.enabled }))
	}

	const [generalInformationWidgetLanguage, setGeneralInformationWidgetLanguage] =
		useState<SelectedLanguageEnum>(SelectedLanguageEnum.PT)
	const [generalInformationLanguage, setGeneralInformationLanguage] = useState<SelectedLanguageEnum>(
		SelectedLanguageEnum.PT
	)
	const [priorityClientLanguage, setPriorityClientLanguage] = useState<SelectedLanguageEnum>(
		SelectedLanguageEnum.PT
	)
	const [standartClientLanguage, setStandartClientLanguage] = useState<SelectedLanguageEnum>(
		SelectedLanguageEnum.PT
	)
	const [callSoonLanguage, setCallSoonLanguage] = useState<SelectedLanguageEnum>(SelectedLanguageEnum.PT)

	const handleChange = (
		e: any,
		language: SelectedLanguageEnum,
		setState: Dispatch<SetStateAction<AlertInterface>>
	) => {
		setState(prevState => ({
			...prevState,
			message: { ...prevState.message, [`${language}`]: e.target.value },
		}))
	}

	const fetchData = useCallback(async (text: string, setState: Dispatch<SetStateAction<AlertInterface>>) => {
		const translation = await customerApi.post('/translation', {
			text,
		})
		setState(prevState => ({
			...prevState,
			message: { ...prevState.message, en: String(translation?.data) },
		}))
	}, [])

	useEffect(() => {
		if (generalInformationWidgetLanguage === SelectedLanguageEnum.EN) {
			fetchData(generalInformationWidget.message.pt, setGeneralInformationWidget)
		}
	}, [
		fetchData,
		generalInformationWidget.message.pt,
		generalInformationWidgetLanguage,
		setGeneralInformationWidget,
	])

	useEffect(() => {
		if (generalInformationLanguage === SelectedLanguageEnum.EN) {
			fetchData(generalInformation.message.pt, setGeneralInformation)
		}
	}, [fetchData, generalInformation.message.pt, generalInformationLanguage, setGeneralInformation])

	useEffect(() => {
		if (priorityClientLanguage === SelectedLanguageEnum.EN) {
			fetchData(priorityClient.message.pt, setPriorityClient)
		}
	}, [fetchData, priorityClient.message.pt, priorityClientLanguage, setPriorityClient])

	useEffect(() => {
		if (standartClientLanguage === SelectedLanguageEnum.EN) {
			fetchData(standartClient.message.pt, setStandartClient)
		}
	}, [fetchData, standartClient.message.pt, standartClientLanguage, setStandartClient])

	useEffect(() => {
		if (callSoonLanguage === SelectedLanguageEnum.EN) {
			fetchData(callSoon.message.pt, setCallSoon)
		}
	}, [fetchData, callSoon.message.pt, callSoonLanguage, setCallSoon])

	return (
		<BackgroundWrapper>
			<InnerWrapper>
				<MarginBottom margin="6px" />
				<ToggleSwitch
					state={generalInformationWidget.enabled}
					handleChange={() => handleToggleSwitch(setGeneralInformationWidget)}
				>
					Informações gerais widget
				</ToggleSwitch>
				<MarginBottom margin="6px" />
				<WaitlistSettingsTextArea
					state={generalInformationWidget.message[generalInformationWidgetLanguage]}
					handleChange={(e: any) =>
						handleChange(e, generalInformationWidgetLanguage, setGeneralInformationWidget)
					}
					language={generalInformationWidgetLanguage}
					setLanguage={setGeneralInformationWidgetLanguage}
					disabled={!generalInformationWidget.enabled}
				>
					Texto
				</WaitlistSettingsTextArea>
				<MarginBottom margin="30px" />
				<ToggleSwitch
					state={generalInformation.enabled}
					handleChange={() => handleToggleSwitch(setGeneralInformation)}
				>
					Informações gerais
				</ToggleSwitch>
				<MarginBottom margin="6px" />
				<WaitlistSettingsTextArea
					state={generalInformation.message[generalInformationLanguage]}
					handleChange={(e: any) => handleChange(e, generalInformationLanguage, setGeneralInformation)}
					language={generalInformationLanguage}
					setLanguage={setGeneralInformationLanguage}
					disabled={!generalInformation.enabled}
				>
					Texto
				</WaitlistSettingsTextArea>
				<MarginBottom margin="30px" />

				<ToggleSwitch
					state={priorityClient.enabled}
					handleChange={() => handleToggleSwitch(setPriorityClient)}
				>
					Cliente na fila prioridade
				</ToggleSwitch>
				<MarginBottom margin="6px" />
				<WaitlistSettingsTextArea
					state={priorityClient.message[priorityClientLanguage]}
					handleChange={(e: any) => handleChange(e, priorityClientLanguage, setPriorityClient)}
					language={priorityClientLanguage}
					setLanguage={setPriorityClientLanguage}
					disabled={!priorityClient.enabled}
				>
					Texto
				</WaitlistSettingsTextArea>
				<MarginBottom margin="30px" />

				<ToggleSwitch
					state={standartClient.enabled}
					handleChange={() => handleToggleSwitch(setStandartClient)}
				>
					Cliente na fila padrão
				</ToggleSwitch>
				<MarginBottom margin="6px" />
				<WaitlistSettingsTextArea
					state={standartClient.message[standartClientLanguage]}
					handleChange={(e: any) => handleChange(e, standartClientLanguage, setStandartClient)}
					language={standartClientLanguage}
					setLanguage={setStandartClientLanguage}
					disabled={!standartClient.enabled}
				>
					Texto
				</WaitlistSettingsTextArea>
				<MarginBottom margin="30px" />

				<ToggleSwitch state={callSoon.enabled} handleChange={() => handleToggleSwitch(setCallSoon)}>
					Cliente próximo de ser chamado
				</ToggleSwitch>
				<MarginBottom margin="6px" />
				<WaitlistSettingsTextArea
					state={callSoon.message[callSoonLanguage]}
					handleChange={(e: any) => handleChange(e, callSoonLanguage, setCallSoon)}
					language={callSoonLanguage}
					setLanguage={setCallSoonLanguage}
					disabled={!callSoon.enabled}
				>
					Texto
				</WaitlistSettingsTextArea>
			</InnerWrapper>
		</BackgroundWrapper>
	)
}

export default AlertsSettings
