import { Waitlist } from '../../../../../types/waitlist.interface'
import { WaitlistSettings } from '../../../../../types/waitlist-settings.interface'
import ListWaitlist from '../../list-waitlist'
import DisplayTag from '../../tag'
import Info from '../info'
import { BackgroundWrapper, ClearFilter, InnerWrapper, TagWrapper } from '../styled'
import { ApplyFilter } from '../../../../../types/apply-filter.interface'
import { AppliedTags } from '../../../../../types/applied-tags.interface'

interface DisplayWaitlistFilteredProps {
	waitlistSettings: WaitlistSettings | undefined
	waitlistList: Waitlist[]
	appliedTags: AppliedTags
	clearFilter: () => void
	applyfilter: (paramsFilter: ApplyFilter) => void
	venueId: string
}

const DisplayWaitlistFiltered = ({
	waitlistList,
	waitlistSettings,
	appliedTags,
	clearFilter,
	applyfilter,
	venueId
}: DisplayWaitlistFilteredProps) => {
	const { tags, partySizes, name } = appliedTags
	const hasName = name ? 1 : 0
	let totalTags = tags.length + partySizes.length + hasName

	const goBackIfNoTags = () => {
		// because of life cycle component, after clicked on remove, totalTags will still hold the last value
		// which was 1, so this actually menas that totalTags = 0 (after clicekd)
		if (totalTags === 1) {
			clearFilter()
		}
	}

	const removeTag = (e: any) => {
		const clickedId = e.currentTarget?.dataset.id
		const newTags = tags.filter(tag => tag.id !== clickedId)
		applyfilter({
			tags: newTags, name: name?.value || '', partySizes: partySizes || [], ...(waitlistSettings && {
				newSettings: {
					...waitlistSettings,
					tagsPlaces: waitlistSettings.tagsPlaces?.map(tag => ({ ...tag, active: tag.id === clickedId ? false : tag.active })),
					tagsPriorities: waitlistSettings.tagsPriorities?.map(tag => ({ ...tag, active: tag.id === clickedId ? false : tag.active }))
				}
			})
		})
		goBackIfNoTags()
	}

	const removePartySize = (e: any) => {
		const clickedId = e.currentTarget?.dataset.id
		const newPartySizes = partySizes.filter(tag => tag.id !== clickedId)
		applyfilter({
			tags, name: name?.value || '', partySizes: newPartySizes || [], ...(waitlistSettings && {
				newSettings: {
					...waitlistSettings, partySizesFilter: waitlistSettings.partySizesFilter?.map(partySize => ({ ...partySize, active: partySize.id === clickedId ? false : partySize.active }))
				}
			})
		})
		goBackIfNoTags()
	}

	const removeName = () => {
		applyfilter({ tags, name: '', partySizes })
		goBackIfNoTags()
	}
	return (
		<BackgroundWrapper>
			<InnerWrapper>
				Resultado do filtro: <Info waitlistList={waitlistList} />
				<TagWrapper>
					{totalTags !== 0 && <ClearFilter onClick={clearFilter}>Apagar filtros ({totalTags})</ClearFilter>}
					{tags.length !== 0 &&
						tags?.map(({ icon, display, id }) => (
							<DisplayTag
								iconId={icon}
								key={id}
								id={id}
								fill="var(--color-primary)"
								border="var(--color-primary)"
								color="var(--color-primary)"
								name={display}
								height="1em"
								width="1em"
								onClick={removeTag}
							/>
						))}
					{partySizes.length !== 0 &&
						partySizes?.map(({ icon, display, id }) => (
							<DisplayTag
								iconId={icon}
								key={id}
								id={id}
								fill="var(--color-primary)"
								border="var(--color-primary)"
								color="var(--color-primary)"
								name={display}
								height="1em"
								width="1em"
								onClick={removePartySize}
							/>
						))}
					{name && (
						<DisplayTag
							iconId={name.icon}
							key={name.id}
							fill="var(--color-primary)"
							border="var(--color-primary)"
							color="var(--color-primary)"
							name={name.display}
							height="1em"
							width="1em"
							onClick={removeName}
						/>
					)}
				</TagWrapper>
				<ListWaitlist
					waitlistList={waitlistList}
					waitlistSettings={waitlistSettings}
					appliedTags={appliedTags}
					venueId={venueId}
				/>
			</InnerWrapper>
		</BackgroundWrapper>
	)
}

export default DisplayWaitlistFiltered
