import { AppliedTags } from '../../../../types/applied-tags.interface'
import { Waitlist } from '../../../../types/waitlist.interface'
import { WaitlistSettings } from '../../../../types/waitlist-settings.interface'
import CardWaitlist from '../card-waitlist'
import { CardWrapper, NotFound } from './styled'

interface ListWaitlistProps {
	waitlistList: Waitlist[]
	waitlistSettings: WaitlistSettings | undefined
	onUpdateHistory?: () => void
	appliedTags?: AppliedTags,
	venueId: string,
	history?: boolean
}

const ListWaitlist = ({
	waitlistList,
	waitlistSettings,
	appliedTags,
	onUpdateHistory,
	venueId,
	history
}: ListWaitlistProps) => {

	let orderingWaitlist = waitlistList

	if (history) {
		orderingWaitlist = waitlistList.sort((a, b) => {
			const timeA = a.canceledAt || a.seatedAt || a.arrivedAt
			const timeB = b.canceledAt || b.seatedAt || a.arrivedAt
			return timeA.getTime() - timeB.getTime()
		})
	}

	const cardWaitlistItens = orderingWaitlist.map(waitlist => (
		<CardWaitlist
			key={waitlist.id}
			waitlist={waitlist}
			appliedTags={appliedTags}
			onUpdateHistory={onUpdateHistory}
			waitlistSettings={waitlistSettings}
		/>
	))

	const content =
		cardWaitlistItens.length !== 0 ? cardWaitlistItens : <NotFound>Nenhum cliente foi encontrado</NotFound>
	return <CardWrapper>{content}</CardWrapper>
}

export default ListWaitlist
