import { useState } from "react"
import { useParams } from "react-router-dom"
import Navbar, { CurrentPageType } from "../../components/navbar"
import { HostessWrapper } from "../waitlist-settings/styled"
import { PageTitle, SpinnerContainer, TitleContainer } from "./styled"
import ReportsForm from "./componets/form"
import MaxWidthWrapper from "../../components/max-width-wrapper"
import { ReportService } from "../../service/report"
import { Details, Form, Summary } from "./types"
import SummaryReport from "./componets/summary"
import DetailsList from "./componets/details"
import PageSelector from "./componets/pagination"
import { AddVenueId } from "../../types/add-venueId.type"
import Spinner from "../../components/spinner"
import FailModal from "../../components/fail-modal"

const Reports: React.FC = () => {
    const { venueId } = useParams()

    const [summary, setSummary] = useState<Summary | null>(null)
    const [details, setDetails] = useState<Details[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(17)
    const [limit] = useState<number>(10)
    const [form, setForm] = useState<Form | null>(null)
    const [showFailModal, setShowFailModal] = useState(false)
    const [loadingDetailed, setLoadingDetailed] = useState(false)


    const fetchDetails = async (form: AddVenueId<Form>, page: number) => {
        try {
            setLoadingDetailed(true)
            const reportsResponse = await ReportService.getDetailedReport({ ...form, page, limit })
            setDetails(reportsResponse?.data.waitlistCustomers)
            const totalPages = Math.ceil(reportsResponse?.data.totalItems / limit)
            setTotalPages(totalPages)
            setLoadingDetailed(false)
        } catch (error) {
            setShowFailModal(true)
        }
    }

    const fetchSummary = async (form: AddVenueId<Form>) => {
        try {
            const sumaryResponse = ReportService.getSummaryReport({ ...form })
            setSummary(await sumaryResponse)
        } catch (error) {
            setShowFailModal(true)
        }
    }

    const handleSubmit = async (form: Form) => {
        if (venueId) {
            fetchDetails({ venueId, ...form }, 1)
            fetchSummary({ venueId, ...form })
            setForm(form)
        }
    }

    const handlePageChange = async (page: number) => {
        setCurrentPage(page)
        if (venueId && form) {
            await fetchDetails({ venueId, ...form }, page)
        }
    }

    const showDetailedList = () => {
        if (details.length > 0 && !loadingDetailed)
            return <DetailsList detailsArray={details} />

        if (loadingDetailed)
            return <SpinnerContainer><Spinner /></SpinnerContainer>
    }

    return (
        <HostessWrapper>
            <Navbar hasTitle={true} hasDropDown={true} hasGrid={true} venueId={venueId as string} currentPage={CurrentPageType.REPORTS} />
            <MaxWidthWrapper>
                <TitleContainer>
                    <PageTitle>Relatórios</PageTitle>
                </TitleContainer>
                <ReportsForm onSubmit={handleSubmit} />
                {summary && <SummaryReport summary={summary} />}
                {showDetailedList()}
                {details.length > 0 && <PageSelector currentPage={currentPage} totalPages={totalPages} handleClick={handlePageChange}></PageSelector>}
            </MaxWidthWrapper>
            {showFailModal && <FailModal onClose={() => setShowFailModal(false)} />}
        </HostessWrapper >
    )
}

export default Reports