import styled from 'styled-components/macro'

export const GoBackWrapper = styled.div`
	display: flex;
	gap: 8px;
	text-align: left;
	text-decoration: none;
	font-size: ${14 / 16}rem;

	&:visited,
	&:active {
		color: inherit;
	}

	cursor: pointer;
`
