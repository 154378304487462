import styled from 'styled-components'

export const TableContainer = styled.table`
	display: flex;
	margin-top: 20px;
`

export const DetailsListContainer = styled.tbody`
	display: flex;
	width: 100%;
	flex-direction: column;
`

interface DetailsCardRowProps {
	index: number
}

export const DetailsCardRow = styled.tr<DetailsCardRowProps>`
	display: flex;
	justify-content: space-between;
	background-color: ${({ index }) => (index % 2 === 0 ? 'var(--color-white)' : 'var(--color-gray-mid)')};
	overflow-x: auto;
`

export const DetailsCardColumn = styled.td`
	display: flex;
	gap: 5px;
	min-width: 120px;
	width: 150px;
	flex-direction: column;
	padding: 10px;
`

export const DetailsBoldText = styled.span`
	font-size: ${12 / 16}rem;
	font-weight: var(--font-weight-bold);
`

export const DetailsBasicText = styled.span`
	font-size: ${10 / 16}rem;
`
