import { useCallback, useContext, useEffect, useRef } from 'react'
import { SelectorWrapper, ButtonWrapper, AlertsContainer } from './styled'
import { Button } from '../../../../components/button'
import { OperationMode } from '..'
import { WaitlistSettingsContext } from '../../../../contexts/waitlist-settings/waitlist-settings.context'
import PartySizeRange from '../party-size-range'
import { BackgroundWrapper, InnerWrapper } from '../section-wrapper'
import Dropdown from '../../../../components/dropdown'
import { SettingsLabel } from '../label-wrapper'
import { AlertWrapper } from '../../../../components/alert-wrapper'
import ToggleSwitch from '../../../../components/toggle-switch'
import { ToggleSwitchWrapper } from './styled'
import { HorizontalRule } from '../horinzontal-rule/styled'
import { WaitingTimeMode } from '../../../../contexts/waitlist-settings/types'
import { SmallInput } from '../../../../components/form/small-input/styled'
import BasicPartySizeRange from '../basic-party-size-range'
import WaitlistSchedule from './components/waitlist-schedule'

const QueueSettings = ({ isEmpty }: { isEmpty: boolean }) => {
	const {
		queues,
		setQueues,
		waitlistActive,
		setWaitlistActive,
		selectedOperationMode,
		setSelectedOperationMode,
		showWaitingTimeWidget,
		setShowWaitingTimeWidget,
		waitingTimeWidgetMode,
		setWaitingTimeWidgetMode,
		delayTolerance,
		setDelayTolerance,
		hasAutomaticRange,
		setHasAutomaticRange,
		widgetTagmeMaxPartySize,
		setWidgetTagmeMaxPartySize,
		// googleActive,
		// setGoogleActive,
	} = useContext(WaitlistSettingsContext)

	const queuesRef = useRef(queues);

	useEffect(() => {
		if (selectedOperationMode === OperationMode.BASIC && queuesRef.current.length > 1) {
			setQueues([{ min: 1, max: 99 }]);
		}
	}, [selectedOperationMode, setQueues]);

	useEffect(() => {
		queuesRef.current = queues;
	}, [queues]);

	const maxRange = 99

	const generatePartySizesRanges = useCallback(() => {
		if (selectedOperationMode === OperationMode.ADVANCED) {
			return queues?.map((_, idx) => {
				return (
					<PartySizeRange
						range={maxRange}
						advancedOperation={true}
						hasExclusion={true}
						enabled={true}
						key={idx}
						index={idx}
						hasAboveRuleLabel={idx === 0 ? true : false}
					/>
				)
			})
		}

		return <PartySizeRange range={maxRange} advancedOperation={true} hasExclusion={false} enabled={true} index={0} hasAboveRuleLabel={true} />
	}, [queues, selectedOperationMode])

	const addNewQueue = () => {
		let initialRange = 1

		if (queues.length) {
			initialRange = queues[queues.length - 1].max + 1
		}

		if (initialRange >= maxRange) {
			return alert('You can no longer add new queues')
		}

		const newQueues = [...queues]
		newQueues.push({ min: initialRange, max: initialRange })
		setQueues(newQueues)
	}

	const handleMaxPartySizeChange = (value: number) => {
		setWidgetTagmeMaxPartySize(value)
	}

	return (
		<BackgroundWrapper>
			<InnerWrapper>
				<SelectorWrapper>
					<AlertsContainer>
						<AlertWrapper>
							Capacidade máxima de 99 pessoas na Lista de espera.
						</AlertWrapper>
						{!isEmpty && <AlertWrapper isWarning={true}>
							Para modificar as configurações de tipo de fila (única ou múltipla), é necessário remover todos os clientes da lista.
						</AlertWrapper>}
					</AlertsContainer>
					{isEmpty && <>
						<SettingsLabel>Tipo</SettingsLabel>
						<Dropdown
							state={selectedOperationMode}
							setState={setSelectedOperationMode}
							options={Object.values(OperationMode)}
							height="46px"
							enabled={true}
							placeHolder="Selecione modelo"
						/>
					</>
					}
				</SelectorWrapper>

				{selectedOperationMode === OperationMode.ADVANCED && isEmpty && (
					<ButtonWrapper>
						<Button
							height="32px"
							bg="var(--color-white)"
							color="var(--color-primary)"
							border="2px solid var(--color-primary)"
							onClick={addNewQueue}
						>
							Adicionar nova fila
						</Button>
					</ButtonWrapper>
				)}

				{isEmpty && generatePartySizesRanges()}
			</InnerWrapper>
			<HorizontalRule />
			<InnerWrapper>
				<ToggleSwitchWrapper>
					<ToggleSwitch state={showWaitingTimeWidget} setState={setShowWaitingTimeWidget}>
						Exibição do tempo de espera para cliente
					</ToggleSwitch>
				</ToggleSwitchWrapper>
				{showWaitingTimeWidget && (<>
					<SettingsLabel>Selecione o formato</SettingsLabel>
					<Dropdown
						state={waitingTimeWidgetMode}
						setState={setWaitingTimeWidgetMode}
						options={Object.values(WaitingTimeMode)}
						height="46px"
						enabled={showWaitingTimeWidget}
						placeHolder="Selecione modelo de exibição"
					/>
				</>)}

				<SettingsLabel>Tolerância para atraso do cliente (minutos)</SettingsLabel>
				<SmallInput
					value={delayTolerance}
					placeholder="tempo em minutos"
					onChange={(e: any) => setDelayTolerance(parseInt(e.target.value))}
					type='number'
					min='0'
					step='1'
				/>

			</InnerWrapper>
			<HorizontalRule />
			<InnerWrapper>
				<AlertWrapper>
					IMPORTANTE: O agendamento implicará na abertura e fechamento automatizado da lista de espera
				</AlertWrapper>
				<WaitlistSchedule />
				<HorizontalRule />
				<ToggleSwitchWrapper>
					<ToggleSwitch state={waitlistActive} setState={setWaitlistActive}>
						Lista de espera online
					</ToggleSwitch>
				</ToggleSwitchWrapper>
				<HorizontalRule />
				<ToggleSwitchWrapper>
					<ToggleSwitch state={hasAutomaticRange} handleChange={() => setHasAutomaticRange(!hasAutomaticRange)} disabled={!waitlistActive}>
						Utilizar a mesma configuração da lista de espera presencial
					</ToggleSwitch>
				</ToggleSwitchWrapper>
				{!hasAutomaticRange && (<BasicPartySizeRange
					maxRange={queues[queues.length - 1].max}
					disabled={hasAutomaticRange || !waitlistActive}
					hasWarning={widgetTagmeMaxPartySize > queues[queues.length - 1].max}
					maxPartySize={widgetTagmeMaxPartySize}
					handleMaxPartySizeChange={handleMaxPartySizeChange}
				/>)}
				{/* <HorizontalRule /> */}
				{/* <ToggleSwitchWrapper>
					<ToggleSwitch state={googleActive} setState={setGoogleActive} disabled={!waitlistActive}>
						Google
					</ToggleSwitch>
				</ToggleSwitchWrapper> */}
			</InnerWrapper>


		</BackgroundWrapper>
	)
}

export default QueueSettings
