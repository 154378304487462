import { useState } from 'react'
import useInput from '../../../../hooks/use-input.hook'
import { Waitlist } from '../../../../types/waitlist.interface'
import { WaitlistSettings } from '../../../../types/waitlist-settings.interface'
import { FullNameAdapter } from '../../../../adapters/full-name.adapter'
import { ValidationInputAdapter } from '../../../../adapters/validation-input.adapter'
import { Button } from '../../../../components/button'
import Input from '../../../../components/form/input'
import { SubmitButton, WrapperModalContent } from '../../../hostess-waitlist/components/form-waitlist/styled'
import { ModalContentForm, Sizes } from '../../../hostess-waitlist/components/modal-content-form'
import useModalContent from '../../../hostess-waitlist/components/modal-content-form/use-modal-content.hook'
import Modal from '../../../../components/modal'
import { FormWrapper, Title, WrapperEditProfile } from './styled'
import { CreateWaitlistDto } from '../../../../service/waitlist/types'

type UpdateCustomer = Partial<Pick<CreateWaitlistDto, 'customer'>>

interface EditProfileProps {
	waitlist: Waitlist | undefined
	waitlistSettings: WaitlistSettings | undefined
	onUpdateCustomer: (updateCustomer: UpdateCustomer) => void
}

type ID_COMPONENTS_FORM = 'places' | 'priorities'

const EditProfile = ({ waitlist, waitlistSettings, onUpdateCustomer }: EditProfileProps) => {
	// Modal States
	const [showModalForm, setShowModalForm] = useState(false)
	const closeModalForm = () => setShowModalForm(false)
	const [chooseComponentForm] = useState<ID_COMPONENTS_FORM>('priorities')

	const [tagsPlaces, handleTagsPlacesToggle] = useModalContent(
		{
			data: waitlistSettings?.tagsPlaces,
			callback: closeModalForm
		}
	)

	const [tagsPriorities, handleTagPriorityToggle] =
		useModalContent({ data: waitlistSettings?.tagsPriorities, callback: closeModalForm })

	// FORM States
	// Use Input Hook - Phone
	const [phone, phoneIsValid, phoneHasError, phoneChangeHandler, phoneBlurHandler] = useInput(
		ValidationInputAdapter.checkPhoneIsValid,
		waitlist?.phone
	)

	// Use Input Hook - fullName
	const [
		fullName,
		fullNameIsValid,
		fullNameHasError,
		fullNameChangeHandler,
		fullNameBlurHandler
	] = useInput(
		ValidationInputAdapter.checkNameIsValid,
		waitlist?.fullName
	)

	// Use Input Hook - Email
	const [email, emailIsValid, emailHasError, emailChangeHandler, emailBlurHandler] = useInput(
		ValidationInputAdapter.checkEmailIsValid,
		waitlist?.email
	)

	const PlacesModalContent = () => (
		<WrapperModalContent>
			<ModalContentForm
				info="Local de Espera"
				data={tagsPlaces}
				onClick={handleTagsPlacesToggle}
				size={Sizes.SMALL}
			/>
		</WrapperModalContent>
	)

	const TypeCustomerModalContent = () => (
		<WrapperModalContent>
			<ModalContentForm
				info="Tipo de Cliente"
				data={tagsPriorities}
				onClick={handleTagPriorityToggle}
				size={Sizes.SMALL}
			/>
		</WrapperModalContent>
	)

	// Asssign ID component to Functional Component on Map (like object {})
	const chooseModalFormComponent: Record<ID_COMPONENTS_FORM, () => JSX.Element> = {
		priorities: TypeCustomerModalContent,
		places: PlacesModalContent,
	}
	const ComponentModalForm = chooseModalFormComponent[chooseComponentForm]

	//FIXME: I think this is incorrect, think a better way to validate those forms
	const formIsValid = fullNameIsValid || emailIsValid || phoneIsValid

	const [name, lastName] = FullNameAdapter.set(fullName).getNameAndLastName()
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!formIsValid) {
			return
		}
		const updateCustomer = {
			customer: {
				name,
				lastName,
				email,
				phone,
			},
		}
		onUpdateCustomer(updateCustomer)
	}
	return (
		<>
			{showModalForm && (
				<Modal onClose={closeModalForm} >
					<ComponentModalForm />
				</Modal>
			)}
			<WrapperEditProfile>
				<Title>Dados do Cliente</Title>
				<FormWrapper noValidate onSubmit={handleSubmit}>
					<Input
						name="nome"
						placeholder="Nome e sobrenome"
						type="text"
						value={fullName}
						onChange={fullNameChangeHandler}
						onBlur={fullNameBlurHandler}
						isValid={fullNameIsValid}
						hasError={fullNameHasError}
					/>

					<Input
						name="email"
						placeholder="Email"
						type="email"
						value={email}
						onChange={emailChangeHandler}
						onBlur={emailBlurHandler}
						isValid={emailIsValid}
						hasError={emailHasError}
					/>
					<Input
						name="phone"
						placeholder="Telefone com DDD"
						mask="(99) 99999-9999"
						type="tel"
						value={phone}
						onChange={phoneChangeHandler}
						onBlur={phoneBlurHandler}
						isValid={phoneIsValid}
						hasError={phoneHasError}
					/>
					{/* <ButtonsWrapper> */}
					{/* 	<ButtonIcon */}
					{/* 		id="OPLUS" */}
					{/* 		type="button" */}
					{/* 		width="18px" */}
					{/* 		fill="primary" */}
					{/* 		onClick={() => handleChooseComponentForm('places')} */}
					{/* 		size={tagsPlacesActive.length} */}
					{/* 	> */}
					{/* 		Localização */}
					{/* 	</ButtonIcon> */}
					{/* 	<ButtonIcon */}
					{/* 		type="button" */}
					{/* 		id="OPLUS" */}
					{/* 		width="18px" */}
					{/* 		fill="primary" */}
					{/* 		onClick={() => handleChooseComponentForm('priorities')} */}
					{/* 		size={tagsPrioritiesActive.length} */}
					{/* 	> */}
					{/* 		Tipo de Cliente */}
					{/* 	</ButtonIcon> */}
					{/* </ButtonsWrapper> */}
					<SubmitButton>
						<Button primary width={'100%'} height={'100%'} type="submit" disabled={!formIsValid}>
							Atualizar cliente
						</Button>
					</SubmitButton>
				</FormWrapper>
			</WrapperEditProfile>
		</>
	)
}

export default EditProfile
