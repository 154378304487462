import { useEffect, useState } from 'react'
import { DataModalContent } from '../../../../types/data-modal-content-form.interface'

type useModalReturn = [
	DataModalContent[],
	(event: React.MouseEvent<HTMLButtonElement>) => void,
	DataModalContent[],
	() => void
]

interface Props {
	data: DataModalContent[] | undefined
	callback?: () => void
	initiallySelected?: DataModalContent[] | undefined
}

const useModalContent = ({ data, callback, initiallySelected }: Props): useModalReturn => {
	const [list, setList] = useState<DataModalContent[]>(data || [])

	// Use effect shouldn't be necessary here
	useEffect(() => {
		if (data)
			setList(
				data?.map(tag => {
					if (
						initiallySelected?.find(
							selectedTag =>
								selectedTag.display === tag.display.replace('Espera: ', '').replace('Local: ', '')
						)
					) {
						return {
							...tag,
							active: true,
						}
					}
					return tag
				})
			)
	}, [data, initiallySelected])

	const handleListActive = (event: React.MouseEvent<HTMLButtonElement>) => {
		const id = event.currentTarget?.dataset.id
		const newList = list?.map(item => {
			if (item.id === id) {
				const updatedItem = {
					...item,
					active: !item.active,
				}
				return updatedItem
			}
			return item
		})
		setList(newList)
		if (callback) callback()
	}

	const reset = () => {
		setList(list?.map(element => ({ ...element, active: false })))
	}

	const listActive = list.filter(element => element.active === true)

	return [list, handleListActive, listActive, reset]
}

export default useModalContent
