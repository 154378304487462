export const validateCustomerTabs = (firstTabNumber: string, lastTabNumber: string) => {
	const initialTabNumber = firstTabNumber ? parseInt(firstTabNumber) : -1
	const finalTabNumber = lastTabNumber ? parseInt(lastTabNumber) : 0

	const hasInitialTabNumberInconsistency = initialTabNumber < 0
	const hasFinalTabNumberInconsistency = finalTabNumber !== 0 && finalTabNumber < initialTabNumber

	return {
		hasInitialTabNumberInconsistency,
		hasFinalTabNumberInconsistency,
		hasAnyInconsistency: hasInitialTabNumberInconsistency || hasFinalTabNumberInconsistency,
	}
}
