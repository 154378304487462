import Icon from '../../../../components/icon'
import { IconWrapper, Selector, SelectorName, Warning } from './styled'

export interface WaitlistSettingsSelectorProps {
	children?: string | number
	handleClick?: any
	height?: string
	iconHeight?: string
	iconWidth?: string
	fontSize?: string
	fontWeight?: string
	iconColor?: string
	padding?: string
	hasArrow?: boolean
	rotateArrow?: boolean
	color?: string
	backgroundColor?: string | boolean
	hasInconsistency?: boolean
	hasWarning?: boolean
	open?: boolean
}

const WaitlistSettingsSelector = ({
	children: name,
	handleClick,
	height,
	iconHeight,
	iconWidth,
	fontSize,
	fontWeight,
	iconColor,
	padding,
	hasArrow,
	rotateArrow,
	color,
	backgroundColor,
	hasInconsistency,
	hasWarning,
	open,
}: WaitlistSettingsSelectorProps) => {
	return (
		<Selector
			height={height}
			padding={padding}
			backgroundColor={backgroundColor}
			onClick={() => handleClick()}
			hasInconsistency={hasInconsistency}
			open={open}
		>
			<SelectorName fontWeight={fontWeight} fontSize={fontSize} color={color}>
				{name}
			</SelectorName>
			{hasWarning && <Warning>!</Warning>}
			{hasArrow && (
				<IconWrapper rotateArrow={rotateArrow}>
					<Icon fill={iconColor} width={iconWidth || '16px'} height={iconHeight || '8px'} id="ARROWDOWN" />
				</IconWrapper>
			)}
		</Selector>
	)
}

export default WaitlistSettingsSelector
