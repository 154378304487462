import styled from 'styled-components/macro'
import MaxWidthWrapper from '../../../../components/max-width-wrapper'

export const HostessWrapper = styled.div`
	height: 100%;
`

export const BackgroundWrapper = styled.div`
	background-color: var(--color-gray-light);
`

export const InnerWrapper = styled(MaxWidthWrapper)`
	font-size: ${14 / 16}rem;
	padding: 16px;
	height: 100%;
`

export const CardWrapper = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 0;
	list-style: none;

	/* min-width: 343px; */
	/* overflow: auto; */
`

export const TagWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
	gap: 5px;
`

export const ClearFilter = styled.div`
	font-size: ${12 / 16}rem;
	border-radius: 100px;
	background-color: var(--color-primary);
	color: var(--color-white);
	cursor: pointer;
	white-space: nowrap;
	padding: 2px 5px;
`
