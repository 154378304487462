import styled from 'styled-components/macro'

export const SwitchWaitlistWrapper = styled.div<{ state: boolean }>`
	display: flex;
	gap: 7px;
	align-items: center;
	color: ${props => (props.state ? 'var(--color-success)' : 'var(--color-danger)')};
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	cursor: pointer;
`
