import { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApplyFilter, PartySize } from '../../types/apply-filter.interface'
import { Waitlist } from '../../types/waitlist.interface'
import { AppliedTags } from '../../types/applied-tags.interface'
import { WaitlistSettings } from '../../types/waitlist-settings.interface'
import { FilterWaitlistAdapter } from '../../adapters/filter.adapter'
import { waitlistSettingsAdapter } from '../../adapters/waitlist-settings.adapter'
import { waitlistListAdapter } from '../../adapters/waitlist.adapter'
import { connectWebsocket } from '../../adapters/websocket.service'
import { HostessWrapper } from './styled'
import DisplayWaitlistFiltered from './components/display/waitlist-filtered'
import DisplayWaitlist from './components/display/waitlist'
import FormWaitlist from './components/form-waitlist'
import Navbar, { CurrentPageType } from '../../components/navbar'
import Filter from './components/filter'
import Modal from '../../components/modal'
import { WAITLIST_EVENT_TYPE } from '../../utils/constants'
import { WaitlistSettingsService } from '../../service/waitlist-settings'
import { WaitlistService } from '../../service/waitlist'
import { CreateWaitlistDto } from '../../service/waitlist/types'
import { VenueContext } from '../../contexts/venue/venue.context'
import { AuthContext } from '../../contexts/auth/auth.context'
import { UserRole } from '../../types/user-role.type'
import { isAuthorized } from '../../utils/authorize-role'
import FailModal from '../../components/fail-modal'
import Spinner from '../../components/spinner'

const initialAppliedTagsState: AppliedTags = {
	tags: [],
	partySizes: [],
	name: null,
}

const HostessWaitlistPage: React.FC = () => {
	const [waitlistList, setWaitlistList] = useState<Waitlist[]>([])
	const [waitlistSettings, setWaitlistSettings] = useState<WaitlistSettings | undefined>()
	const [showModalFilter, setShowModalFilter] = useState(false)
	const [showWaitlistFiltered, setShowWaitlistFiltered] = useState(false)
	const [waitlistListFiltered, setWaitlistListFiltered] = useState<Waitlist[]>([])
	const [appliedTags, setAppliedTags] = useState<AppliedTags>(initialAppliedTagsState)
	const [showFailModal, setShowFailModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const { venueId } = useParams()
	const authContext = useContext(AuthContext)
	const { setVenueId } = useContext(VenueContext)
	const userRole = authContext?.ssoTagmeToken?.role || UserRole.VIEWER
	const initialWaitlistSettingsRef = useRef<WaitlistSettings | null>(null)

	useEffect(() => {
		setVenueId(venueId as string);
	}, [venueId, setVenueId])

	const fetchWaitlistData = useCallback(async () => {
		try {
			setLoading(true)
			const results = await WaitlistService.getWaitlistList(venueId as string)
			setWaitlistList(waitlistListAdapter(results))
			setLoading(false)
		} catch (error) {
			setShowFailModal(true)
		}
	}, [venueId])

	const fetchWaitlistSettingsData = useCallback(async () => {
		try {
			const results = await WaitlistSettingsService.getWaitlistSettingsForHostess(venueId as string)
			const waitlistSettings = waitlistSettingsAdapter(results)
			initialWaitlistSettingsRef.current = waitlistSettings
			setWaitlistSettings(waitlistSettings)
		} catch (error) {
			setShowFailModal(true)
		}
	}, [venueId])

	const handleSocketIoConnection = useCallback((venueId: string): SocketIOClient.Socket => {
		const options = {
			query: {
				venueId,
			},
			path: '/waitlistSocket/',
			transports: ['websocket'],
		}

		// Connecting the websocket through the customersApi
		const url = `${process.env.REACT_APP_CUSTOMER_BASE_URL}/waitlist`
		const socket = connectWebsocket(url, options)

		socket.on('connect', () => console.log('connected to websocket'))
		socket.on('disconnect', () => console.log('disconnect from websocket'))

		socket.on(WAITLIST_EVENT_TYPE.UPDATE_VIEW_HOSTESS, (content: any) => {
			const { waitlistList: updatedWaitlistList } = content
			setWaitlistList(waitlistListAdapter(updatedWaitlistList))
		})
		return socket
	}, [])

	// Esablish the connection with the socketIo from the CommunicationsApi
	useEffect(() => {
		const socket: SocketIOClient.Socket = handleSocketIoConnection(venueId as string)

		return () => {
			socket.close()
		}
	}, [handleSocketIoConnection, venueId])

	useEffect(() => {
		fetchWaitlistData()
		fetchWaitlistSettingsData()
	}, [fetchWaitlistData, fetchWaitlistSettingsData])

	const handleAddWaitlist = async (createWaitlist: CreateWaitlistDto) => {
		try {
			setLoading(true)
			await WaitlistService.createWaitlist(venueId as string, createWaitlist)
			const results = await WaitlistService.getWaitlistList(venueId as string)
			setWaitlistList(waitlistListAdapter(results))
			setLoading(false)
		} catch (error) {
			setShowFailModal(true)
		}
	}

	const handleApplyFilter = ({ name, tags, partySizes, newSettings }: ApplyFilter) => {
		setShowWaitlistFiltered(true)
		setShowModalFilter(false)
		const tagsValues: string[] = tags?.map(({ value }) => value)
		const partySizesValues: PartySize[] = partySizes?.map(({ value }) => value)

		const waitlistFiltered = new FilterWaitlistAdapter(waitlistList)
			.filterByTags(tagsValues)
			.filterByName(name)
			.filterByPartySizes(partySizesValues)

		setWaitlistListFiltered(waitlistFiltered.waitlist)

		const appliedTags: AppliedTags = {
			tags: tags || [],
			partySizes: partySizes || [],
			name: name ? { id: 'nome', display: name, icon: 'SEARCH', value: name, active: false } : null,
		}

		if (newSettings) setWaitlistSettings(newSettings)

		setAppliedTags(appliedTags)
	}

	const clearFilter = () => {
		setShowWaitlistFiltered(false)
		setWaitlistListFiltered([])
		setAppliedTags(initialAppliedTagsState)
		if (initialWaitlistSettingsRef.current) setWaitlistSettings(initialWaitlistSettingsRef.current)
	}

	const handleUnion = () => {
		navigate('/history')
	}

	return (
		<HostessWrapper>
			<Navbar
				hasTitle={true}
				hasDate={true}
				hasDropDown={true}
				hasFilter={true}
				hasSettings={true}
				hasGrid={true}
				onUnion={handleUnion}
				onFilter={() => setShowModalFilter(true)}
				numberTags={appliedTags?.tags?.length}
				venueId={venueId as string}
				currentPage={CurrentPageType.OPERATIONAL}
			/>
			{isAuthorized({ authorizedRole: UserRole.OPERATOR, userRole }) && (
				<FormWaitlist onAddWaitlist={handleAddWaitlist} waitlistSettings={waitlistSettings} />
			)}

			{loading && <Spinner />}
			{showModalFilter && (
				<Modal onClose={() => setShowModalFilter(false)}>
					<Filter
						onApplyFilter={handleApplyFilter}
						waitlistSettings={waitlistSettings}
						appliedFilters={appliedTags}
					/>
				</Modal>
			)}
			{showWaitlistFiltered ? (
				<DisplayWaitlistFiltered
					waitlistList={waitlistListFiltered}
					waitlistSettings={waitlistSettings}
					appliedTags={appliedTags}
					clearFilter={clearFilter}
					applyfilter={handleApplyFilter}
					venueId={venueId as string}
				/>
			) : (
				<DisplayWaitlist
					waitlistList={waitlistList}
					waitlistSettings={waitlistSettings}
					venueId={venueId as string}
				/>
			)}
			{showFailModal && <FailModal onClose={() => setShowFailModal(false)} />}
		</HostessWrapper>
	)
}

export default HostessWaitlistPage
