import Icon from "../icon"
import { TextWrapper, Wrapper } from "./styled"


interface CheckRadioProps {
    selected: boolean
    children?: string
    onClick: () => void
}

const CheckRadio = ({ selected, onClick, children: text }: CheckRadioProps) => {
    return (
        <Wrapper>
            <Icon id="CHECKRADIO" fill={selected ? 'var(--color-green)' : 'var(--color-gray-mid)'} onClick={onClick} />
            {text && <TextWrapper isActive={selected}>{text}</TextWrapper>}
        </Wrapper>
    )
}

export default CheckRadio