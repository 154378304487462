import { ColorLabel, ColorPreview, ColorPreviewWrapper, HiddenColorInput } from './styled'

interface ColorInputProps {
	name: string
	color: string
	handleChange: (e: any) => void
}

const ColorInput = ({ name, color, handleChange }: ColorInputProps) => {
	return (
		<>
			<ColorPreviewWrapper>
				<HiddenColorInput name={name} value={color} onChange={handleChange} />
				<ColorPreview color={color} />
				<ColorLabel>cor: {color}</ColorLabel>
			</ColorPreviewWrapper>
		</>
	)
}

export default ColorInput
