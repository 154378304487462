import { Container, StyledSpinner } from './styled';

const Spinner = () => {
    return <Container>
        <StyledSpinner viewBox="0 0 50 50">
            <circle
                className="path"
                cx="25"
                cy="25"
                r="10"
                fill="none"
                strokeWidth="4"
            />
        </StyledSpinner>
    </Container>
}


export default Spinner;