import React, { useState, useReducer, createContext, useEffect, useCallback, useContext } from 'react'
import { OperationMode, PartySize } from '../../pages/waitlist-settings/components'
import { WaitlistSettingsContextType, EmailTemplateInterface, OrderTabsMode, WaitingTimeMode, TagSettings } from './types'
import { initialWaitingSpotsTags, initialQueuesState, initialSchedule } from './constants'
import { EmailTemplateFactory } from './factories/email-template.factory'
import { WaitlistSettingsService } from '../../service/waitlist-settings'
import { ClockModeType, OperationModeType, UpdateWaitlistSettingsDto } from '../../service/waitlist-settings/types'
import { VenueContext } from '../venue/venue.context'
import { AlertFactory } from './factories/alert.factory'
import { ScheduleReducerActionKind, waitlistScheduleReducer } from './reducers/waitlist-schedule.reducer'


const WaitlistSettingsContext = createContext<WaitlistSettingsContextType>({} as WaitlistSettingsContextType)

const WaitlistSettingsContextComponent: React.FC<React.ReactNode> = ({ children }) => {

	const { venueId } = useContext(VenueContext)

	const [waitlistSettingsId, setWaitlistSettingsId] = useState('')

	const [waitlistActive, setWaitlistActive] = useState(false)

	// Presential queue config
	const [queues, setQueues] = useState<PartySize[]>(initialQueuesState)

	const [waitlistSchedule, dispatchWaitlistSchedule] = useReducer(waitlistScheduleReducer, initialSchedule)

	const [selectedOperationMode, setSelectedOperationMode] = useState<OperationMode>(OperationMode.ADVANCED)
	// -------

	// Online queue config
	const [hasAutomaticRange, setHasAutomaticRange] = useState(false)
	const [widgetTagmeMinPartySize, setWidgetTagmeMinPartySize] = useState(1)
	const [widgetTagmeMaxPartySize, setWidgetTagmeMaxPartySize] = useState(8)

	const [googleActive, setGoogleActive] = useState(true)
	const [googleMinPartySize, setGoogleMinPartySize] = useState(1)
	const [googleMaxPartySize, setGoogleMaxPartySize] = useState(8)
	// -------

	// Operational config
	const [showPositionWidget, setShowPositionWidget] = useState(true)
	const [showWaitingTimeWidget, setShowWaitingTimeWidget] = useState(false)
	const [showWaitingTimeHostess, setShowWaitingTimeHostess] = useState(false)
	const [waitingTimeWidgetMode, setWaitingTimeWidgetMode] = useState(WaitingTimeMode.CRESCENT)
	const [waitingTimeHostessMode, setWaitingTimeHostessMode] = useState(WaitingTimeMode.CRESCENT)
	const [delayTolerance, setDelayTolerance] = useState(15)
	// -------

	// Message SMS config
	const [smsActive, setSmsActive] = useState(true)
	const [smsEntry, setSmsEntry] = useState(false)
	const [smsEntryMessage, setSmsEntryMessage] = useState('Olá, você está em nossa lista de espera.')
	const [smsCall, setSmsCall] = useState(false)
	const [smsCallMessage, setSmsCallMessage] = useState(
		'A sua vez chegou! Compareça na entrada do nosso restaurante.'
	)
	const [smsDelay, setSmsDelay] = useState(false)
	const [smsDelayMessage, setSmsDelayMessage] = useState('Você está atrasado, por favor, venha rápido! ;)')
	const [smsCancel, setSmsCancel] = useState(false)
	const [smsCancelMessage, setSmsCancelMessage] = useState('Você foi removido da lista de espera.')
	const [smsSeat, setSmsSeat] = useState(false)
	const [smsSeatMessage, setSmsSeatMessage] = useState('Esperamos que a sua experiência seja perfeita.')
	// -------

	// Emails config
	const [emailActive, setEmailActive] = useState(true)
	const [entryEmailTemplate, setEntryEmailTemplate] = useState<EmailTemplateInterface>(EmailTemplateFactory.createEmpty('Olá, você está em nossa lista de espera.'))
	const [callEmailTemplate, setCallEmailTemplate] = useState<EmailTemplateInterface>(EmailTemplateFactory.createEmpty('A sua vez chegou! Compareça na entrada do nosso restaurante.'))
	const [delayEmailTemplate, setDelayEmailTemplate] = useState<EmailTemplateInterface>(EmailTemplateFactory.createEmpty('Você está atrasado, por favor, venha rápido! ;)'))
	const [seatEmailTemplate, setSeatEmailTemplate] = useState<EmailTemplateInterface>(EmailTemplateFactory.createEmpty('Esperamos que a sua experiência seja perfeita.'))
	const [cancelEmailTemplate, setCancelEmailTemplate] = useState<EmailTemplateInterface>(EmailTemplateFactory.createEmpty('Você foi removido da lista de espera.'))

	// Alerts messages
	const [generalInformationWidget, setGeneralInformationWidget] = useState(AlertFactory.generalInformationWidget())
	const [generalInformation, setGeneralInformation] = useState(AlertFactory.generalInformation())
	const [priorityClient, setPriorityClient] = useState(AlertFactory.priorityClient())
	const [standartClient, setStandartClient] = useState(AlertFactory.standartClient())
	const [callSoon, setCallSoon] = useState(AlertFactory.callSoon())
	// -------

	// Waiting spot
	const [waitingSpotsTags, setWaitingSpotsTags] = useState<TagSettings>(initialWaitingSpotsTags)
	// -------

	// Accommodation spot
	const [accommodationSpotsTags, setAccommodationSpotsTags] = useState<TagSettings>(initialWaitingSpotsTags)
	// -------

	// Priority tags
	const [priorityTags, setPriorityTags] = useState<TagSettings>(initialWaitingSpotsTags)
	// -------

	// OrderTabs
	const [tabsControl, setTabsControl] = useState(true)
	const [tabsOperationMode, setTabsOperationMode] = useState(OrderTabsMode.AUTOMATIC)
	const [firstTabNumber, setFirstTabNumber] = useState('150')
	const [lastTabNumber, setLastTabNumber] = useState('')
	const [finishFirstShift, setFinishFirstShift] = useState('09:00')
	const [finishSecondShift, setFinishSecondShift] = useState('21:00')
	// -------

	const updateWidgetTagmeMaxPartySize = useCallback(() => {
		if (hasAutomaticRange) {
			setWidgetTagmeMaxPartySize(queues[queues.length - 1]?.max || 0);
		}
	}, [hasAutomaticRange, queues]);

	useEffect(() => {
		updateWidgetTagmeMaxPartySize();
	}, [updateWidgetTagmeMaxPartySize]);


	const fetchData = useCallback(async () => {
		try {
			const waitlistSettingsData = await WaitlistSettingsService.getWaitlistSettings(venueId)


			if (!waitlistSettingsData) {
				return
			}

			const {
				active,
				partySizes,
				operationMode,
				waitlistsChannels,
				operational,
				smsTemplates,
				emailTemplates,
				clientNotices,
				tagsWaitingPlaces,
				tagsAccommodationPlaces,
				tagsPriorities,
				customerTabs,
				closingConfiguration
			} = waitlistSettingsData

			setWaitlistActive(active)

			if (partySizes) {
				setQueues(partySizes)
			}

			if (operationMode) {
				setSelectedOperationMode(operationMode === 'advanced' ? OperationMode.ADVANCED : OperationMode.BASIC)
			}

			if (closingConfiguration) {
				dispatchWaitlistSchedule({ type: ScheduleReducerActionKind.UPDATE_WEEKLY_SCHEDULE_WITH_DATA_FROM_API, apiData: closingConfiguration })
			}

			if (waitlistsChannels) {
				setHasAutomaticRange(waitlistsChannels.automaticConfiguration)
				setGoogleActive(waitlistsChannels.google.enabled)
				setWidgetTagmeMinPartySize(waitlistsChannels.tagmeWidget.partySize.min)
				setWidgetTagmeMaxPartySize(waitlistsChannels.tagmeWidget.partySize.max)
			}

			if (operational) {
				setShowPositionWidget(operational.showClientPosition)
				setShowWaitingTimeWidget(operational.showTimeEstimatedClient)
				setWaitingTimeWidgetMode(
					operational.clientClockMode === 'asc' ? WaitingTimeMode.CRESCENT : WaitingTimeMode.DECRESCENT
				)
				setShowWaitingTimeHostess(operational.showTimeEstimatedAdmin)
				setWaitingTimeHostessMode(
					operational.hostessClockMode === 'asc' ? WaitingTimeMode.CRESCENT : WaitingTimeMode.DECRESCENT
				)
				setDelayTolerance(operational.delayTolerance)
			}

			if (smsTemplates) {
				setSmsActive(smsTemplates.enabled)
				setSmsEntry(smsTemplates.welcome.enabled)
				setSmsEntryMessage(smsTemplates.welcome.message)
				setSmsCall(smsTemplates.call.enabled)
				setSmsCallMessage(smsTemplates.call.message)
				setSmsDelay(smsTemplates.delay.enabled)
				setSmsDelayMessage(smsTemplates.delay.message)
				setSmsCancel(smsTemplates.cancel.enabled)
				setSmsCancelMessage(smsTemplates.cancel.message)
				setSmsSeat(smsTemplates.seat.enabled)
				setSmsSeatMessage(smsTemplates.seat.message)
			}


			if (emailTemplates) {
				setEntryEmailTemplate(prevState => ({
					...prevState,
					...(emailTemplates.welcome.enabled && { enabled: emailTemplates.welcome.enabled }),
					...(emailTemplates.welcome.templateId && { templateId: emailTemplates.welcome.templateId }),
					...(emailTemplates.welcome.useSmsText && { useSmsText: emailTemplates.welcome.useSmsText }),
					template: { ...prevState.template, ...emailTemplates.welcome?.template, ...(!emailTemplates.welcome.template?.imageUrl && { imageUrl: emailTemplates?.welcome.template?.originalLogo }) }
				}));
				setCallEmailTemplate(prevState => ({
					...prevState,
					...(emailTemplates.call.enabled && { enabled: emailTemplates.call.enabled }),
					...(emailTemplates.call.templateId && { templateId: emailTemplates.call.templateId }),
					...(emailTemplates.call.useSmsText && { useSmsText: emailTemplates.call.useSmsText }),
					template: { ...prevState.template, ...emailTemplates.call?.template, ...(!emailTemplates.call.template?.imageUrl && { imageUrl: emailTemplates?.call.template?.originalLogo }) }
				}));
				setDelayEmailTemplate(prevState => ({
					...prevState,
					...(emailTemplates.delay.enabled && { enabled: emailTemplates.delay.enabled }),
					...(emailTemplates.delay.templateId && { templateId: emailTemplates.delay.templateId }),
					...(emailTemplates.delay.useSmsText && { useSmsText: emailTemplates.delay.useSmsText }),
					template: { ...prevState.template, ...emailTemplates.delay?.template, ...(!emailTemplates.delay.template?.imageUrl && { imageUrl: emailTemplates?.delay.template?.originalLogo }) }
				}));
				setSeatEmailTemplate(prevState => ({
					...prevState,
					...(emailTemplates.welcome.enabled && { enabled: emailTemplates.seat.enabled }),
					...(emailTemplates.seat.templateId && { templateId: emailTemplates.seat.templateId }),
					...(emailTemplates.seat.useSmsText && { useSmsText: emailTemplates.seat.useSmsText }),
					template: { ...prevState.template, ...emailTemplates.seat?.template, ...(!emailTemplates.seat.template?.imageUrl && { imageUrl: emailTemplates?.seat.template?.originalLogo }) }
				}));
				setCancelEmailTemplate(prevState => ({
					...prevState,
					...(emailTemplates.cancel.enabled && { enabled: emailTemplates.cancel.enabled }),
					...(emailTemplates.cancel.templateId && { templateId: emailTemplates.cancel.templateId }),
					...(emailTemplates.cancel.useSmsText && { useSmsText: emailTemplates.cancel.useSmsText }),
					template: { ...prevState.template, ...emailTemplates.cancel?.template, ...(!emailTemplates.cancel.template?.imageUrl && { imageUrl: emailTemplates?.cancel.template?.originalLogo }) }
				}));
				setEmailActive(emailTemplates.enabled)
			}

			if (clientNotices) {
				setGeneralInformationWidget({
					enabled: clientNotices.generalInformationWidget.enabled,
					message: {
						pt: clientNotices.generalInformationWidget.message.pt,
						en: clientNotices.generalInformationWidget.message.en,
					},
				})
				setGeneralInformation({
					enabled: clientNotices.generalInformation.enabled,
					message: {
						pt: clientNotices.generalInformation.message.pt,
						en: clientNotices.generalInformation.message.en,
					},
				})
				setPriorityClient({
					enabled: clientNotices.priorityText.enabled,
					message: {
						pt: clientNotices.priorityText.message.pt,
						en: clientNotices.priorityText.message.en,
					},
				})
				setStandartClient({
					enabled: clientNotices.defaultText.enabled,
					message: {
						pt: clientNotices.defaultText.message.pt,
						en: clientNotices.defaultText.message.en,
					},
				})
				setCallSoon({
					enabled: clientNotices.customerToBeCalled.enabled,
					message: {
						pt: clientNotices.customerToBeCalled.message.pt,
						en: clientNotices.customerToBeCalled.message.en,
					},
				})
			}

			if (tagsWaitingPlaces) {
				setWaitingSpotsTags(
					{
						enabled: tagsWaitingPlaces.enabled,
						tags: tagsWaitingPlaces.tags?.map((item: any) => {
							if (item.logo.url) {
								return { label: item.name, url: item.logo.url, active: item.enabled }
							}

							return { label: item.name, icon: item.logo.icon, color: item.logo.color, active: item.enabled }
						})
					}
				)
			}

			if (tagsAccommodationPlaces) {
				setAccommodationSpotsTags(
					{
						...tagsAccommodationPlaces,
						tags: tagsAccommodationPlaces.tags?.map((item: any) => {
							if (item.logo.url) {
								return { label: item.name, url: item.logo.url, active: item.enabled }
							}

							return { label: item.name, icon: item.logo.icon, color: item.logo.color, active: item.enabled }
						})
					}
				)
			}

			if (tagsPriorities) {
				setPriorityTags(
					{
						...tagsPriorities,
						tags: tagsPriorities.tags?.map((item: any) => {
							if (item.logo.url) {
								return { label: item.name, url: item.logo.url, active: item.enabled }
							}

							return { label: item.name, icon: item.logo.icon, color: item.logo.color, active: item.enabled }
						})
					}
				)
			}

			if (customerTabs) {
				setTabsControl(customerTabs.enabled)
				setTabsOperationMode(customerTabs.automatic ? OrderTabsMode.AUTOMATIC : OrderTabsMode.MANUAL)
				setFirstTabNumber(customerTabs.firstTabNumber)
				setLastTabNumber(customerTabs.lastTabNumber || '')
				setFinishFirstShift(customerTabs.finishFirstShift)
				setFinishSecondShift(customerTabs.finishSecondShift || 'Não configurado')
			}
		} catch (error) {
			return error
		}
	}, [venueId])

	useEffect(() => {
		if (venueId)

			fetchData()
	}, [fetchData, venueId])

	const dispatchData = async () => {
		const payload: UpdateWaitlistSettingsDto = {
			active: waitlistActive,
			partySizes: queues,
			operationMode: selectedOperationMode === OperationMode.ADVANCED ? OperationModeType.ADVANCED : OperationModeType.BASIC,
			waitlistsChannels: {
				automaticConfiguration: hasAutomaticRange,
				google: {
					enabled: googleActive,
					partySize: {
						min: googleMinPartySize,
						max: googleMaxPartySize,
					},
				},
				tagmeWidget: {
					enabled: true,
					partySize: {
						min: widgetTagmeMinPartySize,
						max: widgetTagmeMaxPartySize,
					},
				}
			},
			operational: {
				showClientPosition: showPositionWidget,
				showTimeEstimatedClient: showWaitingTimeWidget,
				clientClockMode: waitingTimeWidgetMode === WaitingTimeMode.CRESCENT ? ClockModeType.ASC : ClockModeType.DESC,
				showTimeEstimatedAdmin: showWaitingTimeHostess,
				hostessClockMode: waitingTimeHostessMode === WaitingTimeMode.CRESCENT ? ClockModeType.ASC : ClockModeType.DESC,
				delayTolerance,
			},
			closingConfiguration: waitlistSchedule,
			smsTemplates: {
				enabled: smsActive || false,
				welcome: {
					enabled: smsEntry,
					message: smsEntryMessage,
				},
				call: {
					enabled: smsCall,
					message: smsCallMessage,
				},
				delay: {
					enabled: smsDelay,
					message: smsDelayMessage,
				},
				cancel: {
					enabled: smsCancel,
					message: smsCancelMessage,
				},
				seat: {
					enabled: smsSeat,
					message: smsSeatMessage,
				},
			},
			clientNotices: {
				generalInformationWidget: {
					enabled: generalInformationWidget.enabled,
					message: { pt: generalInformationWidget.message.pt, en: generalInformationWidget.message.en },
				},
				generalInformation: {
					enabled: generalInformation.enabled,
					message: { pt: generalInformation.message.pt, en: generalInformation.message.en },
				},
				priorityText: {
					enabled: priorityClient.enabled,
					message: { pt: priorityClient.message.pt, en: priorityClient.message.en },
				},
				defaultText: {
					enabled: standartClient.enabled,
					message: { pt: standartClient.message.pt, en: standartClient.message.en },
				},
				customerToBeCalled: {
					enabled: callSoon.enabled,
					message: { pt: callSoon.message.pt, en: callSoon.message.en },
				},
			},
			tagsWaitingPlaces: {
				enabled: waitingSpotsTags.enabled,
				tags: waitingSpotsTags.tags?.map(item => {
					if (item.url) {
						return { name: item.label, enabled: item.active, logo: { url: item.url } } as any
					}

					return { name: item.label, enabled: item.active, logo: { icon: item.icon, color: item.color } }
				})
			},
			tagsAccommodationPlaces: {
				enabled: accommodationSpotsTags.enabled,
				tags: accommodationSpotsTags.tags?.map(item => {
					if (item.url) {
						return { name: item.label, enabled: item.active, logo: { url: item.url } } as any
					}

					return { name: item.label, enabled: item.active, logo: { icon: item.icon, color: item.color } }
				})
			},
			tagsPriorities: {
				enabled: priorityTags.enabled,
				tags: priorityTags.tags?.map(item => {
					if (item.url) {
						return { name: item.label, enabled: item.active, logo: { url: item.url } } as any
					}

					return { name: item.label, enabled: item.active, logo: { icon: item.icon, color: item.color } }
				})
			},
			customerTabs: {
				enabled: tabsControl,
				automatic: tabsOperationMode === OrderTabsMode.AUTOMATIC ? true : false,
				firstTabNumber: String(firstTabNumber),
				...(lastTabNumber && { lastTabNumber: String(lastTabNumber) }),
				finishFirstShift: finishFirstShift,
				...(finishSecondShift && finishSecondShift !== 'Não configurado' && { finishSecondShift: finishSecondShift }),
			},
			emailTemplates: {
				enabled: emailActive,
				welcome: entryEmailTemplate,
				call: callEmailTemplate,
				delay: delayEmailTemplate,
				seat: seatEmailTemplate,
				cancel: cancelEmailTemplate,
			},
		}

		return WaitlistSettingsService.updateWaitlistSettings(venueId, payload)
	}

	return (
		<WaitlistSettingsContext.Provider
			value={{
				waitlistActive,
				setWaitlistActive,
				waitlistSettingsId,
				setWaitlistSettingsId,
				queues,
				setQueues,
				waitlistSchedule,
				dispatchWaitlistSchedule,
				selectedOperationMode,
				setSelectedOperationMode,
				hasAutomaticRange,
				setHasAutomaticRange,
				widgetTagmeMaxPartySize,
				setWidgetTagmeMaxPartySize,
				widgetTagmeMinPartySize,
				setWidgetTagmeMinPartySize,
				googleActive,
				setGoogleActive,
				googleMaxPartySize,
				setGoogleMaxPartySize,
				googleMinPartySize,
				setGoogleMinPartySize,
				showPositionWidget,
				setShowPositionWidget,
				showWaitingTimeWidget,
				setShowWaitingTimeWidget,
				showWaitingTimeHostess,
				setShowWaitingTimeHostess,
				waitingTimeWidgetMode,
				setWaitingTimeWidgetMode,
				waitingTimeHostessMode,
				setWaitingTimeHostessMode,
				delayTolerance,
				setDelayTolerance,
				smsActive,
				setSmsActive,
				smsEntry,
				setSmsEntry,
				smsEntryMessage,
				setSmsEntryMessage,
				smsCall,
				setSmsCall,
				smsCallMessage,
				setSmsCallMessage,
				smsDelay,
				setSmsDelay,
				smsDelayMessage,
				setSmsDelayMessage,
				smsCancel,
				setSmsCancel,
				smsCancelMessage,
				setSmsCancelMessage,
				smsSeat,
				setSmsSeat,
				smsSeatMessage,
				setSmsSeatMessage,
				emailActive,
				setEmailActive,
				entryEmailTemplate,
				setEntryEmailTemplate,
				callEmailTemplate,
				setCallEmailTemplate,
				delayEmailTemplate,
				setDelayEmailTemplate,
				seatEmailTemplate,
				setSeatEmailTemplate,
				cancelEmailTemplate,
				setCancelEmailTemplate,
				waitingSpotsTags,
				setWaitingSpotsTags,
				accommodationSpotsTags,
				setAccommodationSpotsTags,
				priorityTags,
				setPriorityTags,
				generalInformationWidget,
				setGeneralInformationWidget,
				generalInformation,
				setGeneralInformation,
				priorityClient,
				setPriorityClient,
				standartClient,
				setStandartClient,
				callSoon,
				setCallSoon,
				tabsControl,
				setTabsControl,
				tabsOperationMode,
				setTabsOperationMode,
				firstTabNumber,
				setFirstTabNumber,
				lastTabNumber,
				setLastTabNumber,
				finishFirstShift,
				setFinishFirstShift,
				finishSecondShift,
				setFinishSecondShift,
				dispatchData,
			}}
		>
			{children}
		</WaitlistSettingsContext.Provider>
	)
}

export { WaitlistSettingsContextComponent, WaitlistSettingsContext }


