import { useContext } from "react"
import CheckRadio from "../../../../../../components/check-radio"
import ToggleSwitch from "../../../../../../components/toggle-switch"
import DailyScheduleCard from "../daily-schedule-card"
import { WeekDay } from "../../../../../../types/week-day.enum"
import { CheckRadiosWrapper } from "./styled"
import TimeRange from "../time-range"
import { WaitlistSettingsContext } from "../../../../../../contexts/waitlist-settings/waitlist-settings.context"
import { Method } from "../../../../../../contexts/waitlist-settings/types"
import { ScheduleReducerActionKind } from "../../../../../../contexts/waitlist-settings/reducers/waitlist-schedule.reducer"

const WaitlistSchedule = () => {
    const { waitlistSchedule, dispatchWaitlistSchedule } = useContext(WaitlistSettingsContext)
    const { method, weeklySchedules } = waitlistSchedule


    const handleAdvancedWeeklyScheduleDisplay = () => {
        const advancedDailySchedulesArr: JSX.Element[] = []

        for (const day in weeklySchedules) {
            const typedDay = day as WeekDay
            const dailySchedule = weeklySchedules[typedDay]
            advancedDailySchedulesArr.push(<DailyScheduleCard key={day} active={dailySchedule.active} day={typedDay} startRange={dailySchedule.timeRange.openingTime} finishRange={dailySchedule.timeRange.closingTime} onChange={(newDailySchedule) => dispatchWaitlistSchedule({ type: ScheduleReducerActionKind.UPDATE_ADVANCED_DAILY_SCHEDULE, dailySchedule: newDailySchedule, day: typedDay })} />)
        }

        return advancedDailySchedulesArr
    }

    const handleBasicWeeklyScheduleDisplay = () => {
        const timeRange = weeklySchedules.monday.timeRange
        return <TimeRange startRange={timeRange.openingTime} finishRange={timeRange.closingTime} onChange={({ startRange, finishRange }) => dispatchWaitlistSchedule({ type: ScheduleReducerActionKind.UPDATE_BASIC_DAILY_SCHEDULE, timeRange: { openingTime: startRange, closingTime: finishRange } })} />
    }

    return <>
        <ToggleSwitch state={method !== Method.DEFAULT} handleChange={() => dispatchWaitlistSchedule({ type: ScheduleReducerActionKind.TOGGLE_WEEKLY_SCHEDULE_METHOD })} margin="20px 0">Agendamento da lista de espera online</ToggleSwitch >
        {method !== Method.DEFAULT && <CheckRadiosWrapper>
            <CheckRadio selected={method === Method.BASIC} onClick={() => dispatchWaitlistSchedule({ type: ScheduleReducerActionKind.UPDATE_WEEKLY_SCHEDULE_METHOD_TO_BASIC })} >Todos os dias</CheckRadio>
            {method === Method.BASIC && handleBasicWeeklyScheduleDisplay()}
            <CheckRadio selected={method === Method.ADVANCED} onClick={() => dispatchWaitlistSchedule({ type: ScheduleReducerActionKind.UPDATE_WEEKLY_SCHEDULE_METHOD_TO_ADVANCED })} > Por dia da semana</CheckRadio>
            {method === Method.ADVANCED && handleAdvancedWeeklyScheduleDisplay()}
        </CheckRadiosWrapper>
        }
    </>
}

export default WaitlistSchedule