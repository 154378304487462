export class NotificationFactory {
	static wellcome() {
		return {
			enabled: false,
			message: {
				pt: 'Olá, você está em nossa lista de espera.',
				en: 'Hello, you are in our waitlist.',
			},
		}
	}

	static call() {
		return {
			enabled: false,
			message: {
				pt: 'A sua vez chegou! Compareça na entrada do nosso restaurante.',
				en: 'Your turn has come! Come to the entrance of our restaurant',
			},
		}
	}

	static delay() {
		return {
			enabled: false,
			message: {
				pt: 'Você está atrasado, por favor, venha rápido!',
				en: 'You are late, please come fast!',
			},
		}
	}

	static cancel() {
		return {
			enabled: false,
			message: {
				pt: 'Você foi removido da lista de espera.',
				en: 'You were removed from the waitlist.',
			},
		}
	}

	static seat() {
		return {
			enabled: false,
			message: {
				pt: 'Esperamos que a sua experiência seja perfeita.',
				en: 'We hope you have a great expirience.',
			},
		}
	}
}
