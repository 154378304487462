import styled from 'styled-components/macro'

export const Wrapper = styled.div``

export const IconWrapper = styled.div`
	position: relative;
`

export const ExclamationWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%);
`
