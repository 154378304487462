import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { User } from "../../contexts/auth/types"

const HomePage: React.FC = () => {


    const navigate = useNavigate()

    useEffect(() => {
        const storedUser = localStorage.getItem("ssoTagmeToken");
        const parsedStoredUser = storedUser && JSON.parse(storedUser) as User;
        if (parsedStoredUser && parsedStoredUser?.accessToken) {

            return navigate(`/operational/${parsedStoredUser?.venues[0]._id}`)
        }

        return navigate('/login')

    }, [navigate])
    return (
        <></>
    )
}

export default HomePage