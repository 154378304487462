import { useContext, useEffect, useRef, useState } from 'react'
import { WaitlistSettingsContext } from '../../../../contexts/waitlist-settings/waitlist-settings.context'
import WaitlistSettingsSelector from '../selector'
import {
    Dropdown,
    DropdownItem,
    DropdownWrapper,
    WarningWrapper,
    PartySizeLabel,
    PartySizeLableWrapper,
    Warning,
    Wrapper,
    PartySizeWarningLabel,
} from './styled'

import { HorizontalRule } from '../horinzontal-rule/styled'

export interface PartySizeRangeProps {
    maxRange?: number
    disabled?: boolean
    hasWarning?: boolean
    maxPartySize: number
    handleMaxPartySizeChange: (value: number) => void
}

const BasicPartySizeRange = ({
    maxRange,
    disabled,
    hasWarning,
    maxPartySize,
    handleMaxPartySizeChange
}: PartySizeRangeProps) => {
    const { queues } = useContext(WaitlistSettingsContext)

    const [openMinPartySize, setOpenMinPartySize] = useState(false)
    const [openMaxPartySize, setOpenMaxPartySize] = useState(false)

    const minDropdownRef: any = useRef()
    const maxDropdownRef: any = useRef()

    // It closes the dropdown whenever the user click outside the dropdown's limits
    useEffect(() => {
        const checkIfClickedOutside = (e: Event) => {
            // If the dropdown is open and the clicked target is not within the dropdown, then close the dropdown
            if (openMaxPartySize && maxDropdownRef.current && !maxDropdownRef.current.contains(e.target)) {
                setOpenMaxPartySize(false)
            }

            if (openMinPartySize && minDropdownRef.current && !minDropdownRef.current.contains(e.target)) {
                setOpenMinPartySize(false)
            }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [openMaxPartySize, openMinPartySize])


    const handleMaxPartySizeClick = (value: number) => {
        handleMaxPartySizeChange(value)

        setOpenMaxPartySize(false)
    }

    const generateMaxPartySizeDropdownItens = () => {
        const initialRange = 1
        let finalRange = maxRange || 99

        let dropDownArray = []
        for (let i = initialRange; i <= finalRange; i++) {
            dropDownArray.push(
                <DropdownItem key={i} onClick={() => handleMaxPartySizeClick(i)}>
                    {i}
                </DropdownItem>
            )
        }

        return dropDownArray
    }

    const handleOpenMaxPartySize = () => {
        if (!disabled) {
            setOpenMaxPartySize(!openMaxPartySize)
        }
    }

    const handleIconColor = () => {
        if (disabled) {
            return 'var(--color-gray-mid-2)'
        }

        if (hasWarning) {
            return 'var(--color-danger)'
        }

        return 'var(--color-primary)'
    }

    const handleBackGroundColor = () => {
        if (disabled) {
            return 'var(--color-gray-light-2)'
        }

        return 'var(--color-white)'
    }

    return (
        <>

            <PartySizeLableWrapper>
                <PartySizeLabel>Mínimo de pessoas</PartySizeLabel>
                <PartySizeLabel>Máximo de pessoas</PartySizeLabel>
            </PartySizeLableWrapper>

            <HorizontalRule />
            <Wrapper>
                <DropdownWrapper ref={minDropdownRef}>
                    <WaitlistSettingsSelector
                        height="30px"
                        hasArrow={true}
                        iconColor={'var(--color-gray-mid-2)'}
                        padding="0 7px"
                        backgroundColor={'var(--color-gray-light-2)'}
                        rotateArrow={false}
                        handleClick={() => setOpenMinPartySize(!openMinPartySize)}
                    >
                        1
                    </WaitlistSettingsSelector>
                </DropdownWrapper>
                <DropdownWrapper ref={maxDropdownRef}>
                    <WaitlistSettingsSelector
                        height="30px"
                        hasArrow={true}
                        iconColor={handleIconColor()}
                        padding="0 7px"
                        hasInconsistency={hasWarning}
                        color={hasWarning ? 'var(--color-danger)' : 'var(--color-black)'}
                        backgroundColor={handleBackGroundColor()}
                        rotateArrow={openMaxPartySize}
                        handleClick={handleOpenMaxPartySize}
                    >
                        {openMaxPartySize ? 'Máximo' : maxPartySize}
                    </WaitlistSettingsSelector>
                    <Dropdown>{openMaxPartySize && !disabled && generateMaxPartySizeDropdownItens()}</Dropdown>
                    {hasWarning && <PartySizeWarningLabel>Número de pessoas acima do configurado. (Máximo {queues[queues.length - 1].max})</PartySizeWarningLabel>}
                </DropdownWrapper>

                <WarningWrapper>{hasWarning && <Warning>!</Warning>}</WarningWrapper>
            </Wrapper>
        </>
    )
}

export default BasicPartySizeRange
