import { useState } from "react"
import { ArrowContainer, PageSelectorButton, PageSelectorContainer } from "./styled"
import Icon from "../../../../components/icon"

interface PageSelectorProps {
    totalPages: number
    currentPage: number
    handleClick: (page: number) => void
}

const usePagination = (totalPages: number) => {
    const [range, setRange] = useState(1);

    const allPages = Array.from(Array(totalPages).keys())?.map(page => page + 1);

    const calculateDisplayPages = () => {
        const start = range * 4 - 4;
        const end = range * 4;
        return allPages.slice(start, end);
    };

    const handleChapterChange = (action: string) => {
        if (action === 'next' && range < totalPages / 4) {
            setRange(range + 1);
        }
        if (action === 'previous' && range > 1) {
            setRange(range - 1);
        }
    };

    const displayPages = calculateDisplayPages();

    return { displayPages, handleChapterChange };
}

const PaginationComponent = (props: PageSelectorProps) => {
    const { currentPage, totalPages, handleClick } = props;
    const { displayPages, handleChapterChange } = usePagination(totalPages);

    return (
        <div>
            <PageSelectorContainer>
                <ArrowContainer left={true} onClick={() => handleChapterChange('previous')}>
                    <Icon id="ARROWDOWN" fill="var(--color-primary)" width="10px" />
                </ArrowContainer>
                {displayPages?.map(page => <PageSelectorButton key={page} isSelected={page === currentPage} onClick={() => handleClick(page)}>{page}</PageSelectorButton>)}
                <ArrowContainer left={false} onClick={() => handleChapterChange('next')}>
                    <Icon id="ARROWDOWN" fill="var(--color-primary)" width="10px" />
                </ArrowContainer>
            </PageSelectorContainer>
        </div>
    );
}

export default PaginationComponent;
