import { Language } from '../../types/language.interface'
import { WaitlistSchedule, TagSettings } from '../../contexts/waitlist-settings/types'
import { TagForHostessFromApi } from '../types'

interface PartySize {
	min: number
	max: number
}

export interface WaitlistSettingsForHostessFromApi {
	tagsAccommodationPlaces: TagForHostessFromApi[]
	tagsPriorities: TagForHostessFromApi[]
	tagsWaitingPlaces: TagForHostessFromApi[]
	partySizes: PartySize[]
	maxPartySize: number
	customerTabs: {
		automatic: boolean
		enabled: boolean
	}
}

interface ClientMessage {
	message: Language
	enabled: boolean
}

interface TemplateMessage {
	message: string
	enabled: boolean
}

interface SMSTemplates {
	enabled: boolean
	welcome: TemplateMessage
	call: TemplateMessage
	seat: TemplateMessage
	cancel: TemplateMessage
	delay: TemplateMessage
}

interface MessagesPushTemplate {
	enabled: boolean
	useSmsText: boolean
	welcome: ClientMessage
	call: ClientMessage
	seat: ClientMessage
	cancel: ClientMessage
	delay: ClientMessage
}

interface Details {
	headerBackground?: string
	headerImage?: string
	bodyImage?: string
	bodyBackground?: string
	bodyText?: string
	buttonText?: string
	buttonLink?: string
	buttonColor?: string
	buttonBackground?: string
	buttonBorder?: string
	imageUrl?: string
	originalLogo?: string
	textColor?: string
	mainColor?: string
	message?: string
	useSmsText?: boolean
	dateColor?: string
	buttonTextColor?: string
	backgroundColor?: string
	sublinkColor?: string
	sublinkText?: string
	sublinkLink?: string
}

interface EmailTemplateInfo {
	templateId?: string | null
	enabled?: boolean
	template?: Details
	useSmsText?: boolean
}

interface EmailTemplates {
	enabled: boolean
	welcome: EmailTemplateInfo
	call: EmailTemplateInfo
	seat: EmailTemplateInfo
	delay: EmailTemplateInfo
	cancel: EmailTemplateInfo
}

interface CustomerTabs {
	enabled: boolean
	automatic: boolean
	firstTabNumber: string
	lastTabNumber?: string
	finishFirstShift: string
	finishSecondShift?: string
}

export enum OperationModeType {
	ADVANCED = 'advanced',
	BASIC = 'basic',
}

export enum ClockModeType {
	ASC = 'asc',
	DESC = 'desc',
}

interface SupervisoryPassword {
	enabled?: boolean
	password?: string
}

export class Logo {
	icon?: string
	color?: string
	url?: string
}

interface ClientNotices {
	generalInformationWidget: ClientMessage
	generalInformation: ClientMessage
	priorityText: ClientMessage
	defaultText: ClientMessage
	customerToBeCalled: ClientMessage
}

interface ChannelConfiguration {
	enabled: boolean
	partySize: PartySize
}

interface WaitlistsChannels {
	automaticConfiguration: boolean
	tagmeWidget: ChannelConfiguration
	google: ChannelConfiguration
}

interface Operational {
	showClientPosition: boolean
	showTimeEstimatedClient: boolean
	clientClockMode: ClockModeType
	hostessClockMode: ClockModeType
	showTimeEstimatedAdmin: boolean
	delayTolerance: number
}

export interface WaitlistScheduleFromApi extends Partial<WaitlistSchedule> {}

enum ModeType {
	MANUAL = 'manual',
	AFTERLAST = 'afterLast',
	AVERAGE = 'average',
}

export interface WaitlistSettingsForConfigurationFromApi {
	_id: string
	maxWaitingTime: number
	averageRange: number
	averageOf: number
	venue: string
	operationMode: OperationModeType
	mode: ModeType
	partySizes: PartySize[]
	waitlistsChannels: WaitlistsChannels
	operational: Operational
	smsTemplates?: SMSTemplates
	emailTemplates?: EmailTemplates
	messagesPush?: MessagesPushTemplate
	customerTabs: CustomerTabs
	supervisoryPassword?: SupervisoryPassword
	timeZone: string
	clientNotices: ClientNotices
	tagsWaitingPlaces: TagSettings
	tagsAccommodationPlaces: TagSettings
	tagsPriorities: TagSettings
	closingConfiguration: WaitlistScheduleFromApi
	active: boolean
}

export interface UpdateWaitlistSettingsDto {
	operationMode: OperationModeType
	partySizes: PartySize[]
	smsTemplates?: SMSTemplates
	emailTemplates?: EmailTemplates
	waitlistsChannels: WaitlistsChannels
	operational: Operational
	clientNotices: ClientNotices
	tagsWaitingPlaces: TagSettings
	tagsAccommodationPlaces: TagSettings
	tagsPriorities: TagSettings
	customerTabs: CustomerTabs
	active?: boolean
	closingConfiguration: WaitlistSchedule
}
