export interface Summary {
	totalEntrances: number
	totalClients: number
	averageWaitingTime: string
	totalSeatedClients: number
	totalCanceledClients: number
	avaregeClientsPerHour: Record<number, number>
}

export enum Shift {
	MORNING = 'morning',
	AFTERNOON = 'afternoon',
	EVENING = 'evening',
	ALL = 'all',
}

export interface Details {
	fullName: string
	phoneNumber: string
	email?: string
	profile: string[]
	partySize: number
	arriveDate: string
	arriveTime: string
	finalDate: string
	finalTime: string
	waitingTime: string
	finalStatus: string
	sentSmsAmount: number
	firstSmsSentTime: string
	origin: string
	waitingPlaces: string[]
	accomodationPlaces: string[]
	tableNumber: number
	customerTab: string
	note: string
}

export interface Form {
	shift: Shift
	startDate: Date
	endDate: Date
}
