import styled from 'styled-components'

export const DropdownWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
`

interface DropdownStyleProps {
	height?: string
}

export const DropdownStyle = styled.div<DropdownStyleProps>`
	${({ height }) => (height ? `top: ${height}` : 'top: 30px')};
	z-index: 2;
	width: 100%;
	max-height: 140px;
	overflow-y: scroll;
	position: absolute;
	border-radius: 3px;
	box-shadow: 0px 3px 5px rgb(200 200 200 / 50%);
`

export const DropdownItem = styled.div<DropdownStyleProps>`
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 5px 24px;
	border-bottom: 1px solid var(--color-gray-light);
	background-color: white;
	${({ height }) => (height ? `height: ${height}` : 'height: 30px')};
	&:hover {
		cursor: pointer;
		background-color: #f8f9fa;
	}
`
