interface GenerateTimeOptionsInterface {
	min?: string
	max?: string
}

export const generateTimeOptions = ({ min, max }: GenerateTimeOptionsInterface) => {
	const timesArray = []

	const initialHour = parseInt(min?.split(':')[0] || '0')
	const initialMinute = parseInt(min?.split(':')[1] || '0')

	const finalHour = parseInt(max?.split(':')[0] || '24')
	const finalMinute = parseInt(max?.split(':')[1] || '60') || 60
	for (let hour = initialHour; hour <= finalHour; hour++) {
		for (let min = 0; min < finalMinute; min = min + 10) {
			if (hour < 24) {
				if (hour === initialHour) {
					if (min > initialMinute) {
						timesArray.push(`${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`)
					}
				} else {
					timesArray.push(`${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`)
				}
			}
		}
	}

	return timesArray
}
