import ButtonIcon from '../../../../../components/button-icon'
import { truncateString } from '../../../../../utils/truncate-string'
import { Note } from '../styled'

interface NoteDisplayProps {
	onConfirm: () => void
	note?: string
	showCustomerTab?: boolean
}

const NoteDisplay = ({ note, onConfirm, showCustomerTab }: NoteDisplayProps) => {
	let name = showCustomerTab ? 'Obs...' : 'Observações'

	if (note) {
		name = showCustomerTab ? truncateString(note, 10) : truncateString(note, 15)
	}

	return (
		<Note>
			{note !== '' && note !== undefined ? (
				<ButtonIcon id="EXCLAMATIONMARKCIRCLE" width="18px" type="button" onClick={onConfirm}>
					{name}
				</ButtonIcon>
			) : (
				<ButtonIcon id="OPLUS" width="18px" type="button" onClick={onConfirm}>
					{name}
				</ButtonIcon>
			)}
		</Note>
	)
}

export default NoteDisplay
