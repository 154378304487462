import styled from 'styled-components'

interface Margin {
	margin?: string
}

export const HorizontalRule = styled.hr<Margin>`
	border: 1px solid var(--color-gray-mid);
	${({ margin }) => margin && `margin: ${margin}`}
`
