import { CancelReason } from '../../types/cancel-reason.enum'
import { WaitlistLog } from '../../types/waitlist.interface'
import { TagDB, TagForHostessFromApi } from '../types'

export interface WaitlistFromApi {
	_id: string
	globalPosition: number
	position: number
	customerTab: string
	partySize: number
	customer: {
		fullName: string
		phone?: string
		email?: string
		lastName: string
		name: string
	}
	priority: boolean
	note?: string
	origin: {
		label: string
	}
	arrivedAt: string
	notifiedAt?: string
	canceledAt?: string
	cancelReason?: CancelReason
	seatedAt?: string
	table?: string
	history?: boolean
	tagsWaitingPlaces?: TagDB[]
	tagsAccommodationPlaces?: TagDB[]
	tagsPriorities?: TagDB[]
	logs: WaitlistLog[]
}

export enum OriginLabel {
	RESTAURANT = 'Restaurant',
	WIDGET = 'Widget',
}

export interface CreateWaitlistDto {
	customer: {
		name: string
		lastName?: string
		phone?: string
		email?: string
	}
	partySize: number
	origin: {
		app?: string
		label: OriginLabel.RESTAURANT
	}
	tagsWaitingPlaces?: TagForHostessFromApi[]
	tagsAccommodationPlaces?: TagForHostessFromApi[]
	tagsPriorities?: TagForHostessFromApi[]
	note?: string
	customerTab?: string
}

export interface UpdateWaitlistDto extends Partial<CreateWaitlistDto> {
	table?: string
	priority?: boolean
	restore?: boolean
	cancelReason?: CancelReason
}
