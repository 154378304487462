import { Waitlist } from '../../../../../types/waitlist.interface'
import { Wrapper } from './styled'

const getClients = (waitlistList: Waitlist[]) => waitlistList.length
const getPeople = (waitlistList: Waitlist[]) => waitlistList.reduce((acc, item) => (acc += +item.partySize), 0)

interface InfoProps {
	waitlistList: Waitlist[]
}

const Info = ({ waitlistList }: InfoProps) => {
	const clients =
		getClients(waitlistList) === 1 ? (
			<strong>1 cliente</strong>
		) : (
			<strong>{getClients(waitlistList)} clientes</strong>
		)
	const people =
		getPeople(waitlistList) === 1 ? (
			<strong>1 pessoa</strong>
		) : (
			<strong>{getPeople(waitlistList)} pessoas</strong>
		)
	return (
		<Wrapper>
			{clients} e {people} na lista.
		</Wrapper>
	)
}
export default Info
