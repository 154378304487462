import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import WaitlistSettingsSelector from '../../pages/waitlist-settings/components/selector'
import { DropdownWrapper, DropdownStyle, DropdownItem } from './styled'

interface DropdownProps {
	state: string | number
	setState: Dispatch<SetStateAction<any>>
	options: string[] | number[]
	height: string
	enabled: boolean
	placeHolder: string
}

const Dropdown = ({ state, setState, options, height, enabled, placeHolder }: DropdownProps) => {
	const [open, setOpen] = useState(false)

	const handleDropdownItemClick = (item: string | number) => {
		if (enabled) {
			setOpen(false)
			setState(item)
		}
	}

	const handleOpenDropdown = () => {
		if (enabled) {
			setOpen(!open)
		}
	}

	const generateDropdownItens = () => {
		return options?.map((item, idx) => {
			return (
				<DropdownItem height={height} key={idx} onClick={() => handleDropdownItemClick(item)}>
					{item}
				</DropdownItem>
			)
		})
	}

	const ref: any = useRef()

	useEffect(() => {
		const checkIfClickedOutside = (e: Event) => {
			// If the dropdown is open and the clicked target is not within the dropdown, then close the menu
			if (open && ref.current && !ref.current.contains(e.target)) {
				setOpen(false)
			}
		}

		document.addEventListener('mousedown', checkIfClickedOutside)

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside)
		}
	}, [open])

	return (
		<DropdownWrapper ref={ref}>
			<WaitlistSettingsSelector
				handleClick={() => handleOpenDropdown()}
				height="46px"
				iconColor={enabled ? 'var(--color-primary)' : 'var(--color-gray-dark)'}
				padding="14px 10px"
				color={enabled ? 'var(--color-black)' : 'var(--color-gray-mid-2)'}
				hasArrow={true}
				backgroundColor={enabled && !open ? 'var(--color-white)' : 'var(--color-gray-mid)'}
				rotateArrow={open && enabled}
			>
				{open ? placeHolder : state || placeHolder}
			</WaitlistSettingsSelector>
			<DropdownStyle height={height}>{open && enabled && generateDropdownItens()}</DropdownStyle>
		</DropdownWrapper>
	)
}

export default Dropdown
