import { WaitlistScheduleFromApi } from '../../../service/waitlist-settings/types'
import { WeekDay } from '../../../types/week-day.enum'
import { DailySchedule, Method, ShiftRangeInterface, WaitlistSchedule } from '../types'

export enum ScheduleReducerActionKind {
	UPDATE_ADVANCED_DAILY_SCHEDULE = 'UPDATE_ADVANCED_DAILY_SCHEDULE',
	UPDATE_BASIC_DAILY_SCHEDULE = 'UPDATE_BASIC_DAILY_SCHEDULE',
	UPDATE_WEEKLY_SCHEDULE_METHOD_TO_ADVANCED = 'UPDATE_WEEKLY_SCHEDULE_METHOD_TO_ADVANCED',
	UPDATE_WEEKLY_SCHEDULE_METHOD_TO_BASIC = 'UPDATE_WEEKLY_SCHEDULE_METHOD_TO_BASIC',
	TOGGLE_WEEKLY_SCHEDULE_METHOD = 'TOGGLE_WEEKLY_SCHEDULE_METHOD',
	UPDATE_WEEKLY_SCHEDULE_WITH_DATA_FROM_API = 'UPDATE_WEEKLY_SCHEDULE_WITH_DATA_FROM_API',
}

export interface UpdateScheduleAction {
	type: ScheduleReducerActionKind
	day?: WeekDay
	dailySchedule?: DailySchedule
	timeRange?: ShiftRangeInterface
	apiData?: WaitlistScheduleFromApi
}

export const waitlistScheduleReducer = (
	state: WaitlistSchedule,
	{ type: actionType, dailySchedule, day, timeRange, apiData }: UpdateScheduleAction
) => {
	switch (actionType) {
		case ScheduleReducerActionKind.UPDATE_WEEKLY_SCHEDULE_METHOD_TO_ADVANCED: {
			return {
				...state,
				method: Method.ADVANCED,
			}
		}
		case ScheduleReducerActionKind.UPDATE_WEEKLY_SCHEDULE_METHOD_TO_BASIC: {
			return {
				...state,
				method: Method.BASIC,
			}
		}
		case ScheduleReducerActionKind.TOGGLE_WEEKLY_SCHEDULE_METHOD: {
			return {
				...state,
				method: state.method === Method.DEFAULT ? Method.BASIC : Method.DEFAULT,
			}
		}
		case ScheduleReducerActionKind.UPDATE_ADVANCED_DAILY_SCHEDULE: {
			if (!day || !dailySchedule) return state

			return {
				...state,
				weeklySchedules: {
					...state.weeklySchedules,
					[day]: {
						...dailySchedule,
					},
				},
			}
		}
		case ScheduleReducerActionKind.UPDATE_BASIC_DAILY_SCHEDULE: {
			if (!timeRange) return state
			// Apply the same timeRange to all days and make then all active
			const newState = { ...state }
			for (const day of Object.values(WeekDay)) {
				newState.weeklySchedules[day].timeRange = timeRange
				newState.weeklySchedules[day].active = true
			}
			return newState
		}
		case ScheduleReducerActionKind.UPDATE_WEEKLY_SCHEDULE_WITH_DATA_FROM_API: {
			if (!apiData) return state
			return { ...state, ...apiData }
		}
		default:
			return state
	}
}
