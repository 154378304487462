import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const MainText = styled.span`
	font-size: ${14 / 16}rem;
	font-weight: var(--font-weight-bold);
	color: var(--color-black);
	margin-bottom: 5px;
`

export const StyledLink = styled(Link)`
	font-size: ${12 / 16}rem;
	color: var(--color-primary);
	font-weight: var(--font-weight-bold);

	&:hover {
		cursor: pointer;
	}
`

export const ChildrenWrapper = styled.div`
	 {
		display: flex;
		flex-direction: column;
		align-items: start;
		margin-left: 10px;
	}
`
