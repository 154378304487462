import Icon from '../../../../../components/icon'
import { IconWrapper, Input, InputWrapper } from './styled'

interface SearchCustomerProps {
	value: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchCustomer = ({ value, onChange }: SearchCustomerProps) => {
	return (
		<InputWrapper>
			<IconWrapper>
				<Icon id='SEARCH' width='16px' />
			</IconWrapper>
			<Input placeholder='Digite aqui o nome do cliente' onChange={onChange} value={value} />
		</InputWrapper>
	)
}

export default SearchCustomer
