import styled from 'styled-components'

export const SummaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	background-color: var(--color-white);
	border-radius: 3px;

	@media (min-width: 640px) {
		height: 388px;
	}
`

export const SummaryHeaderContainer = styled.div`
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	width: 100%;
	border-bottom: 1px solid var(--color-gray-mid);
	min-height: 115px;
	padding: 15px;

	@media (min-width: 640px) {
		flex-wrap: nowrap;
		gap: 0;
		height: 115px;
		justify-content: space-between;
	}
`

export const SummaryCard = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;

	@media (min-width: 640px) {
		gap: 0;
		justify-content: space-between;
	}
`

export const SummaryIconContainer = styled.div`
	display: flex;
	justify-content: start;
`

export const SummaryCardTitle = styled.span`
	font-size: ${14 / 16}rem;
	line-height: ${14 / 16}rem;
	font-weight: var(--font-weight-bold);
	color: var(--color-black);
`

export const SummaryCardValue = styled.span`
	font-size: ${16 / 16}rem;
	line-height: ${18 / 16}rem;
	font-weight: var(--font-weight-extra-bold);
	color: var(--color-black);
`

export const SummaryChartTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 20px 0 0;
`

export const SummaryChartTitle = styled.h3`
	font-size: ${12 / 16}rem;
	font-weight: var(--font-weight-bold);
`

export const ChartContainer = styled.div`
	display: flex;
	padding: 10px;
	width: 100%;
	justify-content: space-between;
	margin: 0 auto;
	height: 100%;
	align-items: end;
	overflow-x: auto;
`

export const ChartBarDetailsContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-bottom: 5px;
`

export const ChartBarValue = styled.span`
	font-size: ${14 / 16}rem;
	text-align: center;
`

export const ChartBarValueLabel = styled.span`
	font-size: ${6 / 16}rem;
	text-align: center;
`

export const ChartBarContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
`

interface ChartBarProps {
	percentage: number
}

export const ChartBar = styled.div<ChartBarProps>`
	${({ percentage }) => `height: ${(percentage / 100) * 145 + 5}px;`}
	${({ percentage }) => `background-color: ${percentage === 0 ? 'var(--color-gray-mid)' : 'red'};`}
	${({ percentage }) => `opacity: ${percentage === 0 ? 0.8 : percentage / 100};`}
	display: flex;
	width: 30px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
`

export const ChartBarLabel = styled.span`
	font-size: ${10 / 16}rem;
	margin-top: 10px;
	width: 100%;
	text-align: center;
	left: 3px;
	color: var(--color-black);
`
