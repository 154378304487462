import { Dispatch, SetStateAction } from 'react'
import { OperationMode, PartySize } from '../../pages/waitlist-settings/components'
import { Language } from '../../types/language.interface'
import { WeekDay } from '../../types/week-day.enum'
import { UpdateScheduleAction } from './reducers/waitlist-schedule.reducer'

export enum WaitingTimeMode {
	CRESCENT = 'Crescente - Tempo aguardando na fila',
	DECRESCENT = 'Decrescente - Tempo estimado para sentar',
}

export enum OrderTabsMode {
	AUTOMATIC = 'Automático (Sequenciada)',
	MANUAL = 'Manual (Hostess inclui na entrada)',
}

export interface TagInterface {
	icon?: string
	url?: string
	color?: string
	label: string
	active: boolean
}

export interface TagSettings {
	enabled: boolean
	tags: TagInterface[]
}

export interface EmailTemplateDetails {
	templateId?: string | null
	imageUrl: string
	originalLogo?: string
	mainColor: string
	textColor: string
	message: string
	dateColor: string
	buttonColor: string
	buttonTextColor: string
	backgroundColor: string
}

export interface EmailTemplateInterface {
	enabled: boolean
	template: EmailTemplateDetails
	templateId: string | null
	useSmsText: boolean
}

export interface AlertInterface {
	enabled: boolean
	message: Language
}

export enum Method {
	BASIC = 'basic',
	ADVANCED = 'advanced',
	DEFAULT = 'default',
}

export interface ShiftRangeInterface {
	openingTime: string
	closingTime: string
}

export interface DailySchedule {
	active: boolean
	timeRange: ShiftRangeInterface
}

export interface WaitlistSchedule {
	method: Method
	weeklySchedules: Record<WeekDay, DailySchedule>
}

export type WaitlistSettingsContextType = {
	waitlistActive: boolean
	setWaitlistActive: Dispatch<SetStateAction<boolean>>
	waitlistSettingsId: string
	setWaitlistSettingsId: Dispatch<SetStateAction<string>>
	queues: PartySize[]
	setQueues: Dispatch<SetStateAction<PartySize[]>>
	waitlistSchedule: WaitlistSchedule
	dispatchWaitlistSchedule: Dispatch<UpdateScheduleAction>
	selectedOperationMode: OperationMode
	setSelectedOperationMode: Dispatch<SetStateAction<OperationMode>>
	hasAutomaticRange: boolean
	setHasAutomaticRange: Dispatch<SetStateAction<boolean>>
	widgetTagmeMaxPartySize: number
	setWidgetTagmeMaxPartySize: Dispatch<SetStateAction<number>>
	widgetTagmeMinPartySize: number
	setWidgetTagmeMinPartySize: Dispatch<SetStateAction<number>>
	googleActive: boolean
	setGoogleActive: Dispatch<SetStateAction<boolean>>
	googleMaxPartySize: number
	setGoogleMaxPartySize: Dispatch<SetStateAction<number>>
	googleMinPartySize: number
	setGoogleMinPartySize: Dispatch<SetStateAction<number>>
	showPositionWidget: boolean
	setShowPositionWidget: Dispatch<SetStateAction<boolean>>
	showWaitingTimeWidget: boolean
	setShowWaitingTimeWidget: Dispatch<SetStateAction<boolean>>
	showWaitingTimeHostess: boolean
	setShowWaitingTimeHostess: Dispatch<SetStateAction<boolean>>
	waitingTimeWidgetMode: WaitingTimeMode
	setWaitingTimeWidgetMode: Dispatch<SetStateAction<WaitingTimeMode>>
	waitingTimeHostessMode: WaitingTimeMode
	setWaitingTimeHostessMode: Dispatch<SetStateAction<WaitingTimeMode>>
	delayTolerance: number
	setDelayTolerance: Dispatch<SetStateAction<number>>
	smsActive: boolean
	setSmsActive: Dispatch<SetStateAction<boolean>>
	smsEntry: boolean
	setSmsEntry: Dispatch<SetStateAction<boolean>>
	smsEntryMessage: string
	setSmsEntryMessage: Dispatch<SetStateAction<string>>
	smsCall: boolean
	setSmsCall: Dispatch<SetStateAction<boolean>>
	smsCallMessage: string
	setSmsCallMessage: Dispatch<SetStateAction<string>>
	smsDelay: boolean
	setSmsDelay: Dispatch<SetStateAction<boolean>>
	smsDelayMessage: string
	setSmsDelayMessage: Dispatch<SetStateAction<string>>
	smsCancel: boolean
	setSmsCancel: Dispatch<SetStateAction<boolean>>
	smsCancelMessage: string
	setSmsCancelMessage: Dispatch<SetStateAction<string>>
	smsSeat: boolean
	setSmsSeat: Dispatch<SetStateAction<boolean>>
	smsSeatMessage: string
	setSmsSeatMessage: Dispatch<SetStateAction<string>>
	emailActive: boolean
	setEmailActive: Dispatch<SetStateAction<boolean>>
	entryEmailTemplate: EmailTemplateInterface
	setEntryEmailTemplate: Dispatch<SetStateAction<EmailTemplateInterface>>
	callEmailTemplate: EmailTemplateInterface
	setCallEmailTemplate: Dispatch<SetStateAction<EmailTemplateInterface>>
	delayEmailTemplate: EmailTemplateInterface
	setDelayEmailTemplate: Dispatch<SetStateAction<EmailTemplateInterface>>
	seatEmailTemplate: EmailTemplateInterface
	setSeatEmailTemplate: Dispatch<SetStateAction<EmailTemplateInterface>>
	cancelEmailTemplate: EmailTemplateInterface
	setCancelEmailTemplate: Dispatch<SetStateAction<EmailTemplateInterface>>
	waitingSpotsTags: TagSettings
	setWaitingSpotsTags: Dispatch<SetStateAction<TagSettings>>
	accommodationSpotsTags: TagSettings
	setAccommodationSpotsTags: Dispatch<SetStateAction<TagSettings>>
	priorityTags: TagSettings
	setPriorityTags: Dispatch<SetStateAction<TagSettings>>
	generalInformationWidget: AlertInterface
	setGeneralInformationWidget: Dispatch<SetStateAction<AlertInterface>>
	generalInformation: AlertInterface
	setGeneralInformation: Dispatch<SetStateAction<AlertInterface>>
	priorityClient: AlertInterface
	setPriorityClient: Dispatch<SetStateAction<AlertInterface>>
	standartClient: AlertInterface
	setStandartClient: Dispatch<SetStateAction<AlertInterface>>
	callSoon: AlertInterface
	setCallSoon: Dispatch<SetStateAction<AlertInterface>>
	tabsControl: boolean
	setTabsControl: Dispatch<SetStateAction<boolean>>
	tabsOperationMode: OrderTabsMode
	setTabsOperationMode: Dispatch<SetStateAction<OrderTabsMode>>
	firstTabNumber: string
	setFirstTabNumber: Dispatch<SetStateAction<string>>
	lastTabNumber: string
	setLastTabNumber: Dispatch<SetStateAction<string>>
	finishFirstShift: string
	setFinishFirstShift: Dispatch<SetStateAction<string>>
	finishSecondShift: string
	setFinishSecondShift: Dispatch<SetStateAction<string>>
	dispatchData: any
}
