import Icon from "../../../../components/icon"
import { Summary } from "../../types"
import { SummaryCard, SummaryCardTitle, SummaryCardValue, SummaryContainer, SummaryHeaderContainer, SummaryIconContainer, ChartContainer, ChartBar, ChartBarContainer, ChartBarDetailsContainer, ChartBarValue, ChartBarValueLabel, SummaryChartTitleContainer, ChartBarLabel } from "./styled"


interface ChartBarProps {
    percentage: number
    key: string;
    value: number
}

const ChartBarComponent = (props: ChartBarProps) => {
    const { percentage, key, value } = props
    return (

        <ChartBarContainer key={key}>
            <ChartBarDetailsContainer>
                <ChartBarValue>{value}</ChartBarValue>
                <ChartBarValueLabel>pessoas</ChartBarValueLabel>
            </ChartBarDetailsContainer>
            <ChartBar percentage={percentage} />
            <ChartBarLabel>{Number(key) < 10 ? `0${key}:00` : `${key}:00`}</ChartBarLabel>
        </ChartBarContainer >
    )
}

interface SummaryReportProps {
    summary: Summary
}

const SummaryReport = (props: SummaryReportProps) => {
    const { summary } = props

    const generateChartBars = () => {
        const { avaregeClientsPerHour } = summary

        // Get highest value in valuesArray
        const highestValue = Math.max(...Object.values(avaregeClientsPerHour))

        const chartBars = []

        for (const key in avaregeClientsPerHour) {
            const value = avaregeClientsPerHour[key]
            const percentage = (value / highestValue) * 100
            chartBars.push(ChartBarComponent({ percentage, key, value }))
        }

        return chartBars
    }

    return (
        <SummaryContainer>
            <SummaryHeaderContainer>
                <SummaryCard>
                    <SummaryIconContainer>
                        <Icon id="LOCATION" height="24px" />
                    </SummaryIconContainer>
                    <SummaryCardTitle>Total de entradas na Lista</SummaryCardTitle>
                    <SummaryCardValue>{summary.totalEntrances}</SummaryCardValue>
                </SummaryCard>
                <SummaryCard>
                    <SummaryIconContainer>
                        <Icon id="USER" height="24px" />
                    </SummaryIconContainer>
                    <SummaryCardTitle>Total de pessoas na Lista</SummaryCardTitle>
                    <SummaryCardValue>{summary.totalClients}</SummaryCardValue>
                </SummaryCard>
                <SummaryCard>
                    <SummaryIconContainer>
                        <Icon id="TIME" height="24px" />
                    </SummaryIconContainer>
                    <SummaryCardTitle>Tempo médio na Lista</SummaryCardTitle>
                    <SummaryCardValue>{summary.averageWaitingTime}</SummaryCardValue>
                </SummaryCard>
                <SummaryCard>
                    <SummaryIconContainer>
                        <Icon id="ARROWTOTOP" height="24px" />
                    </SummaryIconContainer>
                    <SummaryCardTitle>Total de clientes sentados</SummaryCardTitle>
                    <SummaryCardValue>{summary.totalSeatedClients}</SummaryCardValue>
                </SummaryCard>
                <SummaryCard>
                    <SummaryIconContainer>
                        <Icon id="ARROWTOTOP" height="24px" />
                    </SummaryIconContainer>
                    <SummaryCardTitle>Total de clientes cancelados</SummaryCardTitle>
                    <SummaryCardValue>{summary.totalCanceledClients}</SummaryCardValue>
                </SummaryCard>
            </SummaryHeaderContainer>
            <SummaryChartTitleContainer>
                <SummaryCardTitle>Média de frequência</SummaryCardTitle>
            </SummaryChartTitleContainer>
            <ChartContainer>
                {generateChartBars()}
            </ChartContainer>
        </SummaryContainer>
    )
}


export default SummaryReport