import TimeInput from "../../../../../../components/form/time-input"
import { TimeRangeLabel, TimeRangeWrapper, TimeWrapper } from "./styled"
import { TimeRangeInterface } from "../../../../../../types/time-range.interface"


interface TimeRangePropsInterface {
    startRange: string
    finishRange: string
    onChange: ({ startRange, finishRange }: TimeRangeInterface) => void
}

const TimeRange = ({ startRange, finishRange, onChange }: TimeRangePropsInterface) => {

    const handleStartChange = (time: string) => {
        onChange({ startRange: time, finishRange });
    };

    const handleFinishChange = (time: string) => {
        onChange({ startRange, finishRange: time });
    };

    return (
        <TimeRangeWrapper>
            <TimeWrapper>
                <TimeRangeLabel>Abertura</TimeRangeLabel>
                <TimeInput
                    placeholder="Horário de abertura"
                    time={startRange}
                    onChange={handleStartChange}
                />
            </TimeWrapper>
            <TimeWrapper>
                <TimeRangeLabel>Fechamento</TimeRangeLabel>
                <TimeInput
                    placeholder="Horário de fechamento"
                    time={finishRange}
                    onChange={handleFinishChange}
                />
            </TimeWrapper>
        </TimeRangeWrapper>

    )
}

export default TimeRange