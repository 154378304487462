import Icon, { IconProps } from '../../../../components/icon'
import { TagWrapper } from './styled'

interface TagProps extends Omit<IconProps, 'id' | 'onClick'> {
	name: string
	border?: string
	color?: string
	iconId?: string
	url?: string
	filter?: boolean
	onClick?: (e: any) => void
	id?: string
}

const DisplayTag = ({
	iconId,
	url,
	name,
	border,
	color,
	filter,
	fill,
	id,
	onClick,
	...delegated
}: TagProps) => {
	const closeIcon = onClick ? true : false
	return (
		<TagWrapper border={border} color={color} onClick={onClick} data-id={id} $filter={filter}>
			{iconId ? (
				<>
					<Icon id={iconId} {...delegated} /> {name}{' '}
					{closeIcon && <Icon id="CLOSE" width="7px" strokeWidth="2px" />}
				</>
			) : (
				<>
					{url && <img height="16px" width="16px" src={url} alt="Imagem que representa um ícone" />}
					{name}
				</>
			)}
		</TagWrapper>
	)
}

export default DisplayTag
