import styled from 'styled-components'

export const AlertWrapper = styled.span<{
	isWarning?: boolean
}>`
	margin: 0px auto;
	border-radius: 3px;
	box-sizing: border-box;
	${({ isWarning }) =>
		isWarning
			? 'background: var(--color-red-light); color: var(--color-red-dark); border:  1px solid var(--color-red-mid);'
			: 'background: var(--color-gold-light); color: var(--color-gold-dark); border:  1px solid var(--color-gold-mid);'}
	width: 100%;
	padding: 10px;
	line-height: 18px;

	@media (min-width: 620px) {
		text-align: center;
	}
`
