import Icon from '../../../../../components/icon'
import { Box, BoxWrapper, InputBox, Title } from '../styled'

interface TableProps {
	as?: 'static'
	table?: string
	onChangeTable?: (table: string) => void
}

const Table = ({ as, table: tableProp, onChangeTable }: TableProps) => {
	const handleChange = (event: any) => {
		let value = event.target.value
		value = value !== '' ? value : undefined
		onChangeTable && onChangeTable(value)
	}
	const placeholder = tableProp ? tableProp.toString() : 'Sem N°'
	return (
		<BoxWrapper>
			<Title>N° Mesa</Title>
			<Box>
				<Icon id="TABLE" width="16px" />
				{as === 'static' ? (
					<span>{placeholder}</span>
				) : (
					<InputBox placeholder={placeholder} onChange={handleChange} />
				)}
			</Box>
		</BoxWrapper>
	)
}

export default Table
