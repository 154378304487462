import styled from 'styled-components/macro'

export const Tooltip = styled.span`
	position: relative;
	border-radius: 3px;
	color: var(--color-gold-dark);
	background-color: var(--color-gold-light);
	padding: 10px;
	border: 1px solid var(--color-gold-mid);
	font-size: ${14 / 16}rem;

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 10px;
		height: 10px;
		border-right: 1px solid var(--color-gold-mid);
		border-bottom: 1px solid var(--color-gold-mid);
		background-color: var(--color-gold-light);
		margin-left: -5px;
		margin-top: -4px;
		transform: rotate(45deg);
	}
`
