import {
	Wrapper,
	Info,
	Center,
	Row,
	ActionButton,
	Title,
	Name,
	PartySize,
	Time,
	InfoWrapper,
	HeaderWrapper,
	EditButton,
} from './styled'
import CustomerTab from './customer-tab'
import SaveButton from './save-button'
import Table from './table'
import { useContext, useState } from 'react'
import { WaitlistSettings } from '../../../../types/waitlist-settings.interface'
import { isAuthorized } from '../../../../utils/authorize-role'
import { UserRole } from '../../../../types/user-role.type'
import { AuthContext } from '../../../../contexts/auth/auth.context'

interface ModalContentActionsProps {
	table?: string
	name: string
	partySize: number
	waitingTime: string
	customerTab: string
	waitlistSettings: WaitlistSettings | undefined
	onCall: () => void
	onSeat: () => void
	onDelete: () => void
	onEdit: () => void
	onSave: ({ customerTab, table }: { customerTab?: string; table?: string }) => void
}

const ModalContentButtons = ({
	name,
	onCall,
	onSeat,
	onSave,
	onDelete,
	onEdit,
	waitingTime,
	waitlistSettings,
	table: tableProp,
	partySize: partySizeProp,
	customerTab: customerTabProp,
}: ModalContentActionsProps) => {
	const partySize = partySizeProp === 1 ? `${partySizeProp} pessoa` : `${partySizeProp} pessoas`
	const [table, setTable] = useState<string | undefined>(tableProp)
	const [customerTab, setCustomerTab] = useState<string | undefined>(customerTabProp)
	const authContext = useContext(AuthContext)
	const userRole = authContext?.ssoTagmeToken?.role || UserRole.VIEWER

	const [, setLoadingAction] = useState(false)

	const disableSave = !(table !== tableProp || customerTab !== customerTabProp)
	const handleCustomerTab = (customerTab: string) => {
		setCustomerTab(customerTab)
	}

	const handleTable = (table: string) => {
		setTable(table)
	}

	const onSaveAndCall = async () => {
		setLoadingAction(true)
		await onSave({ customerTab, table })
		setTimeout(async () => {
			await onCall()
		}, 500); // Atraso de 0,5s para atualizar o número da mesa
		setLoadingAction(false)
	}

	return (
		<Wrapper>
			<HeaderWrapper>
				<InfoWrapper>
					<Name>{name}</Name>
					<Info>
						<PartySize>{partySize}</PartySize> - <Time>{waitingTime} minutos na espera</Time>
					</Info>
				</InfoWrapper>
				{isAuthorized({ authorizedRole: UserRole.OPERATOR, userRole }) && (
					<EditButton onClick={onEdit}>Editar</EditButton>
				)}
			</HeaderWrapper>
			<Center>
				<Row>
					<CustomerTab
						customerTab={customerTab}
						onChangeCustomertab={handleCustomerTab}
						automatic={waitlistSettings?.customerTabs.automatic}
					/>
					<Table table={table} onChangeTable={handleTable} />
					<SaveButton onSave={() => onSave({ customerTab, table })} disabled={disableSave} />
				</Row>
			</Center>
			<Title>Ações</Title>
			<Row>
				<ActionButton bg="var(--color-danger)" onClick={onDelete}>
					Excluir
				</ActionButton>
				<ActionButton bg="var(--color-success)" onClick={onSaveAndCall}>
					Chamar
				</ActionButton>
				<ActionButton bg="var(--color-info)" onClick={onSeat}>
					Sentar
				</ActionButton>
			</Row>
		</Wrapper>
	)
}
export default ModalContentButtons
