import { customerApi } from '../../config/axios.config'
import { ReportsAdapter } from './reports.adapter'
import { DetailsReportDto, ReportSummaryFromAPI, ReportsDetailsFromApi, SummaryReportDto } from './types'

export class ReportService {
	static async getSummaryReport({
		venueId,
		shift,
		startDate,
		endDate,
	}: SummaryReportDto): Promise<ReportSummaryFromAPI> {
		const response = await customerApi.get(
			`/waitlists/venues/${venueId}/statistics?shift=${shift}&startDate=${ReportsAdapter.formatDate(
				startDate
			)}&endDate=${ReportsAdapter.formatDate(endDate)}`
		)

		const formattedResponse = response?.data?.data
		return formattedResponse
	}

	static async getDetailedReport({
		venueId,
		shift,
		startDate,
		endDate,
		page,
		limit,
	}: DetailsReportDto): Promise<ReportsDetailsFromApi> {
		const response = await customerApi.get(
			`/waitlists/venues/${venueId}/reports?shift=${shift}&startDate=${ReportsAdapter.formatDate(
				startDate
			)}&endDate=${ReportsAdapter.formatDate(endDate)}&page=${page}&limit=${limit}`
		)

		return response?.data
	}
}
