import styled from 'styled-components/macro'
import MaxWidthWrapper from '../../../components/max-width-wrapper'

export const SettingsWrapper = styled(MaxWidthWrapper)`
	padding: 20px 16px;
	height: 100%;
`

export const InnerWrapper = styled.div`
	margin-bottom: 20px;
`
