import { useContext, useEffect, useState } from 'react'
import {
	Wrapper,
	TodaysDate,
	Left,
	WrapperTitle,
	NumberTags,
	FilterWrapper,
	NavigateOptionsContainer,
	NavigateOptionCard,
	NavigateOptionText,
	NavigateHeader,
	NavigateHeaderText,
	TagContainer,
	TagArrow,
	TagBody,
	TagText,
	NavigateAnchor,
	Venue,
	VenueOptionText,
	IconContainer,
} from './styled'
import Icon from '../icon'
import { UnstyledButton } from '../unstyled-button'
import { formatWeekDay } from '../../utils/format-weekday'
import { AuthContext } from '../../contexts/auth/auth.context'
import Modal from '../modal'
import { useNavigate } from 'react-router-dom'
import { Venue as VenueInterface } from '../../contexts/auth/types'
import { UserRole } from '../../types/user-role.type'
import { isAuthorized } from '../../utils/authorize-role'
import { VenueContext } from '../../contexts/venue/venue.context'

export enum CurrentPageType {
	OPERATIONAL = 'OPERATIONAL',
	SETTINGS = 'SETTINGS',
	REPORTS = 'REPORTS',
	ADMIN = 'ADMIN',
	CRM = 'CRM',
}

interface NavigationModalProps {
	currentPage?: CurrentPageType
	onClose: () => void
	venueId: string
	userRole: UserRole
}

const Tag = () => {
	return (
		<TagContainer>
			<TagArrow />
			<TagBody>
				<TagText>Você está aqui</TagText>
			</TagBody>
		</TagContainer>
	)
}

const NavigationModal = ({ currentPage, onClose, venueId, userRole }: NavigationModalProps) => {
	const navigate = useNavigate()

	const handleGoToOldWaitlist = () => {
		// localStorage.removeItem('ssoTagmeToken')
		window.location.href = `${process.env.REACT_APP_LEGACY_WAITLIST_URL}/admin/reservations`
	}

	return (
		<Modal onClose={onClose}>
			<NavigateHeader>
				<Icon id="TAGMELOGO" width="107px" />
				<NavigateHeaderText>Apps</NavigateHeaderText>
			</NavigateHeader>
			<NavigateOptionsContainer>
				<NavigateOptionCard
					selected={currentPage === CurrentPageType.OPERATIONAL}
					onClick={() => navigate(`/operational/${venueId}`)}
				>
					<Icon id="OPERATIONAL" />
					<NavigateOptionText>Operacional da Lista de espera</NavigateOptionText>
					{currentPage === CurrentPageType.OPERATIONAL && <Tag />}
				</NavigateOptionCard>

				{isAuthorized({ authorizedRole: UserRole.MANAGER, userRole }) && (
					<NavigateOptionCard
						selected={currentPage === CurrentPageType.SETTINGS}
						onClick={() => navigate(`/settings/${venueId}`)}
					>
						<Icon id="SETTINGS2" />
						<NavigateOptionText>Configurações da Lista de espera</NavigateOptionText>
						{currentPage === CurrentPageType.SETTINGS && <Tag />}
					</NavigateOptionCard>
				)}
				{isAuthorized({ authorizedRole: UserRole.MANAGER, userRole }) && (
					<NavigateOptionCard
						selected={currentPage === CurrentPageType.REPORTS}
						onClick={() => navigate(`/reports/${venueId}`)}
					>
						<Icon id="ADMIN" />
						<NavigateOptionText>Relatórios</NavigateOptionText>
						{currentPage === CurrentPageType.REPORTS && <Tag />}
					</NavigateOptionCard>
				)}

				<NavigateOptionCard onClick={handleGoToOldWaitlist}>
					<Icon id="ADMIN" />
					<NavigateOptionText>Tagme Waitlist</NavigateOptionText>
				</NavigateOptionCard>

				<NavigateAnchor href={`${process.env.REACT_APP_CRM_URL}`}>
					<NavigateOptionCard>
						<Icon id="CRM" />
						<NavigateOptionText>CRM</NavigateOptionText>
					</NavigateOptionCard>
				</NavigateAnchor>

				<NavigateOptionCard onClick={() => navigate(`/loggout`)}>
					<Icon id="SETTINGS" />
					<NavigateOptionText>Sair da Lista de espera</NavigateOptionText>
				</NavigateOptionCard>
			</NavigateOptionsContainer>
		</Modal>
	)
}

interface VenuesModalProps {
	onClose: () => void
	venues: VenueInterface[]
	venueId: string
}

const VenuesModal = ({ onClose, venues, venueId }: VenuesModalProps) => {
	const navigate = useNavigate()
	const { setVenueId } = useContext(VenueContext)

	const handleChangeVenue = (venueId: string) => {
		setVenueId(venueId)
		onClose()
		navigate(`/operational/${venueId}`)
	}

	return (
		<Modal onClose={onClose}>
			<NavigateOptionsContainer>
				{venues?.map(venue => {
					return (
						<NavigateOptionCard
							key={venue._id}
							selected={venue._id === venueId}
							onClick={() => handleChangeVenue(venue._id)}
						>
							<VenueOptionText>{venue.name}</VenueOptionText>
							{venue._id === venueId && <Tag />}
						</NavigateOptionCard>
					)
				})}
			</NavigateOptionsContainer>
		</Modal>
	)
}

interface NavbarProps {
	onFilter?: () => void
	onUnion?: () => void
	numberTags?: number
	hasTitle?: true
	hasDate?: boolean
	hasDropDown?: boolean
	hasFilter?: boolean
	hasSettings?: boolean
	hasGrid?: boolean
	venueId: string
	currentPage?: CurrentPageType
}

const Navbar = ({
	onFilter,
	onUnion,
	numberTags,
	hasTitle,
	hasDate,
	hasFilter,
	hasSettings,
	hasGrid,
	venueId,
	currentPage,
}: NavbarProps) => {
	const [NOW, setNOW] = useState<Date>(new Date())
	const [showNavigationModal, setShowNavigationModal] = useState<boolean>(false)
	const [showVenuesModal, setShowVenuesModal] = useState<boolean>(false)

	const authContext = useContext(AuthContext)

	const userRole = authContext?.ssoTagmeToken?.role || UserRole.VIEWER

	const venues = authContext?.ssoTagmeToken?.venues

	let hasMultipleVenues = false

	if (venues && venues.length > 0) hasMultipleVenues = true

	useEffect(() => {
		const id = setInterval(() => {
			setNOW(new Date())
		}, 1000)

		return () => clearInterval(id)
	}, [])

	const todaysWeekday = formatWeekDay(NOW)

	const todaysDate = NOW.toLocaleDateString('pt-br', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	})

	const todaysTime = NOW.toLocaleTimeString('pt-br', {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	})

	const handleVenueName = () => {
		const venue = authContext?.ssoTagmeToken?.venues?.find(venue => venue._id === venueId)

		return venue?.name
	}

	return (
		<>
			<Wrapper>
				<Left>
					<WrapperTitle>
						{hasTitle && <Venue>{handleVenueName()}</Venue>}
						{hasMultipleVenues && (
							<IconContainer onClick={() => setShowVenuesModal(true)} rotate={showVenuesModal}>
								<Icon fill="primary" width="16px" id="ARROWDOWN" />
							</IconContainer>
						)}
					</WrapperTitle>
					{hasDate && (
						<TodaysDate>
							{todaysWeekday} {todaysDate} • {todaysTime}
						</TodaysDate>
					)}
				</Left>

				{hasFilter && (
					<FilterWrapper onClick={onFilter} title="Filtro">
						{numberTags !== 0 && <NumberTags>{numberTags}</NumberTags>}
						<Icon width="18.67px" id="FILTER" />
					</FilterWrapper>
				)}

				{hasSettings && (
					<UnstyledButton onClick={onUnion} title="Histórico">
						<Icon width="20px" id="UNION" mr="-5px" />
					</UnstyledButton>
				)}

				{hasGrid && (
					<UnstyledButton onClick={() => setShowNavigationModal(true)}>
						<Icon width="40px" px="5px" py="5px" id="DOTMATRIX" circle />
					</UnstyledButton>
				)}
			</Wrapper>
			{showNavigationModal && (
				<NavigationModal
					onClose={() => setShowNavigationModal(false)}
					currentPage={currentPage}
					venueId={venueId}
					userRole={userRole}
				/>
			)}
			{showVenuesModal && venues && (
				<VenuesModal onClose={() => setShowVenuesModal(false)} venues={venues} venueId={venueId} />
			)}
		</>
	)
}

export default Navbar
