import { Size } from '.'
import styled from 'styled-components'

interface SwitchInterface {
	isActive: boolean
	size?: Size
}

const handleSwitchDivSize = (size: Size | undefined) => {
	switch (size) {
		case Size.SMALL:
			return 'width: 30px; height: 14px;'
		default:
			return 'width: 48px; height: 30px;'
	}
}

const handleSwitchSize = (size: Size | undefined) => {
	switch (size) {
		case Size.SMALL:
			return 'width: 10px; height: 10px;'
		default:
			return 'width: 26px; height: 26px;'
	}
}

interface Margin {
	margin?: string
}

export const Wrapper = styled.div<Margin>`
	 {
		display: flex;
		align-items: center;
		${({ margin }) => margin && `margin: ${margin}`}
	}
`

export const SwitchWrapper = styled.div`
	position: relative;
	background-color: transparent;
	display: flex;
	align-items: center;
`
export const SwitchLabel = styled.label.attrs({ htmlFor: 'checkbox' })<SwitchInterface>`
	position: absolute;
	top: 0;
	left: 0;
	${({ size }) => handleSwitchDivSize(size)};
	border: 1px solid var(--color-gray-mid);
	border-radius: 15px;
	background: var(--color-gray-light);
	cursor: pointer;
	&::after {
		content: '';
		display: block;
		border-radius: 50%;
		${({ size }) => handleSwitchSize(size)};

		margin: 1px;
		background: #ffffff;
		box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.2);
		transition: 0.2s;
	}
	${({ isActive }) =>
		isActive &&
		`background: var(--color-success);
		&::after {
			content: '';
			display: block;
			border-radius: 50%;
			${({ size }: any) => handleSwitchSize(size)}
			margin-left: 19px;
			transition: 0.2s;
		}`};
`

export const Switch = styled.input.attrs({ type: 'checkbox', id: 'checkbox' })<any>`
	opacity: 0;
	z-index: 1;
	border-radius: 15px;
	${({ size }) => handleSwitchDivSize(size)};
`
interface TextWrapperInterface {
	isActive?: boolean
}

export const TextWrapper = styled.span<TextWrapperInterface>`
	margin-left: 10px;
	${({ isActive }) => (isActive ? 'color: var(--color-success)' : 'color: var(--color-gray-mid-2)')};
`
