import { ChangeEvent } from "react"
import Input from "../input"

interface TimeInputInterface {
    time: string
    placeholder: string
    onChange: (value: string) => void
}


const TimeInput = ({ time, placeholder, onChange }: TimeInputInterface) => {

    const startsWithTwo = time[0] === '2'

    const mask = [
        /[0-2]/,
        startsWithTwo ? /[0-3]/ : /[0-9]/,
        ':',
        /[0-5]/,
        /[0-9]/
    ]

    const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        onChange(e.target.value.replace('_', '0'))
    }


    return (<Input
        placeholder={placeholder}
        mask={mask}
        type="text"
        value={time}
        onChange={handleTimeChange}
    />)
}

export default TimeInput