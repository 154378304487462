import { Player } from '@lottiefiles/react-lottie-player'
import { DynamicIconWrapper, TextWrapper, DataSavedContent } from './styled'
import okSimple from '../../../../assets/lotties/ok-simple.json'

const DataSaved = () => {
	return (
		<DataSavedContent>
			<DynamicIconWrapper>
				<Player
					autoplay
					loop
					src={okSimple}
					style={{ height: '299px', width: '299px' }}
				></Player>
			</DynamicIconWrapper>
			<TextWrapper>Dados salvos com sucesso!</TextWrapper>
		</DataSavedContent>
	)
}

export default DataSaved
