import { Dispatch, SetStateAction } from 'react'
import { SwitchWrapper, Switch, SwitchLabel, Wrapper, TextWrapper } from './styled'

export enum Size {
	SMALL = 'small',
}
interface ToggleSwitchProps {
	children?: any
	state: boolean
	setState?: Dispatch<SetStateAction<boolean>>
	disabled?: boolean
	size?: Size | undefined
	handleChange?: () => void
	margin?: string
}

const ToggleSwitch = ({
	children,
	state,
	setState,
	disabled,
	size,
	handleChange,
	margin
}: ToggleSwitchProps) => {
	const handleClick = () => {
		if (setState) {
			setState(!state)
		}

		if (handleChange) {
			handleChange()
		}
	}

	const handleChildren = () => {
		if (typeof children === 'string') {
			return (<TextWrapper isActive={state}>{state ? `${children} (Ligado)` : `${children} (Desligado)`}</TextWrapper>)
		}

		return children
	}

	return (
		<Wrapper margin={margin}>
			<SwitchWrapper>
				<Switch onClick={() => handleClick()} disabled={disabled} size={size} />
				<SwitchLabel isActive={state} size={size}></SwitchLabel>
			</SwitchWrapper>
			{handleChildren()}
		</Wrapper>
	)
}

export default ToggleSwitch
