import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { TagSettings } from '../../../../../../contexts/waitlist-settings/types'
import { Button } from '../../../../../../components/button'
import TagCard from '../tag-card'
import { ButtonWrapper, FlexWrap } from './styled'

export interface WaitingSpotInterface {
	icon?: string
	label: string
	url?: string
	active: boolean
}

export enum TagsListEnum {
	WAITING_SPOT = 'waiting-spot',
	ACCOMMODATION_SPOT = 'accomodation-spot',
	PRIORITY = 'priority',
}

interface TagsProps {
	tagSettings: TagSettings
	setTagSettings: Dispatch<SetStateAction<TagSettings>>
	type: TagsListEnum
}

const TagsList = ({ tagSettings, setTagSettings, type }: TagsProps) => {

	const handleToggleTag = (index: number) => {
		const newSettings = { ...tagSettings }
		newSettings.tags[index].active = !tagSettings.tags[index].active
		setTagSettings(newSettings)
	}

	const handleDeleteTag = (index: number) => {
		const newSettings = { ...tagSettings }
		newSettings.tags.splice(index, 1)
		setTagSettings(newSettings)
	}


	const generateTagsCards = () => {
		return tagSettings.tags?.map((item, idx) => (
			<TagCard key={idx} item={item} handleToggleTag={() => handleToggleTag(idx)} handleDeleteTag={() => handleDeleteTag(idx)} />
		))
	}

	const navigate = useNavigate()

	const handleCreateNewTag = () => {
		switch (type) {
			case TagsListEnum.WAITING_SPOT:
				navigate(`/tag/${TagsListEnum.WAITING_SPOT}`)
				break
			case TagsListEnum.ACCOMMODATION_SPOT:
				navigate(`/tag/${TagsListEnum.ACCOMMODATION_SPOT}`)
				break
			case TagsListEnum.PRIORITY:
				navigate(`/tag/${TagsListEnum.PRIORITY}`)
				break
		}
	}

	return (
		<>
			<FlexWrap>{generateTagsCards()}</FlexWrap>
			<ButtonWrapper>
				<Button
					height="46px"
					color="var(--color-primary)"
					bg="var(--color-white)"
					border="2px solid var(--color-primary)"
					width="100%"
					onClick={handleCreateNewTag}
				>
					Criar nova tag
				</Button>
			</ButtonWrapper>
		</>
	)
}

export default TagsList
