import styled from 'styled-components/macro'

export const UnstyledButton = styled.button<{ display?: string }>`
	display: ${props => props.display || 'block'};
	margin: 0;
	padding: 0;
	border: none;
	background: transparent;
	cursor: pointer;
	text-align: left;
	font: inherit;
	color: inherit;
`
