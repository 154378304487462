// Hooks
import { useCallback, useState } from 'react'
import useInput from '../../../../../hooks/use-input.hook'
import useModalContent from '../../modal-content-form/use-modal-content.hook'

// Commponents
import ButtonIcon from '../../../../../components/button-icon'
import Input from '../../../../../components/form/input'
import { Sizes, ModalContentForm, SuggestedCustomersModalContentForm } from '../../modal-content-form'
import { Button } from '../../../../../components/button'
import DisplayTag from '../../tag'
import SetNoteOrCustomerTab from '../set-note'

// Services
import { ValidationInputAdapter } from '../../../../../adapters/validation-input.adapter'
import { FullNameAdapter } from '../../../../../adapters/full-name.adapter'
import { tagsAdapterToBd } from '../../../../../adapters/waitlist.adapter'

// Interfaces
import { SuggestedCustomer } from '../../../../../types/suggested-customer.interface'
import { WaitlistSettings } from '../../../../../types/waitlist-settings.interface'

// Styled Components
import {
	FormWrapper,
	SubmitButton,
	WrapperModalContent,
	WaitingPlacesList,
	TypeCustomerList,
	PhoneWrapper,
	PartySizeWrapper,
	PlacesWrapper,
	NoteWrapper,
	NameWrapper,
	EmailWrapper,
	PriorityWrapper,
	HeaderWrapper,
	Title,
	// AccomodationPlacesList,
} from './styled'
import MaxWidthWrapper from '../../../../../components/max-width-wrapper'

// Utils
import { OriginLabel, UpdateWaitlistDto } from '../../../../../service/waitlist/types'
import { CustomerService } from '../../../../../service/customer'
import { Waitlist } from '../../../../../types/waitlist.interface'
import Icon from '../../../../../components/icon'
import NoteDisplay from '../../form-waitlist/note-display'

type ID_COMPONENTS_FORM = 'partySize' | 'places' | 'priorities' | 'customers'
type ID_COMPONENTS_IMAGE = 'note'

interface FormWaitlistProps {
	onUpdateWaitlist?: (waitlistId: string, waitlist: UpdateWaitlistDto) => void
	onGoBack: () => void
	waitlistSettings: WaitlistSettings | undefined
	waitlist: Waitlist
}

const EditWaitlist = ({ onUpdateWaitlist, waitlistSettings, waitlist, onGoBack }: FormWaitlistProps) => {
	// Modal for close modal on Form like WaitingPoint and TypeCustomer
	const [showModalForm, setShowModalForm] = useState(false)
	const closeModalForm = () => setShowModalForm(false)

	// Modal for Note (Observations) and CustomerTab (if enabled)
	// They are different because they have different top values
	const [showModalImage, setShowModalImage] = useState(false)

	// State for PartySize
	const [partySize, setPartysize] = useState<number>(waitlist.partySize)


	// State for Observations
	const [note, setNote] = useState<string | undefined>(waitlist.note)
	const [chooseComponentForm, setChooseComponentForm] = useState<ID_COMPONENTS_FORM>('partySize')
	const [chooseComponentImage, setChooseComponentImage] = useState<ID_COMPONENTS_IMAGE>('note')

	// State for suggested customers
	const [suggestedCustomers, setSuggestedCustomers] = useState<SuggestedCustomer[]>([])

	// use Modal Content Hook for Type Customer
	// This hook was created because there were a lot of repetitive code that is the same for this states
	// Under the hood, they all use useState hook but return a customized functions
	const [tagsPriorities, handleTagPriorityToggle, tagsPrioritiesActive, tagsPrioritiesReset] =
		useModalContent({ data: waitlistSettings?.tagsPriorities, callback: closeModalForm, initiallySelected: waitlist.tagsPriorities })

	const [tagsPlaces, handleTagsPlacesToggle, tagsPlacesActive, tagsPlacesReset] = useModalContent(
		{
			data: waitlistSettings?.tagsPlaces,
			callback: closeModalForm,
			initiallySelected: waitlist.tagsPlaces
		}
	)

	// Use Input Hook - Name
	// This hook was created for the same purpose as the above, reuse functions
	// Under the hood, they all use useState hook but return a customized functions
	const [
		fullName,
		fullNameIsValid,
		fullNameHasError,
		fullNameChangeHandler,
		fullNameBlurHandler,
		fullNameReset,
		setFullNameHandler
	] = useInput(ValidationInputAdapter.checkNameIsValid, waitlist.fullName)

	// Use Input Hook - Phone
	const [phone, phoneIsValid, phoneHasError, phoneChangeHandler, phoneBlurHandler, phoneReset] = useInput(
		ValidationInputAdapter.checkPhoneIsValid, waitlist.phone
	)

	// Use Input Hook - Email
	const [email, emailIsValid, emailHasError, emailChangeHandler, emailBlurHandler, emailReset, setEmailHandler] = useInput(
		ValidationInputAdapter.checkEmailIsValid, waitlist.email
	)

	// Validate Form
	const formIsValid = fullNameIsValid && partySize

	// Reset Form
	const resetForm = () => {
		fullNameReset()
		phoneReset()
		emailReset()
		setPartysize(0)
		tagsPlacesReset()
		tagsPrioritiesReset()
		setNote(undefined)
	}


	const fetchSuggestedCustomersData = useCallback(async () => {
		if (phoneIsValid && phone) {
			const response = await CustomerService.getCustomersByPhone(phone)
			setSuggestedCustomers(response)

			return response
		}

	}, [phone, phoneIsValid])

	const handleChooseComponentForm = (id: ID_COMPONENTS_FORM) => {
		setShowModalForm(true)
		setChooseComponentForm(id)
	}

	const handleChooseComponentImage = (id: ID_COMPONENTS_IMAGE) => {
		setShowModalImage(true)
		setChooseComponentImage(id)
	}

	const handlePhoneBlur = async () => {
		phoneBlurHandler()

		const response = await fetchSuggestedCustomersData()

		if (response?.length) {
			handleChooseComponentForm('customers')
		}
	}

	// Handle onClick Value PartySize
	const handlePartySizeValue = (e: any) => {
		const partySize = +e.currentTarget?.dataset.value
		setPartysize(partySize)
		setShowModalForm(false)
	}

	const handleSelectSuggestedCustomer = (suggestedCustomer: SuggestedCustomer) => {
		const { name, lastName, email } = suggestedCustomer

		const fullName = `${name} ${lastName}`

		if (email) {
			setEmailHandler(email)
		} else {
			emailReset()
		}

		setFullNameHandler(fullName)
		setShowModalForm(false)
	}

	// Create PartySize Component based on Modal Content Markup
	const PartySizeModalContent = () => (
		<WrapperModalContent>
			<ModalContentForm
				info="Quantidade de pessoas"
				data={waitlistSettings?.partySizesForm || []}
				onClick={handlePartySizeValue}
				size={Sizes.MEDIUM}
			/>
		</WrapperModalContent>
	)

	const SuggestedCustomersModalContent = () => (
		<WrapperModalContent>
			<SuggestedCustomersModalContentForm
				info="Selecione o cliente"
				data={suggestedCustomers}
				handleSelectCustomer={handleSelectSuggestedCustomer}
				handleManuallyFill={setShowModalForm}
			/>
		</WrapperModalContent>
	)

	const PlacesModalContent = () => (
		<WrapperModalContent>
			<ModalContentForm
				info="Local de Espera"
				data={tagsPlaces}
				onClick={handleTagsPlacesToggle}
				size={Sizes.MEDIUM}
			/>
		</WrapperModalContent>
	)

	// Create TypeCustomer Component based on Modal Content Markup
	const TypeCustomerModalContent = () => (
		<WrapperModalContent>
			<ModalContentForm
				info="Tipo de Cliente"
				data={tagsPriorities}
				onClick={handleTagPriorityToggle}
				size={Sizes.MEDIUM}
			/>
		</WrapperModalContent>
	)

	// Asssign ID component to Functional Component on Map (like object {})
	const chooseModalFormComponent: Record<ID_COMPONENTS_FORM, () => JSX.Element> = {
		partySize: PartySizeModalContent,
		priorities: TypeCustomerModalContent,
		places: PlacesModalContent,
		customers: SuggestedCustomersModalContent,
	}
	const ComponentModalForm = chooseModalFormComponent[chooseComponentForm]

	const handleConfirmNote = (value: any) => {
		setShowModalImage(false)
		setNote(value)
	}

	const SetNote = () => (
		<SetNoteOrCustomerTab
			id="note"
			onClose={() => setShowModalImage(false)}
			value={note}
			onConfirm={handleConfirmNote}
		/>
	)




	const chooseModalImageComponent: Record<ID_COMPONENTS_IMAGE, () => JSX.Element> = {
		note: SetNote,
	}
	const ComponentModalImage = chooseModalImageComponent[chooseComponentImage]

	// const ComponentModalImage = componentsImage[]

	// Get PartySize name
	const partySizeName =
		partySize === 1 ? `${partySize} pessoa` : partySize ? `${partySize} pessoas` : 'Qtd. de pessoas'

	// Handle Form Submit
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!formIsValid) {
			return
		}

		const [name, lastName] = FullNameAdapter.set(fullName).getNameAndLastName()
		const updateWaitlist: UpdateWaitlistDto = {
			customer: {
				name,
				lastName,
				phone,
				email: email.length !== 0 ? email : undefined,
			},
			partySize,
			tagsWaitingPlaces: tagsAdapterToBd(
				tagsPlacesActive.filter(item => item?.origin && item.origin.includes('Espera'))
			),
			tagsAccommodationPlaces: tagsAdapterToBd(
				tagsPlacesActive.filter(item => item?.origin && item.origin.includes('Local'))
			),
			tagsPriorities: tagsAdapterToBd(tagsPrioritiesActive),
			priority: tagsPrioritiesActive.length !== 0,
			note,
			origin: {
				label: OriginLabel.RESTAURANT,
			},
		}

		// callback function to create new waitlist on the server
		if (onUpdateWaitlist)
			onUpdateWaitlist(waitlist.id, updateWaitlist)
		resetForm()
	}

	const handleGoBack = () => {
		resetForm()
		onGoBack()
	}

	// const isWaitingPlaces = tagsWaitingPlacesActive.length !== 0
	const isPlaces = tagsPlacesActive.length !== 0
	// const isAccomodationPlaces = tagsAccommodationPlacesActive.length !== 0
	const isPriorities = tagsPrioritiesActive.length !== 0

	const showCustomerTab = waitlistSettings?.customerTabs.enabled && !waitlistSettings?.customerTabs.automatic

	return (
		<>
			{showModalForm && (
				<ComponentModalForm />
			)}
			{showModalImage && (
				<ComponentModalImage />
			)}
			{!showModalForm && !showModalImage && <MaxWidthWrapper>
				<HeaderWrapper><Icon id='BACKARROW' width='24px' onClick={handleGoBack} /><Title>Editar Informaçoes</Title></HeaderWrapper>
				<FormWrapper noValidate onSubmit={handleSubmit}>
					<PhoneWrapper>
						<Input
							name="phone"
							placeholder="Telefone com DDD"
							mask="(99) 99999-9999"
							type="tel"
							value={phone}
							onChange={phoneChangeHandler}
							onBlur={handlePhoneBlur}
							isValid={phoneIsValid}
							hasError={phoneHasError}
						/>
					</PhoneWrapper>
					<PartySizeWrapper>
						<ButtonIcon
							id="ARROWDOWN"
							type="button"
							width="16px"
							height="8px"
							fill="primary"
							primary={true}
							onClick={() => handleChooseComponentForm('partySize')}
							isValid={!!partySize}
						>
							{partySizeName}
						</ButtonIcon>
					</PartySizeWrapper>
					<PlacesWrapper>
						<ButtonIcon
							id="OPLUS"
							type="button"
							width="18px"
							fill="primary"
							onClick={() => handleChooseComponentForm('places')}
							size={tagsPlacesActive.length}
						>
							Localização
						</ButtonIcon>
					</PlacesWrapper>
					{isPlaces && (
						<WaitingPlacesList>
							{tagsPlacesActive?.map(({ icon, url, color, fill, display }, idx) => (
								<DisplayTag
									key={idx}
									iconId={icon}
									url={url}
									fill={fill}
									color={color}
									width="20px"
									border="var(--color-gray-mid)"
									name={display}
								/>
							))}
						</WaitingPlacesList>
					)}
					<NoteWrapper>
						<NoteDisplay
							showCustomerTab={showCustomerTab}
							note={note}
							onConfirm={() => handleChooseComponentImage('note')}
						/>
					</NoteWrapper>
					<NameWrapper>
						<Input
							name="nome"
							placeholder="Nome e sobrenome"
							type="text"
							value={fullName}
							onChange={fullNameChangeHandler}
							onBlur={fullNameBlurHandler}
							isValid={fullNameIsValid}
							hasError={fullNameHasError}
							required
						/>
					</NameWrapper>
					<EmailWrapper>
						<Input
							name="email"
							placeholder="Email"
							type="email"
							value={email}
							onChange={emailChangeHandler}
							onBlur={emailBlurHandler}
							isValid={emailIsValid}
							hasError={emailHasError}
						/>
					</EmailWrapper>
					<PriorityWrapper>
						<ButtonIcon
							type="button"
							id="OPLUS"
							width="18px"
							fill="primary"
							primary={false}
							onClick={() => handleChooseComponentForm('priorities')}
							size={tagsPrioritiesActive.length}
						>
							Tipo de Cliente
						</ButtonIcon>
					</PriorityWrapper>
					{isPriorities && (
						<TypeCustomerList>
							{tagsPrioritiesActive?.map(({ icon, fill, color, display, url }, idx) => (
								<DisplayTag
									key={idx}
									iconId={icon}
									fill={fill}
									color={color}
									height="10px"
									width="15px"
									border="var(--color-gray-mid)"
									name={display}
									url={url}
								/>
							))}
						</TypeCustomerList>
					)}
					<SubmitButton>
						<Button secondary width={'100%'} height={'100%'} type="submit" disabled={!formIsValid}>
							Salvar
						</Button>
					</SubmitButton>
				</FormWrapper>
			</MaxWidthWrapper>}
		</>
	)
}

export default EditWaitlist
