import styled from 'styled-components/macro'

export const Wrapper = styled.div`
	padding: 20px 16px;
`

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const Name = styled.h1`
	font-weight: var(--font-weight-bold);
	font-size: ${20 / 16}rem;
`

export const Info = styled.div`
	font-weight: var(--font-weight-bold);
	font-size: ${12 / 16}rem;
`

export const PartySize = styled.span``

export const Time = styled.time``

export const Row = styled.div`
	display: flex;
	gap: 8px;
	justify-content: space-between;
`

export const Center = styled.div`
	margin-top: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid var(--color-gray-mid);
	border-bottom: 1px solid var(--color-gray-mid);
`

export const BoxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
`

export const Static = styled.span`
	color: inherit;
`

export const Box = styled.div<{
	center?: boolean
	primary?: boolean
	disabled?: boolean
	automatic?: boolean
}>`
	display: flex;
	align-items: center;
	gap: 6px;
	height: 30px;
	padding-left: 7px;
	border-radius: 3px;
	font-size: ${14 / 16}rem;
	border: 1px solid var(--color-gray-mid);
	${({ primary }) => primary && `color: var(--color-primary); border: 2px solid var(--color-primary);`};
	${({ center }) => center && `justify-content: center`};
	${({ disabled }) => disabled && `opacity: 0.3`};

	${({ automatic }) =>
		automatic &&
		`
			color: var(--color-gray-disabled);
			background-color: var(--color-gray-mid); `}
`

export const InputBox = styled.input.attrs({ type: 'text' })`
	background-color: inherit;
	width: 100%;
	border: none;
`

export const NumberTableWrapper = styled.div``

export const NumberTable = styled.div``

export const Title = styled.div`
	margin-top: 10px;
	margin-bottom: 9px;

	font-size: ${12 / 16}rem;
`

export const ActionButton = styled.button<{ bg: string }>`
	color: var(--color-white);
	flex: 1;
	font-size: ${14 / 16}rem;
	border-radius: 3px;
	background-color: ${({ bg }) => bg};
	height: 30px;
	border: none;
	cursor: pointer;
`

export const EditButton = styled.button`
	background-color: var(--color-gray-light);
	height: 22px;
	width: 81px;
	color: var(--color-primary);
	font-size: ${14 / 16}rem;
	border: 1px solid var(--color-gray-mid);
	border-radius: 3px;
	cursor: pointer;
`
