import { useContext, useEffect, useState } from 'react'
import { Waitlist } from '../../types/waitlist.interface'
import ListWaitlist from '../hostess-waitlist/components/list-waitlist'
import { HistoryCustomersWrapper, Title } from './styled'
import { waitlistListAdapter } from '../../adapters/waitlist.adapter'
import { WaitlistSettings } from '../../types/waitlist-settings.interface'
import { waitlistSettingsAdapter } from '../../adapters/waitlist-settings.adapter'
import GoBack from '../../components/go-back'
import { WaitlistSettingsService } from '../../service/waitlist-settings'
import { WaitlistService } from '../../service/waitlist'
import { VenueContext } from '../../contexts/venue/venue.context'
import FailModal from '../../components/fail-modal'
import Spinner from '../../components/spinner'

const HistoryCustomersPage: React.FC = () => {
	const [waitlistListHistory, setWaitlistListHistory] = useState<Waitlist[]>([])
	const [waitlistSettings, setWaitlistSettings] = useState<WaitlistSettings | undefined>()
	const [showFailModal, setShowFailModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const { venueId } = useContext(VenueContext)
	const { setVenueId } = useContext(VenueContext)
	setVenueId(venueId as string)

	useEffect(() => {
		const fetchWaitlist = async () => {
			try {
				setLoading(true)
				const results = await WaitlistService.getWaitlistHistory(venueId as string)
				if (results)
					setWaitlistListHistory(waitlistListAdapter(results))
				setLoading(false)
			} catch (error) {
				setShowFailModal(true)
			}
		}

		const fetchWaitlistSettingsData = async () => {
			const results = await WaitlistSettingsService.getWaitlistSettingsForHostess(venueId as string)
			setWaitlistSettings(waitlistSettingsAdapter(results))
		}

		fetchWaitlist()
		fetchWaitlistSettingsData()
	}, [venueId])

	const handleUpdateHistoryWaitlist = async () => {
		try {
			setLoading(true)
			const results = await WaitlistService.getWaitlistHistory(venueId as string)
			setWaitlistListHistory(waitlistListAdapter(results))
			setLoading(false)
		} catch (error) {
			setShowFailModal(true)
		}
	}

	return (
		<HistoryCustomersWrapper>
			<GoBack />
			<Title>Histórico de clientes</Title>
			{/* <DateWrapper> */}
			{/* 	<IconWrapper> */}
			{/* 		<Icon id="CALENDAR" width="16px" /> */}
			{/* 	</IconWrapper> */}
			{/* 	<TodaysDate>{humanReadableDate}</TodaysDate> */}
			{/* </DateWrapper> */}
			{loading && <Spinner />}
			<ListWaitlist
				waitlistList={waitlistListHistory}
				waitlistSettings={waitlistSettings}
				onUpdateHistory={handleUpdateHistoryWaitlist}
				venueId={venueId as string}
				history={true}
			/>
			{showFailModal && <FailModal onClose={() => setShowFailModal(false)} />}
		</HistoryCustomersWrapper>
	)
}

export default HistoryCustomersPage
