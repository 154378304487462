import styled from 'styled-components/macro'
import Icon from '../../../../../components/icon'
import { Box, BoxWrapper, Title, InputBox, Static } from '../styled'

interface CustomerTabProps {
	customerTab?: string
	automatic?: boolean
	onChangeCustomertab?: (customerTab: string) => void
	as?: 'static'
}

const CustomerTab = ({ as, customerTab, onChangeCustomertab, automatic }: CustomerTabProps) => {
	const handleChange = (event: any) => {
		let customerTab = event.target.value
		customerTab = customerTab !== '' ? customerTab : undefined
		onChangeCustomertab && onChangeCustomertab(customerTab)
	}
	as = automatic ? 'static' : as
	return (
		<BoxWrapper>
			<Title>Comanda</Title>
			<Box automatic={automatic}>
				<Icon id="CUSTOMERTAB" width="16px" />
				{as === 'static' ? (
					<Static>#{customerTab}</Static>
				) : (
					<>
						<Hash>#</Hash>
						<InputBox defaultValue={customerTab} onChange={handleChange} />
					</>
				)}
			</Box>
		</BoxWrapper>
	)
}

export default CustomerTab

const Hash = styled.span`
	margin-right: -7px;
`
