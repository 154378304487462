import {
	ArrowDown,
	OPlus,
	DotMatrix,
	Filter,
	Email,
	ExclamationMark,
	ExclamationMarkCircle,
	CustomerTab,
	Origin,
	WaitingPlace,
	Close,
	EmptyStar,
	Wheelchair,
	Vip,
	Person,
	Tagme,
	Union,
	Table,
	Calendar,
	Entrance,
	Man,
	Library,
	Parking,
	Sugar,
	BackArrow,
	Checkbox,
	Check,
	Search,
	Camera,
	TagmeLogo,
	Locker,
	Autist,
	BabyStroller,
	Baby,
	Chair,
	Chef,
	Elderly,
	EmptyPin,
	Pin,
	Star,
	FilledCircle,
	Globe,
	Indifferent,
	Key,
	MegaPhone,
	Pregnant,
	Sad,
	Smile,
	SmileBig,
	Tag,
	Voucher,
	Walker,
	Watch,
	Cross,
	CheckRadio,
	Location,
	User,
	Time,
	ArrowToTop,
	NavigateCrm,
	NavigateOperational,
	NavigateSettings,
	NavigateReports,
	NavigateAdmin
} from '../../assets/icons'

import { Wrapper } from './styled'


type IconRecord = Record<string, React.FunctionComponent>


const icons: IconRecord = {
	ARROWDOWN: ArrowDown,
	OPLUS: OPlus,
	SETTINGS: NavigateSettings,
	DOTMATRIX: DotMatrix,
	FILTER: Filter,
	EMAIL: Email,
	EXCLAMATIONMARK: ExclamationMark,
	EXCLAMATIONMARKCIRCLE: ExclamationMarkCircle,
	CUSTOMERTAB: CustomerTab,
	ORIGIN: Origin,
	WAITINGPLACE: WaitingPlace,
	CLOSE: Close,
	EMPTYSTAR: EmptyStar,
	WHEELCHAIR: Wheelchair,
	VIP: Vip,
	PERSON: Person,
	TAGME: Tagme,
	UNION: Union,
	TABLE: Table,
	CALENDAR: Calendar,
	ENTRANCE: Entrance,
	MAN: Man,
	LIBRARY: Library,
	PARKING: Parking,
	SUGAR: Sugar,
	BACKARROW: BackArrow,
	CHECKBOX: Checkbox,
	CHECK: Check,
	SEARCH: Search,
	CAMERA: Camera,
	TAGMELOGO: TagmeLogo,
	LOCKER: Locker,
	AUTIST: Autist,
	BABYSTROLLER: BabyStroller,
	BABY: Baby,
	CHAIR: Chair,
	CHEF: Chef,
	ELDERLY: Elderly,
	EMPTYPIN: EmptyPin,
	PIN: Pin,
	STAR: Star,
	FILLEDCIRCLE: FilledCircle,
	GLOBE: Globe,
	INDIFFERENT: Indifferent,
	KEY: Key,
	MEGAPHONE: MegaPhone,
	PREGNANT: Pregnant,
	SAD: Sad,
	SMILE: Smile,
	SMILEBIG: SmileBig,
	TAG: Tag,
	VOUCHER: Voucher,
	WALKER: Walker,
	WATCH: Watch,
	CROSS: Cross,
	CHECKRADIO: CheckRadio,
	LOCATION: Location,
	USER: User,
	TIME: Time,
	ARROWTOTOP: ArrowToTop,
	CRM: NavigateCrm,
	OPERATIONAL: NavigateOperational,
	SETTINGS2: NavigateSettings,
	REPORTS: NavigateReports,
	ADMIN: NavigateAdmin
};

export interface IconProps {
	id: string
	height?: string
	width?: string
	mr?: string
	py?: string
	px?: string
	circle?: boolean
	bg?: string
	fill?: string
	strokeWidth?: string
	onClick?: () => void
}

const Icon = ({ id, ...delegated }: IconProps) => {
	const IconComponent = icons[id]

	return (
		<Wrapper {...delegated}>
			<IconComponent />
		</Wrapper>
	)
}

export default Icon

