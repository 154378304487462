export const millisecondsToTime = (miliseconds: number) => {
	if (miliseconds < 0) return '00:00'
	const seconds = Math.floor((miliseconds / 1000) % 60)
	const minutes = Math.floor(miliseconds / (1000 * 60))

	const displayMinutes = minutes < 10 ? '0' + minutes : minutes
	const displaySeconds = seconds < 10 ? '0' + seconds : seconds

	return `${displayMinutes}:${displaySeconds}`
}
