import styled from 'styled-components/macro'
import { UnstyledButton } from '../../../../components/unstyled-button'
import { WaitlistSettingsSelectorProps } from '.'

export const Selector = styled(UnstyledButton)<
	Pick<WaitlistSettingsSelectorProps, 'height' | 'padding' | 'backgroundColor' | 'hasInconsistency' | 'open'>
>`
	display: flex;
	align-items: center;
	${({ hasInconsistency }) =>
		hasInconsistency ? 'border: 1px solid var(--color-danger)' : 'border: 1px solid var(--color-gray-mid)'};
	width: 100%;

	${({ open }) => (open ? 'border-radius: 3px 3px 0px 0px' : 'border-radius: 3px')};
	${({ padding }) => padding && `padding: ${padding};`};
	${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`};
	${({ height }) => height && `height: ${height};`};
`

export const SelectorName = styled.span<
	Pick<WaitlistSettingsSelectorProps, 'fontSize' | 'fontWeight' | 'color'>
>`
	${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
	${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
	${({ color }) => color && `color: ${color}`};
`

export const IconWrapper = styled.span<Pick<WaitlistSettingsSelectorProps, 'rotateArrow'>>`
	margin-left: auto;
	display: flex;
	${({ rotateArrow }) => rotateArrow && `transform: rotate(180deg)`};
`

export const Warning = styled.span`
	color: var(--color-white);
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	margin-left: 10px;
	border-radius: 3px;
	background-color: var(--color-danger);
`
