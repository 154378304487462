import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { WaitlistSettingsContext } from '../../../../contexts/waitlist-settings/waitlist-settings.context'
import { Button } from '../../../../components/button'
import Modal from '../../../../components/modal'
import DataSaved from '../data-saved'
import { FooterWrapper, SpinnerWrapper } from './styled'
import { VenueContext } from '../../../../contexts/venue/venue.context'
import FailModal from '../../../../components/fail-modal'
import Spinner from '../../../../components/spinner'

interface SubmitFooterProps {
	hasRangeInconsistency: boolean
}

const SubmitFooter = ({ hasRangeInconsistency }: SubmitFooterProps) => {
	const { dispatchData } = useContext(WaitlistSettingsContext)
	const { venueId } = useContext(VenueContext)
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [showFailModal, setShowFailModal] = useState(false)
	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()

	const handleSubmit = async () => {
		setLoading(true)
		const submited = await dispatchData()
		setLoading(false)

		if (submited?.status === 200) {
			setShowConfirmationModal(true)
			setTimeout(() => {
				navigate(`/operational/${venueId}`)
			}, 2000)
		} else {
			setShowFailModal(true)
		}
	}

	const handleCloseModal = () => {
		setShowConfirmationModal(false)
		navigate(`/operational/${venueId}`)
	}

	const showButton = () => {
		if (loading) return <SpinnerWrapper><Spinner /></SpinnerWrapper>
		return <Button
			disabled={hasRangeInconsistency}
			width="100%"
			height="100%"
			primary={true}
			border="none"
			onClick={handleSubmit}
		>
			Salvar
		</Button>
	}

	return (
		<>
			<FooterWrapper>
				{showButton()}
			</FooterWrapper>
			{showConfirmationModal && (
				<Modal onClose={handleCloseModal} >
					<DataSaved />
				</Modal>
			)}
			{showFailModal && <FailModal onClose={() => setShowFailModal(false)} />}
		</>
	)
}

export default SubmitFooter


