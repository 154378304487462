import React, { createContext, useState } from 'react'
import { VenueContextType } from './types'

const VenueContext = createContext<VenueContextType>({} as VenueContextType)

const VenueContextComponent: React.FC<React.ReactNode> = ({ children }) => {
	const [venueId, setVenueId] = useState('')

	if (venueId) {
		sessionStorage.setItem('venueId', venueId)
	} else {
		const storedVenueId = sessionStorage.getItem('venueId')
		if (storedVenueId) {
			setVenueId(storedVenueId)
		}
	}

	return <VenueContext.Provider value={{ venueId, setVenueId }}>{children}</VenueContext.Provider>
}

export { VenueContextComponent, VenueContext }
