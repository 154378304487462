import styled from 'styled-components/macro'

export const TagWrapper = styled.div<{ border?: string; color?: string; $filter?: boolean }>`
	display: flex;
	align-items: center;
	gap: 5px;
	height: 20px;
	padding-left: 5px;
	padding-right: 5px;
	color: ${({ color }) => color || 'var(--color-gray-dark)'};
	border: 1px solid ${({ border }) => border || 'var(--color-gray-dark)'};
	/* arbitrarity number */
	border-radius: 999px;
	font-size: ${12 / 16}rem;
	text-align: center;
	white-space: nowrap;

	${({ $filter }) => $filter && 'background-color: var(--color-gold);'};
	${({ onClick }) => onClick && `{cursor: pointer; }`};
`
