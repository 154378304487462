import styled from 'styled-components'

interface InputProps {
	disabled?: boolean
	alert?: boolean
}

export const Input = styled.input<InputProps>`
	border: 1px solid var(--color-gray-mid);
	border-radius: 3px;
	height: 46px;
	${({ disabled }) => disabled && 'background-color: var(--color-gray-mid)'};
	${({ disabled }) => disabled && 'color: var(--color-gray-mid-2)'};
	${({ alert }) => alert && 'border-color: var(--color-danger)'};
	padding: 0 10px;
	font-size: 14px;
`

export const WarningLabel = styled.span`
	width: 100%;
	font-size: 12px;
	margin-top: 5px;
	color: var(--color-danger);
`
