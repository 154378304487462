import { customerApi } from '../../config/axios.config'

export class ImageUploadService {
	static async uploadImage(venueId: string, base64: string): Promise<{ url: string }> {
		const response = await customerApi.post(`/image-upload/${venueId}`, {
			image: base64,
			folder: 'EmailTemplateImage',
		})

		return response?.data
	}
}
