import styled from 'styled-components/macro'

export const TimeRangeWrapper = styled.div`
	display: flex;
	gap: 10px;
	width: 90%;
`

export const TimeRangeLabel = styled.span`
	font-size: ${12 / 16}rem;
`

export const TimeWrapper = styled.span`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 3px;
`
