import styled from 'styled-components'

export const SelectorWrapper = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
`

export const ButtonWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
`

export const Input = styled.input`
	display: flex;
	padding: 0px 10px;
	align-items: center;
	border: 1px solid var(--color-gray-mid);
	border-radius: 3px;
	width: 100%;
	height: 46px;
`

export const AlertsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`

export const ToggleSwitchWrapper = styled.div`
	 {
		margin: 20px auto;
		width: 100%;
		display: flex;
		justify-content: start;
	}
`
