import styled from 'styled-components'

interface SettingsLabelProps {
	disabled?: boolean
}

export const SettingsLabel = styled.span<SettingsLabelProps>`
	padding: 10px 0;
	font-weight: var(--font-weight-bold);
	${({ disabled }) => (disabled ? 'color: var(--color-gray-mid-2)' : 'color: var(--color-black)')};
`
