import styled from 'styled-components/macro'

export const Display = styled.div`
	display: flex;
	gap: 7px;
	margin-top: 30px;
	margin-bottom: 33px;

	& > * {
		width: 100px;
	}
`
export const CenteredAlertWrapper = styled.div`
	position: fixed;
	top: 50%;
`
