import styled from 'styled-components'

interface ColorProps {
	color: string
}

export const BackgroundWrapper = styled.div<ColorProps>`
	${({ color }) => color && `background-color: ${color}`};
	display: flex;
	width: 100%;
`

export const InnerWrapper = styled.div<ColorProps>`
	${({ color }) => color && `background-color: ${color}`};
	margin: 40px auto;
	padding: 40px 0;
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
`

export const Image = styled.img`
	border-radius: 3px;
	object-fit: contain;
	max-height: 50px;
	width: auto;
`

export const Text = styled.span<ColorProps>`
	margin: 20px;
	${({ color }) => color && `color: ${color}`};
	text-align: center;
	font-size: ${14 / 16}rem;
`

export const HorizontalRule = styled.hr<ColorProps>`
	width: 90%;
	margin: 7px;
	${({ color }) => color && `border: 1px solid ${color}`};
`

export const DateText = styled.span<ColorProps>`
	margin-top: 20px;
	${({ color }) => color && `color: ${color}`};
	font-weight: var(--font-weight-bold);
	text-align: center;
	font-size: 14px;
`

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 20px 53px;
`

export const Link = styled.span<ColorProps>`
	font-size: ${14 / 16}rem;
	${({ color }) => color && `color: ${color}`};
	text-decoration: underline;
`

export const FooterText = styled.span`
	font-size: ${12 / 16}rem;
	color: var(--color-gray-dark-2);
`

export const InnerFooterWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 40px auto;
`
