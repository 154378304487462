import { useContext, useRef } from "react";
import { AuthContext } from "../../contexts/auth/auth.context";
import { AuthContextType } from "../../contexts/auth/types";
import { UserRole } from "../../types/user-role.type";
import { isAuthorized } from "../../utils/authorize-role";
import { notAuthorized } from "../../utils/unauthorized";
import { VenueContext } from "../../contexts/venue/venue.context";

export type ProtectedRouteProps = {
	outlet: JSX.Element
	authorizedRole: UserRole
	navigateTo?: string
}

const PrivateRoute = ({ outlet, authorizedRole }: ProtectedRouteProps) => {
	const { venueId } = useContext(VenueContext)
	const { ssoTagmeToken } = useContext(AuthContext) as AuthContextType
	const ref = useRef({ firstLifeCycle: true })

	if (!ssoTagmeToken) {
		if (ref.current.firstLifeCycle) {
			ref.current.firstLifeCycle = false
			return outlet;
		}

		return notAuthorized()
	}

	const { role: userRole } = ssoTagmeToken
	const hasRoleCredentials = isAuthorized({ authorizedRole, userRole })
	const hasVenueCredentials = ssoTagmeToken?.venues.find((venue) => venue._id === venueId)

	if (!hasVenueCredentials || !hasRoleCredentials) {
		return notAuthorized()
	}


	return outlet;
};

export default PrivateRoute;
