import { CancelReason } from './cancel-reason.enum'

export interface Tag {
	id: string
	icon?: string
	url?: string
	fill?: string
	color?: string
	display: string
	value: any
	active: boolean
}

export enum ActionTypeLog {
	REMOVE = 'remove',
	ADD = 'add',
	SEAT = 'seat',
	UPDATE = 'update',
	CALL = 'call',
	CANCEL = 'cancel',
	RESTORE = 'restore',
}

export interface WaitlistLog {
	user?: string
	actionType?: ActionTypeLog
	origin?: string
	created_at: Date
}

export interface Waitlist {
	id: string
	globalPosition: number
	position: number
	fullName: string
	priority: boolean
	phone?: string
	email?: string
	partySize: number
	origin: string
	customerTab: string
	tags: Tag[]
	logs: WaitlistLog[]
	tagsPriorities: Tag[]
	tagsPlaces: Tag[]
	table?: string
	note?: string
	arrivedAt: Date
	notifiedAt?: Date
	canceledAt?: Date
	seatedAt?: Date
	cancelReason?: CancelReason
	history?: boolean
}
