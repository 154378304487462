import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { QUERIES } from '../../../../utils/constants'
import { lessThanSeconds } from '../../../../utils/less-than-seconds'
import { Tooltip } from '../../../../components/tooltip'
import { UnstyledButton } from '../../../../components/unstyled-button'
import { TagWrapper } from '../tag/styled'

const newWaitlistStyles = `
	border-color: var(--color-primary);
	box-shadow: 0 5px 5px -5px var(--color-primary);
`

export const CardWrapper = styled.li<{ notified: boolean; priority: boolean; arrivedAt?: Date }>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 110px;
	border: 1px solid var(--color-gray-mid);
	padding: 9px;
	border-left: 3px solid var(--color-black);
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	background-color: var(--color-white);
	${({ notified }) => notified && `border-left: 3px solid var(--color-success)`};
	${({ priority }) => priority && `background-color: var(--color-gold-light)`};
	${({ arrivedAt }) => lessThanSeconds(arrivedAt, 5) && `${newWaitlistStyles}`};
`
export const TooltipNewWaitlist = styled(Tooltip)`
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-10px, -38px);
	color: var(--color-primary);
	font-weight: var(--font-weight-bold);
	width: 187px;
	text-align: center;
	padding: 6px;
	font-size: 1rem;

	&::after {
		left: 25px;
	}
`

export const InfoWrapper = styled.div`
	display: flex;
	/* justify-content: space-between; */
`

export const PositionAndNameWrapper = styled.div`
	display: flex;

	@media ${QUERIES.tabletAndUp} {
		margin-left: 10px;
	}
`

export const Position = styled.div<{ notified: boolean; priority: boolean }>`
	text-align: center;
	font-size: 1rem;
	width: 30px;
	height: 30px;
	line-height: 30px;
	margin-left: -3px;
	margin-right: 5px;
	border-radius: 50%;
	background-color: var(--color-black);
	color: white;
	font-weight: var(--font-weight-bold);
	flex-shrink: 0;

	${({ notified }) => notified && `background-color: var(--color-success); `};
	${({ priority }) => priority && `background-color: var(--color-primary);`};
`

export const NameWrapper = styled.div`
	/* margin-left: 3px; */
	max-width: min(28vw, 100%);
	width: min(28vw, 173px);
	/* width: 163px; */
	/* @media (min-width: 830px) { */
	/* } */
`

export const Name = styled.div<{ $filter?: boolean }>`
	font-weight: var(--font-weight-bold);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1rem;
	${({ $filter }) => $filter && 'background-color: var(--color-gold);'}
`

export const SeeProfile = styled(Link)`
	color: var(--color-primary);
	font-weight: var(--font-weight-normal);
	font-size: ${12 / 16}rem;
	text-decoration: none;
	/* width: 15ch; */
`

export const PartySizeWrapper = styled.div``

export const PartySize = styled(Name)``

export const Time = styled.time`
	font-size: ${12 / 16}rem;
`

export const IconNotificationWrapper = styled.div`
	position: relative;
`

export const TooltipNote = styled(Tooltip)`
	display: none;
	position: absolute;
	transform: translate(50%, -60%);
	bottom: 0;
	right: 0%;
	width: 170px;
	font-size: ${14 / 16}rem;
	${IconNotificationWrapper}:hover &, ${IconNotificationWrapper}:active & {
		display: revert;
	}
`

export const NoteAndActionsWrapper = styled.div`
	display: flex;
	gap: 18px;
	margin-left: auto;
	padding-left: 20px;
`

export const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 5px;

	${TagWrapper} {
		white-space: nowrap;
	}
`

export const TagStyled = styled.div`
	display: flex;
	align-items: center;
	color: var(--color-gray-dark);
	border: 1px solid var(--color-gray-dark);
	border-radius: 100px;
	font-size: ${12 / 16}rem;
	text-align: center;

	padding: 1px 7px 2px 7px;
`

export const Plus = styled.div`
	flex-shrink: 0;
	border: 1px solid var(--color-primary);
	cursor: pointer;
	color: var(--color-primary);
	text-align: center;
	align-self: flex-end;
	border-radius: 100px;
	width: 34px;
	height: 20px;
`

export const Box = styled.div`
	/* padding: 10px 10px 11px 10px; */
	display: flex;
	align-items: center;
	padding-left: 10px;
	width: 100%;
	min-height: 35px;
	border-radius: 3px;
	font-size: ${12 / 16}rem;
	margin-bottom: 6px;
`

export const Notified = styled(Box)`
	display: flex;
	align-items: start;
	justify-content: space-between;
	padding: 10px;
	background-color: var(--color-green-light);
	border: 1px solid var(--color-green-mid);
	color: var(--color-success);
`
export const NotificationsList = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: start;
	gap: 3px;
`

export const NotificationWaitingTime = styled.span`
	font-weight: var(--font-weight-bold);
`

export const Seated = styled(Box)`
	position: relative;
	background-color: var(--color-blue-light);
	border: 1px solid var(--color-blue-mid);
	color: var(--color-info);
`

export const Canceled = styled(Box)`
	position: relative;
	background-color: var(--color-red-light);
	border: 1px solid var(--color-red-mid);
	color: var(--color-danger);
`

export const ButtonRestore = styled(UnstyledButton)<{ bg: string }>`
	position: absolute;
	top: 3px;
	bottom: 3px;
	right: 3px;
	border-radius: 3px;
	background-color: ${({ bg }) => bg};
	color: var(--color-white);
	width: 109px;
	text-align: center;
	font-size: ${14 / 16}rem;
`

export const ExtendedNoteWrapper = styled.span`
	margin: 0px auto;
	border-radius: 3px;
	background: var(--color-gold-light);
	box-sizing: border-box;
	border: 1px solid var(--color-gold-mid);
	width: 300px;
	color: var(--color-gold-dark);
	padding: 10px;
	line-height: 18px;
	max-height: 90px; /* Adjust the maximum height as needed */
	overflow-y: auto;
	display: block; /* Ensures the component takes up full width */
	text-align: center;
`

export const Edited = styled.div`
	display: flex;
	align-items: start;
	justify-content: space-between;
	padding: 10px;
	margin: 20px 10px 0;
	background-color: var(--color-green-light);
	border: 1px solid var(--color-green-mid);
	color: var(--color-success);
`

export const SpinnerContainer = styled.div`
	height: 150px;
`
