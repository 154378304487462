import styled from 'styled-components'

export const MainText = styled.span`
	font-size: 14px;
	font-weight: var(--font-weight-bold);
	margin-bottom: 10px;
`

interface MarginBottomProps {
	margin: string
}

export const MarginBottom = styled.span<MarginBottomProps>`
	${({ margin }) => margin && `margin-bottom: ${margin}`}
`
