import React, { useContext } from "react";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Title, Dados, Form, Input, Wrapper, BackgroundWrapper, TagmeIcon, Button } from './styled'
import { AuthService } from "../../service/auth";
//import { Button } from "../../components/button";
import { notAuthorized } from "../../utils/unauthorized";
import { AuthContext } from "../../contexts/auth/auth.context";
import { AuthContextType } from "../../contexts/auth/types";
import { VenueContext } from "../../contexts/venue/venue.context";
import backgroundImage from "../../assets/images/bg-Waitlist-1728x117px-Desktop.png";
import { LogoTagme } from '../../assets/icons'


interface LoginFormState {
	username: string;
	password: string;
}

const Login: React.FC = () => {
	const navigate = useNavigate();

	const [formData, setFormData] = useState<LoginFormState>({
		username: '',
		password: '',
	});

	const { setSsoTagmeToken } = useContext(AuthContext) as AuthContextType;
	const { setVenueId } = useContext(VenueContext)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (formData.username && formData.password) {
			const user = await AuthService.login(formData.username, formData.password)
			const venueId = user?.venues[0]?._id;

			if (!venueId) {
				notAuthorized();
			};

			if (user) {
				setSsoTagmeToken(user)
				localStorage.setItem('ssoTagmeToken', JSON.stringify(user));
				setVenueId(venueId)
				navigate(`/operational/${venueId}`);
			} else {
				setFormData({ username: '', password: '' });
			}

		}
	}

	return (
		<BackgroundWrapper style={{ backgroundImage: `url(${backgroundImage})` }}>
			<Wrapper>
				<Title>Login</Title>
				<Dados>Informe seus dados para entrar na sua conta</Dados>
				<Form onSubmit={handleSubmit}>
					<Input type="text" placeholder="Usuário" name="username" value={formData.username} onChange={handleChange} />
					<Input type="password" placeholder="Senha" name="password" value={formData.password} onChange={handleChange} />
					<Button /*width="100%" height="48px" primary*/>
						Entrar
					</Button>
				</Form>
				{/* <Link href="/">Esqueci a senha</Link> */}
				<TagmeIcon><LogoTagme /></TagmeIcon>
			</Wrapper>

		</BackgroundWrapper >
	)
}

export default Login
