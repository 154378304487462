import { useState } from 'react'

type useInputReturn = [
	string,
	boolean,
	boolean,
	(event: React.ChangeEvent<HTMLInputElement>) => void,
	() => void,
	() => void,
	(value: string) => void
]

const useInput = (validateValue: (value: string) => boolean, defaultValue?: string): useInputReturn => {
	const [value, setValue] = useState(defaultValue || '')
	const [isTouched, setIsTouched] = useState(false)

	const valueIsValid = validateValue(value)
	const valueHasError = !valueIsValid && isTouched

	const setValueHandler = (value: string) => {
		setValue(value)
	}

	const valueChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value)
	}

	const valueBlurHandler = () => {
		setIsTouched(true)
	}

	const reset = () => {
		setValue('')
		setIsTouched(false)
	}

	return [value, valueIsValid, valueHasError, valueChangeHandler, valueBlurHandler, reset, setValueHandler]
}

export default useInput
