import { useState } from 'react'
import { ApplyFilter } from '../../../../types/apply-filter.interface'
import { WaitlistSettings } from '../../../../types/waitlist-settings.interface'
import { ModalContentForm, Sizes } from '../modal-content-form'
import useModalContent from '../modal-content-form/use-modal-content.hook'
import SearchCustomer from './searchCustomer'
import { Wrapper, Header, Title, Tab, Tabs, ContentTabWrapper, ButtonWrapper, Button } from './styled'
import { AppliedTags } from '../../../../types/applied-tags.interface'

interface ActiveId {
	waitingPlace: boolean
	priorities: boolean
	name: boolean
	partySizes: boolean
}

const initialStateActiveTab: ActiveId = {
	waitingPlace: true,
	priorities: false,
	name: false,
	partySizes: false,
}

interface FilterProps {
	onApplyFilter: (props: ApplyFilter) => void
	waitlistSettings?: WaitlistSettings
	appliedFilters: AppliedTags
}

const Filter = ({ onApplyFilter, waitlistSettings, appliedFilters }: FilterProps) => {

	const { tags: selectedTags, name: selectedName } = appliedFilters

	const [name, setName] = useState<string>(selectedName?.value || '')
	const [activeTab, setActiveTab] = useState<ActiveId>(initialStateActiveTab)
	const [tagsPlaces, handleTagsPlacesToggle, tagsPlacesActive] =
		useModalContent({ data: waitlistSettings?.tagsPlaces })
	const [tagsPriorities, handleTagPrioritiesToggle, tagsPrioritiesActive] =
		useModalContent({ data: waitlistSettings?.tagsPriorities })
	const [partySizes, handlePartySizesToggle, partySizesActive] = useModalContent({ data: waitlistSettings?.partySizesFilter })

	// Handle Tab Active
	const handleTabActive = (event: any) => {
		const name: keyof ActiveId = event.target?.dataset.name
		const newActiveTab: ActiveId = { ...activeTab }
		Object.keys(activeTab).forEach(key => {
			if (key === name) newActiveTab[name] = true
			else newActiveTab[key as keyof ActiveId] = false
		})
		setActiveTab(newActiveTab)
	}

	// Create WaitingPoint Component based on Modal Content Markup
	const PlacesContentTab = () => (
		<ModalContentForm data={tagsPlaces} selectedData={selectedTags} onClick={handleTagsPlacesToggle} size={Sizes.SMALL} />
	)

	// Create TypeCustomer Component based on Modal Content Markup
	const PrioritiesContentTab = () => (
		<ModalContentForm data={tagsPriorities} onClick={handleTagPrioritiesToggle} size={Sizes.SMALL} />
	)

	// Create PartySizes Component based on Modal Content Markup
	const PartySizesContentTab = () => (
		<ModalContentForm data={partySizes} onClick={handlePartySizesToggle} size={Sizes.SMALL} />
	)

	const handleApplyFilter = (event: any) => {
		event.preventDefault()
		const tagsValues = [...tagsPlacesActive, ...tagsPrioritiesActive]
		const newSettings = {
			...waitlistSettings as WaitlistSettings,
			tagsPlaces,
			tagsPriorities,
			partySizesFilter: partySizes,
		}
		onApplyFilter({ name, tags: tagsValues, partySizes: partySizesActive, newSettings })
	}

	return (
		<Wrapper>
			<Header>
				<Title>Filtrar por</Title>
				<Tabs>
					<Tab active={activeTab.waitingPlace} data-name='waitingPlace' onClick={handleTabActive}>
						Local
					</Tab>
					<Tab active={activeTab.priorities} data-name='priorities' onClick={handleTabActive}>
						Tipo
					</Tab>
					<Tab active={activeTab.name} data-name='name' onClick={handleTabActive}>
						Nome
					</Tab>
					<Tab active={activeTab.partySizes} data-name='partySizes' onClick={handleTabActive}>
						Listas
					</Tab>
				</Tabs>
			</Header>
			<form onSubmit={handleApplyFilter}>
				<ContentTabWrapper>
					{activeTab.waitingPlace && <PlacesContentTab />}
					{activeTab.priorities && <PrioritiesContentTab />}
					{activeTab.name && <SearchCustomer value={name} onChange={event => setName(event.currentTarget.value)} />}
					{activeTab.partySizes && <PartySizesContentTab />}
				</ContentTabWrapper>
				<ButtonWrapper>
					<Button disabled={false} onClick={handleApplyFilter}>
						Aplicar filtro
					</Button>
				</ButtonWrapper>
			</form>
		</Wrapper>
	)
}

export default Filter
