
import { EmailTemplateInterface } from '../../../../contexts/waitlist-settings/types'
import { Button } from '../../../../components/button'
import Icon from '../../../../components/icon'

import {
	Image,
	InnerWrapper,
	BackgroundWrapper,
	Text,
	HorizontalRule,
	DateText,
	ButtonWrapper,
	Link,
	FooterText,
	InnerFooterWrapper,
} from './styled'

interface PreviewTemplateInterface {
	state: EmailTemplateInterface
}

const PreviewTemplate = ({ state }: PreviewTemplateInterface) => {
	return (
		<>
			<BackgroundWrapper color={state.template.backgroundColor}>
				<InnerWrapper color={state.template.mainColor}>
					{state.template.imageUrl && <Image src={state.template.imageUrl} alt="Email template logo preview" />}
					<Text color={state.template.textColor}>{state.template.message}</Text>
					<HorizontalRule color={state.template.backgroundColor} />
					<DateText color={state.template.dateColor}>Domingo, 8 de setembro de 2022</DateText>
					<ButtonWrapper>
						<Button
							height="46px"
							width="189px"
							border="none"
							bg={state.template.buttonColor}
							color={state.template.buttonTextColor}
						>
							Confira seu status aqui
						</Button>
					</ButtonWrapper>
					<Link color={state.template.buttonColor}>Sair da lista de espera</Link>
				</InnerWrapper>
			</BackgroundWrapper>
			<BackgroundWrapper color="var(--color-white)">
				<InnerFooterWrapper>
					<FooterText>Powered by</FooterText>
					<Icon id="TAGMELOGO" />
				</InnerFooterWrapper>
			</BackgroundWrapper>
		</>
	)
}

export default PreviewTemplate
