import { customerApi } from '../../config/axios.config'
import { CreateWaitlistDto, UpdateWaitlistDto, WaitlistFromApi } from './types'

export class WaitlistService {
	static async getWaitlistList(venueId: string): Promise<WaitlistFromApi[]> {
		const response = await customerApi.get(`/waitlist/${venueId}/list`)

		return response?.data
	}

	static async createWaitlist(venueId: string, createWaitlist: CreateWaitlistDto) {
		const response = await customerApi.post(`/waitlist/${venueId}`, createWaitlist)

		return response?.data
	}

	static async getWaitlistHistory(venueId: string): Promise<WaitlistFromApi[]> {
		const response = await customerApi.get(`/waitlist/${venueId}/listHistory`)

		return response?.data
	}

	static async getWaitlistForHostes(waitlistId: string): Promise<WaitlistFromApi> {
		const response = await await customerApi.get(`/waitlist/${waitlistId}/hostess`)

		return response?.data
	}

	static async updateWaitlist(waitlistId: string, updateWaitlist: Partial<UpdateWaitlistDto>) {
		const response = await customerApi.put(`/waitlist/${waitlistId}`, updateWaitlist)

		return response?.data
	}

	static async callWaitlist(waitlistId: string) {
		const response = await customerApi.put(`/waitlist/${waitlistId}/call`)

		return response?.data
	}

	static async seatWaitlist(waitlistId: string) {
		const response = await customerApi.put(`/waitlist/${waitlistId}/seat`)

		return response?.data
	}

	static async cancelWaitlist(waitlistId: string, cancelReason: Pick<UpdateWaitlistDto, 'cancelReason'>) {
		const response = await customerApi.put(`/waitlist/${waitlistId}/cancel`, cancelReason)

		return response?.data
	}

	static async restoreWaitlist(waitlistId: string) {
		const response = await customerApi.put(`/waitlist/${waitlistId}/restore`)

		return response?.data
	}
}
