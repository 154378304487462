import styled from 'styled-components'
import MaxWidthWrapper from '../../../../components/max-width-wrapper'

export const FooterWrapper = styled(MaxWidthWrapper)`
	height: 66px;
	padding: 10px;
	background-color: var(--color-white);
	width: 100%;
	margin-top: auto;
`
export const SpinnerWrapper = styled.div`
	margin-top: 20px;
`
