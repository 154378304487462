import styled from 'styled-components/macro'

export const Button = styled.button<{
	width?: string
	height?: string
	color?: string
	bg?: string
	border?: string
	padding?: string
	disabled?: boolean
	primary?: boolean
	secondary?: boolean
}>`
	display: flex;
	/* text-align: center; */
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	font-size: ${14 / 16}rem;
	border-radius: 3px;
	cursor: pointer;
	${({ padding }) => padding && `padding: ${padding};`}
	${({ width }) => width && `width: ${width};`}
	${({ height }) => height && `height: ${height};`}
	${({ border }) => border && `border: ${border};`}
	${({ color }) => color && `color: ${color};`}
	${({ bg }) => bg && `background-color: ${bg};`}
	${({ disabled }) => disabled && `opacity: 0.5;`}
	${({ primary }) => primary && `color: var(--color-white); background-color: var(--color-primary);`}
	${({ secondary }) =>
		secondary &&
		`color: var(--color-primary); background-color: var(--color-white); border: 2px solid var(--color-primary);`}
`
