import styled from 'styled-components/macro'

export const HeaderInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline; ;
`

export const EmptyWaitlist = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: var(--color-gray-mid-2);
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`
