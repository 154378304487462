import styled from 'styled-components'

interface InputProps {
	disabled?: boolean
}

export const SmallInput = styled.input<InputProps>`
	border: 1px solid var(--color-gray-mid);
	border-radius: 3px;
	height: 46px;
	${({ disabled }) => disabled && 'background-color: var(--color-gray-mid)'};
	${({ disabled }) => disabled && 'color: var(--color-gray-mid-2)'};
	padding: 0 10px;
	font-size: 14px;
`
