import { Button, GiveUpButton, ImageWrapper, Info, Wrapper } from '../styled'
import { OkSuccess } from '../../../../../assets/images'
import { InfoWrapper } from './styled'

interface SeatCustomerProps {
	name: string
	onConfirm: () => void
	onClose: () => void
}

const SeatCustomer = ({ name, onClose, onConfirm }: SeatCustomerProps) => {
	const color = 'var(--color-info)'
	return (
		<Wrapper>
			<ImageWrapper>
				<OkSuccess />
			</ImageWrapper>
			<InfoWrapper>
				<Info>Deseja sentar {name} à mesa?</Info>
			</InfoWrapper>
			<Button color={color} onClick={onConfirm}>
				Confirmar
			</Button>
			<GiveUpButton color={color} onClick={onClose}>
				Desistir
			</GiveUpButton>
		</Wrapper>
	)
}

export default SeatCustomer
