import styled from 'styled-components'
import MaxWidthWrapper from '../../../../components/max-width-wrapper'

export const InnerWrapper = styled(MaxWidthWrapper)`
	padding: 20px 16px;
	display: flex;
	flex-direction: column;
	font-size: ${14 / 16}rem;
	flex: 1;
	height: 100%;
`

export const DisplayIconOrImageWrapper = styled.div`
	display: flex;
	cursor: pointer;
`

export const DisplayIconOrImage = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 3px;
	align-items: center;
	height: 78px;
	width: 78px;
	border: 1px dashed var(--color-gray-mid-2);
	margin-right: 10px;
`

export const MainText = styled.span`
	font-size: ${16 / 16}rem;
	font-weight: var(--font-weight-bold);
	color: var(--color-black);
`

export const SecondaryText = styled.div`
	display: flex;
	align-items: center;
	font-size: ${14 / 16}rem;
	font-weight: var(--font-weight-light);
	color: var(--color-gray-dark-2);
`

export const WarningText = styled.span`
	font-size: ${14 / 16}rem;
	font-weight: var(--font-weight-bold);
	height: ${14 / 16}rem;
	margin-top: 5px;
	color: var(--color-danger);
	cursor: pointer;
`

export const IconOrImageButtonsWrapper = styled.div`
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
`

export const HiddenFileInput = styled.input.attrs({ type: 'file', accept: 'image/*' })`
	position: absolute;
	background-color: red;
	height: 0px;
	width: 0px;
	z-index: 1;
	opacity: 0;
	cursor: pointer;
`

export const ChooseImageText = styled.span`
	font-size: ${14 / 16}rem;
	font-weight: var(--font-weight-bold);
	height: ${14 / 16}rem;
	margin-top: 5px;
	color: var(--color-primary);
	cursor: pointer;
`

interface ImageProps {
	scale: number
	minHeight?: number
	minWidth?: number
}

export const Image = styled.img<ImageProps>`
	${({ scale }) => scale && `transform: scale(${scale})`};
	${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
	${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
`

export const ModalContentWrapper = styled.div`
	gap: 20px;
	padding: 20px 16px;
	display: flex;
	/* align-items: center; */
	flex-direction: column;
`

export const RangeWrapper = styled.div`
	margin-botton: 20px;
`

export const RangeInput = styled.input.attrs({ type: 'range', min: 0, max: 1, step: 0.01 })`
	-webkit-appearance: none;
	width: 80%;
	height: 2px;
	border-radius: 5px;
	background: var(--color-gray-mid-2);
	outline: none;

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: var(--color-primary);
		cursor: pointer;
		outline: 5px solid white;
		box-shadow: 0px 0px 10px black;
	}
`

export const RangeLabelWrapper = styled.div`
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	width: 80%;
`

export const RangeLabel = styled.span`
	font-size: ${10 / 16}rem;
	font-weight: var(--font-weight-light);
`

export const ImageCropWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	width: 100%;
	justify-content: center;
`

export const ButtonsWrapper = styled.div`
	margint-top: 10px;
	gap: 10px;
	display: flex;
`

export const IconsCatalogueWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	gap: 15px;
	max-width: 355px;
`

export const IconWrapper = styled.div<{ selected: boolean }>`
	display: flex;
	padding: 5px;
	height: 40px;
	width: 40px;
	allign-items: center;
	justify-content: center;
	${({ selected }) => selected && `border: 2px solid var(--color-green)`};
	border-radius: 3px;
`

export const FooterWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	cursor: pointer;
`
