import styled from 'styled-components/macro'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: var(--color-white);
	/* margin-top: 48px; */
	border-radius: 3px;
	max-height: 80vh;
	overflow-y: auto;
`

export const ImageWrapper = styled.div`
	height: 180px;
	padding: 20px 28px;
`

export const Info = styled.p`
	text-align: center;
	font-weight: var(--font-weight-bold);
	font-size: ${14 / 16}rem;
	white-space: pre-wrap;
`

export const TextHere = styled.textarea`
	border: 1px solid var(--color-gray-mid);
	min-height: 66px;
	width: 90%;
	margin-top: 16px;
	margin-bottom: 30px;
	font-size: ${12 / 16}rem;
	color: var(--color-black);
`

export const Button = styled.button<{ color: string }>`
	color: ${({ color }) => color};
	border: 2px solid ${({ color }) => color};
	background-color: var(--color-white);
	font-size: ${14 / 16}rem;
	width: min(175px, 90%);
	padding: 6px 0;
	margin: auto;
	display: block;
	margin-bottom: 10px;

	&:hover {
		cursor: pointer;
	}
`
export const GiveUpButton = styled(Button)`
	border: none;
	font-weight: var(--font-weight-bold);
	font-size: ${12 / 16}rem;
`
