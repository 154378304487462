import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { WaitlistSettingsContext } from '../../../../contexts/waitlist-settings/waitlist-settings.context'
import WaitlistSettingsSelector from '../selector'
import {
	Dropdown,
	DropdownItem,
	DropdownWrapper,
	WarningWrapper,
	ExcludePartySize,
	PartySizeLabel,
	PartySizeLableWrapper,
	Warning,
	Wrapper,
	PartySizeWarningLabel,
} from './styled'

import { HorizontalRule } from '../horinzontal-rule/styled'

export interface PartySizeRangeProps {
	range?: number
	advancedOperation?: boolean
	hasExclusion?: boolean
	enabled?: boolean
	index: number
	hasAboveRuleLabel?: boolean
	minPartySizeState?: number
	setMinPartySizeState?: Dispatch<SetStateAction<number>>
	maxPartySizeState?: number
	setMaxPartySizeState?: Dispatch<SetStateAction<number>>
	hasAutomaticRange?: boolean
}

// The same component can be used in the case of advanced operation with multiple queues, or as a simple partySizeRange.
// If used in the adavnced mode it should receive as one of the props an array with the multiple queues and the index of the respective queue.
// If it's not in the advanced mode, it should receive the minPartySizeState and the maxPartySizeState as argument.
const PartySizeRange = ({
	range,
	advancedOperation,
	hasExclusion,
	enabled,
	index,
	hasAboveRuleLabel,
	minPartySizeState,
	setMinPartySizeState,
	maxPartySizeState,
	setMaxPartySizeState,
	hasAutomaticRange,
}: PartySizeRangeProps) => {
	const { queues, setQueues } = useContext(WaitlistSettingsContext)

	const [minPartySize, setMinPartySize] = useState(minPartySizeState || queues[index].min)
	const [maxPartySize, setMaxPartySize] = useState(maxPartySizeState || queues[index].max)

	const [openMinPartySize, setOpenMinPartySize] = useState(false)
	const [openMaxPartySize, setOpenMaxPartySize] = useState(false)

	let hasWarning = false

	if (
		maxPartySize > queues[queues.length - 1].max &&
		enabled
	) {
		hasWarning = true
	}

	if (
		hasAutomaticRange
	) {
		if (setMaxPartySizeState)
			setMaxPartySizeState(queues[queues.length - 1].max)
	}

	const minDropdownRef: any = useRef()
	const maxDropdownRef: any = useRef()

	// It closes the dropdown whenever the user click outside the dropdown's limits
	useEffect(() => {
		const checkIfClickedOutside = (e: Event) => {
			// If the dropdown is open and the clicked target is not within the dropdown, then close the dropdown
			if (openMaxPartySize && maxDropdownRef.current && !maxDropdownRef.current.contains(e.target)) {
				setOpenMaxPartySize(false)
			}

			if (openMinPartySize && minDropdownRef.current && !minDropdownRef.current.contains(e.target)) {
				setOpenMinPartySize(false)
			}
		}

		document.addEventListener('mousedown', checkIfClickedOutside)

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside)
		}
	}, [openMaxPartySize, openMinPartySize])

	const handleOpenMaxPartySizeDropdown = () => {
		// If it's an advaced operation with multiple queues, the only dropdown that is going to be available will be the dropdown for setting the maxPartySize of the last element in the array of queues
		if (advancedOperation) {
			if (enabled && index === queues.length - 1) {
				setOpenMaxPartySize(!openMaxPartySize)
			}
		}

		// If it's not advanced operation, the user might be able to open the maxPartySize dropdown, unless it not enabled or in the hasAutomaticRange configuration
		if (!advancedOperation) {
			if (enabled && !hasAutomaticRange) {
				setOpenMaxPartySize(!openMaxPartySize)
			}
		}
	}

	const handleMinPartySizeClick = (i: number) => {
		setMinPartySize(i)

		if (advancedOperation) {
			const newQueues = [...queues]
			newQueues[index].min = i
			setQueues(newQueues)
		}

		if (setMinPartySizeState !== undefined) {
			setMinPartySizeState(i)
		}

		setOpenMinPartySize(false)
	}

	const handleMaxPartySizeClick = (i: number) => {
		setMaxPartySize(i)

		if (advancedOperation) {
			const newQueues = [...queues]
			newQueues[index].max = i
			setQueues(newQueues)
		}

		if (setMaxPartySizeState !== undefined) {
			setMaxPartySizeState(i)
		}

		setOpenMaxPartySize(false)
	}

	const generateMinPartySizeDropdownItens = () => {
		let initialRange = 1
		if (advancedOperation) {
			initialRange = queues[index - 1] ? queues[index - 1].max + 1 : 1
		}

		const finalRange = maxPartySize

		const dropDownArray = []
		for (let i = initialRange; i <= finalRange; i++) {
			dropDownArray.push(
				<DropdownItem key={i} onClick={() => handleMinPartySizeClick(i)}>
					{i}
				</DropdownItem>
			)
		}

		return dropDownArray
	}

	const generateMaxPartySizeDropdownItens = () => {
		const initialRange = minPartySize || 1
		let finalRange = range || 99

		if (!advancedOperation) {
			finalRange = queues[queues.length - 1].max
		}

		let dropDownArray = []
		for (let i = initialRange; i <= finalRange; i++) {
			dropDownArray.push(
				<DropdownItem key={i} onClick={() => handleMaxPartySizeClick(i)}>
					{i}
				</DropdownItem>
			)
		}

		return dropDownArray
	}

	const deleteQueue = () => {
		if (advancedOperation && index >= queues.length - 1 && queues.length > 1) {
			const newQueues = [...queues]
			newQueues.splice(index, 1)
			setQueues(newQueues)
		}
	}

	const handleIconColor = () => {
		if (!enabled || (advancedOperation && index < queues.length - 1) || hasAutomaticRange) {
			return 'var(--color-gray-mid-2)'
		}

		if (hasWarning) {
			return 'var(--color-danger)'
		}

		return 'var(--color-primary)'
	}

	const handleBackGroundColor = () => {
		if (!enabled || (advancedOperation && index < queues.length - 1) || hasAutomaticRange) {
			return 'var(--color-gray-light-2)'
		}

		return 'var(--color-white)'
	}

	return (
		<>
			{hasAboveRuleLabel && (
				<PartySizeLableWrapper>
					<PartySizeLabel>Mínimo de pessoas</PartySizeLabel>
					<PartySizeLabel>Máximo de pessoas</PartySizeLabel>
				</PartySizeLableWrapper>
			)}
			<HorizontalRule />
			<Wrapper>
				<DropdownWrapper ref={minDropdownRef}>
					<WaitlistSettingsSelector
						height="30px"
						hasArrow={true}
						iconColor={'var(--color-gray-mid-2)'}
						padding="0 7px"
						backgroundColor={'var(--color-gray-light-2)'}
						rotateArrow={openMinPartySize && enabled}
					>
						{openMinPartySize && enabled ? 'Mínimo' : minPartySize}
					</WaitlistSettingsSelector>
					<Dropdown>{openMinPartySize && enabled && generateMinPartySizeDropdownItens()}</Dropdown>
				</DropdownWrapper>
				<DropdownWrapper ref={maxDropdownRef}>
					<WaitlistSettingsSelector
						height="30px"
						hasArrow={true}
						iconColor={handleIconColor()}
						padding="0 7px"
						hasInconsistency={hasWarning}
						color={hasWarning ? 'var(--color-danger)' : 'var(--color-black)'}
						backgroundColor={handleBackGroundColor()}
						rotateArrow={openMaxPartySize}
						handleClick={() => handleOpenMaxPartySizeDropdown()}
					>
						{openMaxPartySize ? 'Máximo' : maxPartySize}
					</WaitlistSettingsSelector>
					<Dropdown>{openMaxPartySize && enabled && generateMaxPartySizeDropdownItens()}</Dropdown>
					{hasWarning && <PartySizeWarningLabel>Número de pessoas acima do configurado. (Máximo {queues[queues.length - 1].max})</PartySizeWarningLabel>}
				</DropdownWrapper>
				{hasExclusion && (
					<ExcludePartySize
						enabled={advancedOperation && index >= queues.length - 1 && index !== 0}
						onClick={() => deleteQueue()}
					>
						Excluir
					</ExcludePartySize>
				)}

				{!hasExclusion && <WarningWrapper>{hasWarning && <Warning>!</Warning>}</WarningWrapper>}
			</Wrapper>
		</>
	)
}

export default PartySizeRange
