import { useNavigate } from 'react-router-dom'
import Icon from '../icon'
import { GoBackWrapper } from './styled'

const GoBack = () => {
	const navigate = useNavigate()
	return (
		<GoBackWrapper onClick={() => navigate(-1)}>
			<Icon id="BACKARROW" width="18px" height="14" />
			Voltar
		</GoBackWrapper>
	)
}

export default GoBack
