import styled from 'styled-components/macro'

export const Wrapper = styled.div`
	background-color: var(--color-white);
	font-weight: var(--font-weight-normal);
	width: 450px;
	height: 440px;
	border-radius: 16px;
	padding: 36px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@media (max-width: 768px) {
		width: 90%;
	}
`
export const Title = styled.h1`
	margin-bottom: 24px;
	font-size: ${32 / 16}rem;
	color: #263238;
	font-weight: var(--font-weight-bold);
`

export const Dados = styled.p`
	margin-bottom: 36px;
	font-size: ${20 / 16}rem;
	color: #263238;
	font-size: 20px;
`
export const Form = styled.form``

export const Input = styled.input`
	width: 378px;
	height: 48px;
	border-radius: 8px;
	border: 1px solid #ddd;
	padding: 13px 25px 13px 16px;
	color: #696970;
	margin-bottom: 24px;
	font-size: ${16 / 16}rem;

	@media (max-width: 768px) {
		width: 100%;
	}
`
export const Button = styled.button`
	width: 378px;
	height: 48px;
	border-radius: 8px;
	background: var(--color-primary);
	border: none;
	color: var(--color-white);
	&:hover {
		cursor: pointer;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`

/*export const Link = styled.a`
	display: block;
	margin-top: 10px;
	color: var(--color-primary);
	font-weight: var(--font-weight-light 300px);
	font-size: ${12 / 16}rem;
	text-decoration: underline;
`*/

export const BackgroundWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`

export const TagmeIcon = styled.div`
	margin-top: 90px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
`
