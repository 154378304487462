import { ChangeEvent } from "react"
import CharacterCounter from "../../character-counter"
import { TextAreaWrapper } from "./styled"
import { TextHere } from "../../text-area/styled"

interface TextAreaWithCounterProps {
    text: string
    placeholder?: string
    onChange: (text: string) => void
    maxLength?: number
}

const TextAreaWithCounter = ({ text, placeholder, onChange, maxLength }: TextAreaWithCounterProps) => {

    const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault()

        onChange(e.target.value.slice(0, maxLength))
    }

    return (
        <TextAreaWrapper>
            <TextHere onChange={(e) => handleTextChange(e)} value={text} placeholder={placeholder} />
            <CharacterCounter state={text ? String(text) : ''} maxLength={maxLength || 20} />
        </TextAreaWrapper>
    )

}

export default TextAreaWithCounter