import CustomerTab from '../../modal-content-buttons/customer-tab'
import Table from '../../modal-content-buttons/table'
import { Button, GiveUpButton, ImageWrapper, Info, Wrapper } from '../styled'
import { Display, CenteredAlertWrapper } from './styled'
import { Send } from '../../../../../assets/images'
import { useEffect, useState } from 'react'
import { NotificationFactory } from '../../../../../contexts/waitlist-settings/factories/notification.factory'
import { AlertWrapper } from '../../../../../components/alert-wrapper'

interface CallCustomerProps {
	name: string
	customerTab: string
	table?: string
	onClose: () => void
	onConfirm: (customerTab: string | undefined, table: string | undefined) => void
	automatic?: boolean
	phone: string
}

const CallCustomer = ({
	name,
	customerTab: customerTabProp,
	table: tableProp,
	onClose,
	onConfirm,
	automatic,
	phone,
}: CallCustomerProps) => {
	const color = 'var(--color-success)';

	const [table, setTable] = useState<string | undefined>(tableProp);
	const [customerTab, setCustomerTab] = useState<string | undefined>(customerTabProp);
	const [showNotification, setShowNotification] = useState(false);

	const callNotification = NotificationFactory.call().message.pt;

	// Effect to hide notification after 2 seconds
	useEffect(() => {
		if (showNotification) {
			const timer = setTimeout(() => {
				setShowNotification(false);
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [showNotification]);

	// Function to generate WhatsApp link with standard message
	const getWhatsappLink = (phone: string, message: string) => {
		const cleanPhone = phone.replace(/[^\d]/g, '');
		const encodedMessage = encodeURIComponent(message);
		return `https://wa.me/${cleanPhone}?text=${encodedMessage}`;
	};

	// Function to handle notifications for WhatsApp customers
	const handleNotifyWhatsApp = () => {
		if (phone) {
			// Open WhatsApp if the phone number is valid
			window.open(getWhatsappLink(phone, callNotification), '_blank');
		} else {
			setShowNotification(true);
		}
	};

	return (
		<Wrapper>
			<ImageWrapper>
				<Send />
			</ImageWrapper>
			<Info>Deseja chamar {name}?</Info>
			<Display>
				<CustomerTab
					automatic={automatic}
					customerTab={customerTab}
					onChangeCustomertab={customerTab => setCustomerTab(customerTab)}
				/>
				<Table table={table} onChangeTable={table => setTable(table)} />
			</Display>
			<Button color={color} onClick={() => onConfirm(customerTab, table)}>
				Confirmar
			</Button>
			<Button color={color} onClick={handleNotifyWhatsApp}>
				Notificar via WhatsApp
			</Button>
			<GiveUpButton color={color} onClick={onClose}>
				Desistir
			</GiveUpButton>
			{showNotification && (
				<CenteredAlertWrapper>
					<AlertWrapper isWarning={true}>Cliente não possui WhatsApp cadastrado.</AlertWrapper>
				</CenteredAlertWrapper>
			)}
		</Wrapper>
	);
};

export default CallCustomer;
