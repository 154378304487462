import Icon from '../../../../components/icon'
import { ExclamationWrapper, IconWrapper, Wrapper } from './styled'

const IconNotification = () => {
	return (
		<Wrapper>
			<IconWrapper>
				<Icon id='EMAIL' width={'21.33px'} />
				<ExclamationWrapper>
					<Icon id='EXCLAMATIONMARKCIRCLE' width='15px' height='15px' />
				</ExclamationWrapper>
			</IconWrapper>
		</Wrapper>
	)
}

export default IconNotification
