import { customerApi } from '../../config/axios.config'
import { Details, Template } from './types'

export class EmailTemplateService {
	static async createTemplate(newTemplate: Template): Promise<Template & { _id: string }> {
		const response = await customerApi.post(`/communication/templates/new-template`, newTemplate)

		return response?.data
	}

	static async updateTemplate(templateId: string, template: Details): Promise<Template & { _id: string }> {
		return await customerApi.put(`/communication/templates/${templateId}`, template)
	}
}
