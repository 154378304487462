import { PixelCrop } from 'react-image-crop'
import { canvasPreview } from './canvas-preview'

export function toBlob(canvas: HTMLCanvasElement) {
	return new Promise(resolve => {
		canvas.toBlob(resolve)
	})
}

export async function generateBlob(image: HTMLImageElement, crop: PixelCrop, scale = 1, rotate = 0) {
	const canvas = document.getElementsByTagName('canvas')[0]
	canvasPreview(image, canvas, crop, scale, rotate)

	const blob = (await toBlob(canvas)) as any

	return blob
}
