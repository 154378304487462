import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Waitlist } from '../../types/waitlist.interface'
import { WaitlistSettings } from '../../types/waitlist-settings.interface'
import { waitlistAdapter } from '../../adapters/waitlist.adapter'
import { waitlistSettingsAdapter } from '../../adapters/waitlist-settings.adapter'
import EditProfile from './components/edit-profile'
import GoBack from '../../components/go-back'
import ConfirmMessage from '../hostess-waitlist/components/modal-content-actions/confirm-message'
import Modal from '../../components/modal'
import Profile from './components/profile'
import { HeaderWrapper, Wrapper, EditProfileLink } from './styled'
import { WaitlistSettingsService } from '../../service/waitlist-settings'
import { WaitlistService } from '../../service/waitlist'
import { VenueContext } from '../../contexts/venue/venue.context'
import { UserRole } from '../../types/user-role.type'
import { isAuthorized } from '../../utils/authorize-role'
import { AuthContext } from '../../contexts/auth/auth.context'
import FailModal from '../../components/fail-modal'
import Spinner from '../../components/spinner'

const ProfilePage: React.FC = () => {
	const { id } = useParams()
	const { venueId } = useContext(VenueContext)
	const [waitlist, setWaitlist] = useState<Waitlist | undefined>()
	const [waitlistSettings, setWaitlistSettings] = useState<WaitlistSettings | undefined>()
	const [showEditProfile, setShowEditProfile] = useState(false)
	const [showConfirmMessage, setShowConfirmMessage] = useState(false)
	const [showFailModal, setShowFailModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [loadingUpdate, setLoadingUpdate] = useState(false)
	const authContext = useContext(AuthContext)
	const userRole = authContext?.ssoTagmeToken?.role || UserRole.VIEWER

	const fetchWaitlistSettingsData = useCallback(async () => {
		try {
			setLoading(true)
			const results = await WaitlistSettingsService.getWaitlistSettingsForHostess(venueId as string)

			setWaitlistSettings(waitlistSettingsAdapter(results))
			setLoading(false)
		} catch (error) {
			setShowFailModal(true)
		}
	}, [venueId])

	const fetchWaitlistId = useCallback(async () => {
		try {
			setLoading(true)
			const result = await WaitlistService.getWaitlistForHostes(id as string)
			setWaitlist(waitlistAdapter(result))
			setLoading(false)
		} catch (error) {
			setShowFailModal(true)
		}
	}, [id])

	const updateWaitlistId = useCallback(
		async updateCustomer => {
			try {
				setLoadingUpdate(true)
				await await WaitlistService.updateWaitlist(id as string, updateCustomer)
				setShowEditProfile(false)
				setShowConfirmMessage(true)
				await fetchWaitlistId()
				setLoadingUpdate(false)
			} catch (error) {
				setShowFailModal(true)
			}
		},
		[id, fetchWaitlistId]
	)

	useEffect(() => {
		fetchWaitlistId()
		fetchWaitlistSettingsData()
	}, [fetchWaitlistId, fetchWaitlistSettingsData])

	return (
		<Wrapper>
			<HeaderWrapper>
				<GoBack />
				{isAuthorized({ authorizedRole: UserRole.OPERATOR, userRole }) && (
					<EditProfileLink onClick={() => setShowEditProfile(true)}>Editar Perfil</EditProfileLink>
				)}
				{showEditProfile && (
					<Modal onClose={() => setShowEditProfile(false)}>
						<EditProfile
							waitlist={waitlist}
							onUpdateCustomer={updateWaitlistId}
							waitlistSettings={waitlistSettings}
						/>
						{loadingUpdate && <Spinner />}
					</Modal>
				)}
				{showConfirmMessage && (
					<Modal onClose={() => setShowConfirmMessage(false)}>
						<ConfirmMessage message="Dados atualizados com sucesso!" />
					</Modal>
				)}
			</HeaderWrapper>
			{loading && <Spinner />}
			<Profile waitlist={waitlist} />
			{showFailModal && <FailModal onClose={() => setShowFailModal(false)} />}
		</Wrapper>
	)
}
export default ProfilePage
