import { customerApi } from '../../config/axios.config'
import { WaitlistStatus } from './types'

export class StatusService {
	static async getWaitlistStatus(venueId: string): Promise<WaitlistStatus> {
		const response = await customerApi.get(`/status/${venueId}/hostess`)

		return response?.data
	}

	static async updateWaitlistStatus(venueId: string, active: boolean): Promise<WaitlistStatus> {
		const response = await customerApi.put(`/status/${venueId}/hostess`, { active })

		return response?.data
	}
}
