import axios from 'axios'
import { notAuthorized } from '../utils/unauthorized'

const customerApi = axios.create({
	baseURL: process.env.REACT_APP_CUSTOMER_BASE_URL,
})

// Automatically adds the token to the request header
customerApi.interceptors.request.use(config => {
	const storedUser = localStorage.getItem('ssoTagmeToken')

	const parsedStoredUser = storedUser && JSON.parse(storedUser)

	if (parsedStoredUser?.accessToken) {
		config.headers = {
			Authorization: `Bearer ${parsedStoredUser.accessToken}`,
		}
	}

	return config
})

// Automatically redirects to the login page if the user is not authorized
customerApi.interceptors.response.use(
	response => {
		return response
	},
	error => {
		if (error.response?.status === 401) {
			notAuthorized()
		}
		return error
	}
)

export { customerApi }
