import styled from 'styled-components/macro'
import { Sizes } from '.'
import { UnstyledButton } from '../../../../components/unstyled-button'

const chooseSize = {
	small: `
		gap: 8px;
		height: 46px;
		padding-left: 11px;
		padding-right: 10px;
	`,
	medium: `
		gap: 19px;
		height: 58px;
		padding-left: 19px;
		padding-right: 10px;
	`,
}

export const Wrapper = styled.div`
	font-size: ${14 / 16}rem;
	font-weight: var(--font-weight-bold);
`

export const NameWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

export const DisclaimerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: ${14 / 16}rem;
	margin-top: 36px;
	gap: 6px;
	font-weight: var(--font-weight-bold);
`

export const FillManually = styled.span`
	color: var(--color-primary);

	&:hover {
		cursor: pointer;
	}
`

export const Info = styled.p`
	margin-bottom: 10px;
`
export const Item = styled(UnstyledButton)<{ active?: boolean; size: Sizes }>`
	display: flex;
	align-items: center;
	${({ active }) =>
		active
			? 'border: 2px solid var(--color-primary); color: var(--color-primary)'
			: 'border: 1px solid var(--color-gray-mid)'};
	width: 100%;
	border-radius: 3px;

	&:not(last-of-type) {
		margin-bottom: 10px;
	}
	${({ size }) => chooseSize[size]}
`

export const Name = styled.span`
	font-weight: var(--font-weight-bold);
`

export const Email = styled.span`
	font-size: ${12 / 16}rem;
	font-weight: var(--font-weight-light);
	height: ${14 / 16}rem;
	margin-top: 3px;
`

export const Select = styled.span`
	margin-left: auto;
	color: var(--color-primary);
`

export const IconWrapper = styled.div<{ active?: boolean }>`
	margin-left: auto;
	color: ${({ active }) => (active ? 'var(--color-primary);' : 'var(--color-gray-mid);')};
`
