import styled from 'styled-components'

export const FlexWrap = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 9px;
	column-gap: 13px;
	row-gap: 26px;
	flex-wrap: wrap;
	margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	padding: 0px 7px;
	font-weight: 600;
`
