import { useEffect, useState } from 'react'
import Switch, { Size } from '../../../../components/toggle-switch'
import { SwitchWaitlistWrapper } from './styled'
import { StatusService } from '../../../../service/status'
import { connectWebsocket } from '../../../../adapters/websocket.service'
import { WAITLIST_EVENT_TYPE } from '../../../../utils/constants'

interface SwitchWaitlistProps {
	venueId: string
}

const SwitchWaitlist = ({ venueId }: SwitchWaitlistProps) => {
	const [state, setState] = useState<boolean>(true)

	useEffect(() => {
		const getStatus = async () => {
			const response = await StatusService.getWaitlistStatus(venueId)
			setState(response.active)
		}
		getStatus()
	}, [venueId])


	useEffect(() => {
		const options = {
			query: {
				venueId,
			},
			path: '/waitlistSocket/',
			transports: ['websocket'],
		}

		// Connecting the websocket through the customersApi
		const url = `${process.env.REACT_APP_CUSTOMER_BASE_URL}/waitlist`
		const socket = connectWebsocket(url, options)

		socket.on(WAITLIST_EVENT_TYPE.UPDATE_STATUS_HOSTESS, (content: any) => {
			const { active,
			} = content

			setState(active)
		})

		return () => {
			socket.close()
		}
	}, [venueId])



	const handleClick = async () => {
		try {
			await StatusService.updateWaitlistStatus(venueId, !state)
			const response = await StatusService.getWaitlistStatus(venueId)
			setState(response.active)
		} catch (error) {
			//TODO: Add sentryError
			console.error(error)
		}
	}

	return (
		<>
			<SwitchWaitlistWrapper state={state} onClick={handleClick}>
				{state ? 'Lista aberta' : 'Lista fechada'}
				<Switch state={state} size={Size.SMALL} />
			</SwitchWaitlistWrapper>
		</>
	)
}

export default SwitchWaitlist
