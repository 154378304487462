import { UnstyledButton } from '../../../../../components/unstyled-button'
import { Box, BoxWrapper } from '../styled'

interface SaveButtonProps {
	onSave: () => void
	disabled: boolean
}

const SaveButton = ({ onSave, disabled }: SaveButtonProps) => (
	<BoxWrapper>
		<UnstyledButton disabled={disabled} onClick={onSave}>
			<Box center primary disabled={disabled}>
				Salvar
			</Box>
		</UnstyledButton>
	</BoxWrapper>
)

export default SaveButton
