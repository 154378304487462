import { Method } from './types'

export const initialAlert = {
	enabled: false,
	message: {
		pt: '',
		en: '',
	},
}

export const initialQueuesState = [
	{ min: 1, max: 2 },
	{ min: 3, max: 4 },
	{ min: 5, max: 8 },
	{ min: 9, max: 12 },
]

export const initialTagsState = {
	enabled: false,
	tags: [
		{
			icon: 'PIN',
			label: 'Entrada',
			color: '#000',
			active: true,
		},
	],
}

export const initialWaitingSpotsTags = {
	enabled: false,
	tags: [
		{
			icon: 'PIN',
			label: 'Entrada',
			color: '#000',
			active: true,
		},
	],
}

export const initialSchedule = {
	method: Method.BASIC,
	weeklySchedules: {
		monday: { active: true, timeRange: { openingTime: '09:00', closingTime: '18:00' } },
		tuesday: { active: true, timeRange: { openingTime: '09:00', closingTime: '18:00' } },
		wednesday: { active: true, timeRange: { openingTime: '09:00', closingTime: '18:00' } },
		thursday: { active: true, timeRange: { openingTime: '09:00', closingTime: '18:00' } },
		friday: { active: true, timeRange: { openingTime: '09:00', closingTime: '18:00' } },
		saturday: { active: true, timeRange: { openingTime: '09:00', closingTime: '18:00' } },
		sunday: { active: true, timeRange: { openingTime: '09:00', closingTime: '18:00' } },
	},
}
