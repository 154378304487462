import { useState } from 'react'
import { CopyImage, Waiter } from '../../../../../assets/images'
import { Button, GiveUpButton, ImageWrapper, Info, Wrapper } from '../styled'
import TextAreaWithCounter from '../../../../../components/form/text-area-with-counter'

interface SetCustomerTabProps {
	id: 'note' | 'customerTab'
	value?: string | number
	onClose: () => void
	onConfirm: (value: any) => void
}

const chooseImage = {
	note: () => <CopyImage />,
	customerTab: () => <Waiter />,
}

const SetNoteOrCustomerTab = ({ id, onClose, onConfirm, value }: SetCustomerTabProps) => {
	const [text, setText] = useState<string>('')
	const color = 'var(--color-primary)'
	const info = id === 'note' ? 'Observações deste cliente' : 'Número de comanda'

	const Image = chooseImage[id]

	const handleTextChange = (text: string) => {
		setText(text)
	}

	return (
		<Wrapper>
			<ImageWrapper>
				<Image />
			</ImageWrapper>
			<Info>{info}</Info>
			<TextAreaWithCounter placeholder="Digite aqui..." onChange={handleTextChange} text={text} maxLength={200}></TextAreaWithCounter>
			<Button
				color={color}
				onClick={() => {
					onConfirm(text)
				}}
			>
				Adicionar
			</Button>
			<GiveUpButton color={color} onClick={onClose}>
				Desistir
			</GiveUpButton>
		</Wrapper>
	)
}

export default SetNoteOrCustomerTab
