import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 200px;
	gap: 30px;
`

export const MessageText = styled.span`
	font-size: ${14 / 16}rem;
`
