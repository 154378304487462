import React from 'react'
import ReactDOM from 'react-dom'
import Router from './router'
import { GlobalStyles } from './styles/global'
import { VenueContextComponent } from './contexts/venue/venue.context'

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyles />
		<VenueContextComponent>
			<Router />
		</VenueContextComponent>
	</React.StrictMode>,
	document.getElementById('root')
)
