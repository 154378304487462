import styled from 'styled-components/macro'
import { QUERIES } from '../../../../../utils/constants'

const marginBottom = '9px'
const marginRight = '9px'

export const FormWrapper = styled.form`
	display: grid;
	padding-bottom: 16px;
	grid-auto-columns: 1fr;
	grid-template-areas:
		'phone name'
		'partySize email'
		'places priority'
		'placesList priorityList'
		'note submit';

	@media (min-width: 900px) {
		grid-template-areas:
			'phone name email partySize'
			'priority places note submit'
			'priorityList placesList _ _';
	}
`

export const PhoneWrapper = styled.div`
	grid-area: phone;
	margin-bottom: ${marginBottom};
	margin-right: ${marginRight};
`

export const PartySizeWrapper = styled.div`
	grid-area: partySize;
	margin-bottom: ${marginBottom};
	margin-right: ${marginRight};

	@media (min-width: 900px) {
		margin-bottom: revert;
		margin-right: revert;
	}
`

export const PlacesWrapper = styled.div`
	display: flex;
	gap: 9px;
	grid-area: places;
	margin-bottom: ${marginBottom};
	margin-right: ${marginRight};
`

export const NoteWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 9px;
	grid-area: note;
	margin-right: ${marginRight};
`

export const Note = styled.div`
	flex: 1;
`

export const NameWrapper = styled.div`
	grid-area: name;
	@media ${QUERIES.tabletAndUp} {
		margin-right: ${marginRight};
	}
`

export const EmailWrapper = styled.div`
	grid-area: email;
	@media ${QUERIES.tabletAndUp} {
		margin-right: ${marginRight};
	}
`

export const PriorityWrapper = styled.div`
	grid-area: priority;

	@media ${QUERIES.tabletAndUp} {
		margin-right: ${marginRight};
	}
`

export const WrapperModalContent = styled.div`
	padding: 20px 16px;
`

export const WaitingPlacesList = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
	grid-area: placesList;
	margin-bottom: ${marginBottom};
	margin-right: ${marginRight};
`

export const AccomodationPlacesList = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
	grid-area: placesList;
	margin-bottom: ${marginBottom};
	margin-right: ${marginRight};
`

export const AccomodationPlaceList = styled.div``

export const TypeCustomerList = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
	grid-area: priorityList;
	margin-bottom: ${marginBottom};
`
export const SubmitButton = styled.div`
	height: 31px;
	grid-area: submit;
`

export const HeaderWrapper = styled.div`
	display: flex;
	gap: 40px;
	margin: 20px 0;
`

export const Title = styled.h1`
	font-size: 1.5rem;
	margin-right: 24px;
	width: 100%;
	text-align: center;
`
