import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { Button } from '../button'
import Icon from '../icon'
import Modal from '../modal'
import {
	ChooseImageText,
	DragAndDropImage,
	DragAndDropImageWrapper,
	HiddenFileInput,
	SecondaryText,
	Image,
	ModalContentWrapper,
	MainText,
	RangeInput,
	RangeWrapper,
	RangeLabelWrapper,
	RangeLabel,
	ImageCropWrapper,
	ButtonsWrapper,
} from './styled'
import { canvasPreview } from './utils/canvas-preview'
import { blobToBase64 } from './utils/generate-base64'
import { generateBlob } from './utils/generate-blob'
import { useDebounceEffect } from './utils/use-debounce'

export enum CropType {
	LOCKED_SQUARE = 'LOCKED_SQUARE',
	FREE_CROP = 'FREE_CROP',
}

interface CropImageProps {
	setBase64: Dispatch<SetStateAction<string>>
	cropType?: CropType
}

const CropImage = ({ setBase64, cropType = CropType.LOCKED_SQUARE }: CropImageProps) => {
	const [imgSrc, setImgSrc] = useState('')
	const [showCropModal, setShowCropModal] = useState(false)
	const [crop, setCrop] = useState<Crop>()
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
	const [scale, setScale] = useState(1)

	const previewCanvasRef = useRef<HTMLCanvasElement>(null)
	const [aspect, setAspect] = useState<number | undefined>(1)
	// const aspect = undefined
	const imgRef = useRef<HTMLImageElement>(null)

	const dimension = 300

	// Create a preview as a side effect, whenever selected file is changed
	useEffect(() => {
		if (imgSrc) {
			setShowCropModal(true)
		}
	}, [imgSrc])

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale)
			}
		},
		100,
		[completedCrop, scale]
	)

	const onImageLoadLockedSquare = (e: React.SyntheticEvent<HTMLImageElement>) => {
		const { width, height } = e.currentTarget

		setCrop({
			unit: 'px',
			x: width / 2 - dimension / 2,
			y: height / 2 - dimension / 2,
			width: dimension,
			height: dimension,
		})
	}

	const onImageLoadFreeCrop = (e: React.SyntheticEvent<HTMLImageElement>) => {
		if (aspect) {
			const { width, height } = e.currentTarget
			setCrop(centerAspectCrop(width, height, aspect))
		}

		setAspect(undefined)
	}

	const handleCloseModal = async () => {
		if (imgRef?.current && completedCrop) {
			const blob = await generateBlob(imgRef.current, completedCrop, scale)
			const convertedBlob = (await blobToBase64(blob)) as string
			setBase64(convertedBlob)
		}

		setShowCropModal(false)
	}

	const handleCancelCrop = () => {
		setCompletedCrop(undefined)
		handleCloseModal()
	}

	const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined) // Makes crop preview update between images.
			const reader = new FileReader()
			reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
			reader.readAsDataURL(e.target.files[0])
		}
	}

	return (
		<>
			<DragAndDropImageWrapper>
				<HiddenFileInput name="image" onChange={handleSelectFile} />
				<DragAndDropImage>
					{completedCrop ? (
						<canvas
							ref={previewCanvasRef}
							style={{
								border: '1px solid black',
								objectFit: 'contain',
							}}
						/>
					) : (
						<Icon id="CAMERA" fill={'var(--color-black)'}></Icon>
					)}
				</DragAndDropImage>
				<SecondaryText>
					Arraste e solte <br /> a foto aqui
				</SecondaryText>
			</DragAndDropImageWrapper>
			<ChooseImageText>Escolher foto</ChooseImageText>
			{showCropModal && (
				<Modal onClose={handleCancelCrop} >
					<ModalContentWrapper>
						<MainText>Recorte a foto</MainText>
						{cropType === CropType.LOCKED_SQUARE ? (
							<SecondaryText>Posicione o ângulo da imagem no quadrado central</SecondaryText>
						) : (
							<SecondaryText>Recorte a foto na dimensão desejada</SecondaryText>
						)}

						<RangeWrapper>
							<RangeInput value={scale} onChange={e => setScale(parseFloat(e.target.value))} />
							<RangeLabelWrapper>
								<RangeLabel>(-) Diminuir foto</RangeLabel>
								<RangeLabel>Aumentar foto (+)</RangeLabel>
							</RangeLabelWrapper>
						</RangeWrapper>
						<ImageCropWrapper>
							{cropType === CropType.LOCKED_SQUARE ? (
								<ReactCrop
									crop={crop}
									onChange={(crop, _) => {
										setCrop({
											unit: 'px',
											x: crop.x,
											y: crop.y,
											width: dimension,
											height: dimension,
										})
									}}
									maxWidth={dimension}
									maxHeight={dimension}
									locked={true}
									onComplete={c => setCompletedCrop(c)}
									aspect={1}
								>
									<Image
										ref={imgRef}
										alt="Crop me"
										src={imgSrc}
										scale={scale}
										minHeight={dimension}
										minWidth={dimension}
										onLoad={onImageLoadLockedSquare}
									/>
								</ReactCrop>
							) : (
								<ReactCrop
									crop={crop}
									onChange={(_, percentCrop) => {
										setCrop(percentCrop)
									}}
									aspect={aspect}
									onComplete={c => setCompletedCrop(c)}
								>
									<Image ref={imgRef} alt="Crop me" src={imgSrc} scale={scale} onLoad={onImageLoadFreeCrop} />
								</ReactCrop>
							)}
						</ImageCropWrapper>
						<ButtonsWrapper>
							<Button
								width="100%"
								height="30px"
								onClick={handleCancelCrop}
								bg="var(--color-white)"
								color="var(--color-primary)"
								border="2px solid var(--color-primary)"
							>
								Cancelar
							</Button>
							<Button
								width="100%"
								height="30px"
								onClick={handleCloseModal}
								color="var(--color-white)"
								bg="var(--color-primary)"
								border="none"
							>
								Salvar
							</Button>
						</ButtonsWrapper>
					</ModalContentWrapper>
				</Modal>
			)}
		</>
	)
}

export default CropImage

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	)
}
