import { Waitlist } from '../../../../../types/waitlist.interface'
import { WaitlistSettings } from '../../../../../types/waitlist-settings.interface'
import ListWaitlist from '../../list-waitlist'
import SwitchWaitlist from '../../switch-waitlist'
import Info from '../info'
import { BackgroundWrapper, InnerWrapper } from '../styled'
import { HeaderInfo, EmptyWaitlist } from './styled'
import { useContext } from 'react'
import { AuthContext } from '../../../../../contexts/auth/auth.context'
import { isAuthorized } from '../../../../../utils/authorize-role'
import { UserRole } from '../../../../../types/user-role.type'
import { WithoutUser } from '../../../../../assets/icons'

interface DisplayWaitlistProps {
	waitlistList: Waitlist[]
	waitlistSettings: WaitlistSettings | undefined
	venueId: string
}

const DisplayWaitlist = ({
	waitlistList,
	waitlistSettings,
	venueId,
}: DisplayWaitlistProps) => {
	const authContext = useContext(AuthContext)
	const userRole = authContext?.ssoTagmeToken?.role || UserRole.VIEWER

	const isDataLoaded = waitlistList !== undefined && waitlistSettings !== undefined;

	return (
		<BackgroundWrapper>
			<InnerWrapper>
				<HeaderInfo>
					{waitlistList?.length > 0 && <Info waitlistList={waitlistList} />}
					{isAuthorized({ authorizedRole: UserRole.OPERATOR, userRole }) && (
						<SwitchWaitlist venueId={venueId} />
					)}
				</HeaderInfo>
				{waitlistList?.length > 0 && (
					<ListWaitlist
						waitlistList={waitlistList}
						waitlistSettings={waitlistSettings}
						venueId={venueId}
					/>
				)}
			</InnerWrapper>
			{isDataLoaded && waitlistList?.length === 0 && (
				<EmptyWaitlist>
					<WithoutUser />
					Nenhum cliente na lista de espera
				</EmptyWaitlist>
			)}
		</BackgroundWrapper>
	)
}

export default DisplayWaitlist
