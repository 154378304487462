import styled from 'styled-components'

interface ColorPreviewProps {
	color: string
}

export const ColorPreviewWrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
`

export const HiddenColorInput = styled.input.attrs({ type: 'color' })`
	position: absolute;
	width: 120px;
	opacity: 0;

	&:hover {
		cursor: pointer;
	}
`

export const ColorPreview = styled.div<ColorPreviewProps>`
	width: 18px;
	height: 18px;
	border: 1px solid var(--color-gray-mid);
	border-radius: 3px;
	${({ color }) => `background-color: ${color}`};
`

export const ColorLabel = styled.span`
	font-size: 14px;
	margin-left: 8px;
`
