import styled from 'styled-components'

export const GoBackWrapper = styled.div`
	margin: 26px 26px;
	display: flex;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
`

export const GoBackText = styled.span`
    color: var(--color-black);
    font-weight: var(--font-weight-bold);
    font-size: {20/16}rem;
    margin-left: 15px;
`
