import React, { useState, createContext, useEffect, ReactNode } from "react";
import { AuthContextType, User } from "./types";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthContextComponentProps {
  children: ReactNode;
}

function AuthContextComponent(props: AuthContextComponentProps): JSX.Element {
  const [ssoTagmeToken, setSsoTagmeToken] = useState<User | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("ssoTagmeToken");
    const parsedStoredUser = storedUser && JSON.parse(storedUser) as User;


    if (parsedStoredUser && parsedStoredUser.userId) {
      setSsoTagmeToken({ ...parsedStoredUser });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ssoTagmeToken, setSsoTagmeToken }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContextComponent, AuthContext };
