import styled from 'styled-components'
import MaxWidthWrapper from '../../../../components/max-width-wrapper'

interface BackgroundWrapperProps {
	hasInconsistency?: boolean
}

export const BackgroundWrapper = styled.div<BackgroundWrapperProps>`
	background-color: var(--color-white);
	${({ hasInconsistency }) => hasInconsistency && 'border: 1px solid var(--color-danger)'};
	${({ hasInconsistency }) => hasInconsistency && 'border-top: none'};
	border-radius: 0px 0px 3px 3px;
`

export const InnerWrapper = styled(MaxWidthWrapper)`
	padding: 10px 20px 20px;
	display: flex;
	flex-direction: column;
	font-size: ${14 / 16}rem;
	flex: 1;
	height: 100%;
`
