import { Dispatch, SetStateAction } from 'react'
import { Button } from '../button'
import { SelectedLanguageEnum } from '../../pages/waitlist-settings/components/section-iterface-alerts'
import { ButtonsWrapper } from './styled'

interface LanguageToggleProps {
	language: string
	setLanguage: Dispatch<SetStateAction<SelectedLanguageEnum>>
}

const LanguageToggle = ({ language, setLanguage }: LanguageToggleProps) => {
	return (
		<ButtonsWrapper>
			<Button
				width="40px"
				height="22px"
				border={language === 'pt' ? '2px solid var(--color-primary)' : '2px solid var(--color-gray-mid-2)'}
				color={language === 'pt' ? 'var(--color-primary)' : 'var(--color-gray-mid-2)'}
				bg="white"
				padding="0px"
				name="pt"
				onClick={(e: any) => setLanguage(e.target.name)}
			>
				POR
			</Button>
			<Button
				width="40px"
				height="22px"
				border={language === 'en' ? '2px solid var(--color-primary)' : '2px solid var(--color-gray-mid-2)'}
				color={language === 'en' ? 'var(--color-primary)' : 'var(--color-gray-mid-2)'}
				bg="white"
				padding="0px"
				name="en"
				onClick={(e: any) => setLanguage(e.target.name)}
			>
				ING
			</Button>
		</ButtonsWrapper>
	)
}

export default LanguageToggle
