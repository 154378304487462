import { DataModalContent } from '../../../../types/data-modal-content-form.interface'
import { SuggestedCustomer } from '../../../../types/suggested-customer.interface'
import Icon from '../../../../components/icon'
import { IconWrapper, Info, Item, Name, Select, Wrapper, NameWrapper, DisclaimerWrapper, Email, FillManually } from './styled'

export enum Sizes {
	SMALL = 'small',
	MEDIUM = 'medium',
}

interface ModalContentFormProps {
	info?: string
	data: DataModalContent[]
	size: Sizes
	selectedData?: DataModalContent[]
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const SELECT = {
	medium: (active: boolean) => (!active ? <Select> Selecionar</Select> : <Select> Excluir</Select>),
	small: (active: boolean) => (
		<IconWrapper active={active}>
			<Icon id="CHECKBOX" width="1em" />
		</IconWrapper>
	),
}

export const ModalContentForm = ({ info, data, selectedData, onClick, size }: ModalContentFormProps) => {
	const width = '32px'
	return (
		<Wrapper>
			{info && <Info>{info}</Info>}
			{data?.map(({ id, icon, url, color, display, fill, active, value }) => {
				return (
					<Item key={id} onClick={onClick} data-id={id} data-value={value} active={active} size={size}>
						{icon && <Icon id={icon} width={width} fill={fill || color} />}
						{url && <img width={width} src={url} alt="Imagem que representa um ícone" />}
						<NameWrapper>
							<Name>{display}</Name>
						</NameWrapper>
						{SELECT[size](active)}
					</Item>
				)
			})}
		</Wrapper>
	)
}

interface SuggestedCustomersModalContentFormProps extends Omit<ModalContentFormProps, 'data' | 'onClick' | 'size'> {
	data: SuggestedCustomer[]
	handleSelectCustomer: (suggestedCustomer: SuggestedCustomer) => void,
	handleManuallyFill: (value: boolean) => void
}

export const SuggestedCustomersModalContentForm = ({ info, data, handleSelectCustomer, handleManuallyFill }: SuggestedCustomersModalContentFormProps) => {
	const width = '16px'
	return (
		<Wrapper>
			{info && <Info>{info}</Info>}
			{data?.map((suggestedCustomer, index) => {
				const { name, lastName, email } = suggestedCustomer
				const fullName = `${name} ${lastName}`
				return (
					<Item key={index} onClick={() => handleSelectCustomer(suggestedCustomer)} size={Sizes.MEDIUM} >
						<Icon id='MAN' width={width} />

						<NameWrapper>
							<Name>{fullName}</Name>
							{email && <Email>{email}</Email>}
						</NameWrapper>
						{SELECT.medium(false)}
					</Item>
				)
			})}
			<DisclaimerWrapper>
				<Name>Não encontrou o cliente correto?</Name>
				<FillManually onClick={() => handleManuallyFill(false)}>Adicione aqui</FillManually>
			</DisclaimerWrapper>
		</Wrapper>

	)
}
