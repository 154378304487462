import { customerApi } from '../../config/axios.config'
import { SuggestedCustomer } from '../../types/suggested-customer.interface'
import { formatPhone } from '../../utils/format-phone'

export class CustomerService {
	static async getCustomersByPhone(phone: string): Promise<SuggestedCustomer[]> {
		const formattedPhone = formatPhone(phone)

		const response = await customerApi.get(`/customers/byPhone/${formattedPhone}`)

		return response?.data
	}
}
