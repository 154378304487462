import styled from 'styled-components/macro'

export const WrapperEditProfile = styled.div`
	padding: 16px;
`

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	gap: 9px;
`

export const NameWrapper = styled.div``

export const Title = styled.h1`
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 16px;
	font-size: ${20 / 16}rem;
`

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 9px;
`
