import {
	Logo,
	NameWrapper,
	ProfileWrapper,
	Wrapper,
	Name,
	Phone,
	Email,
	TagWrapper,
	Note,
	Description,
} from './styled'
import { Waitlist } from '../../../../types/waitlist.interface'
import Tag from '../../../hostess-waitlist/components/tag'

interface ProfileProps {
	waitlist: Waitlist | undefined
}

const getInitials = (name: string | undefined): string => {
	if (!name) return ''
	const [firstName, lastName] = name.split(' ')
	const firstLetterName = firstName && firstName[0] ? firstName[0] : ''
	const firstLetterLastName = lastName && lastName[0] ? lastName[0] : ''
	return `${firstLetterName}${firstLetterLastName}`
}

const Profile = ({ waitlist }: ProfileProps) => {
	const { fullName, phone, email, note, tags } = waitlist || {}
	return (
		<Wrapper>
			<ProfileWrapper>
				<Logo>{getInitials(fullName)}</Logo>
				<NameWrapper>
					<Name>{fullName}</Name>
					{phone ? <Phone>{phone}</Phone> : <Phone>Sem telefone</Phone>}
					{email ? <Email>{email}</Email> : <Email>Sem e-mail</Email>}
				</NameWrapper>
			</ProfileWrapper>
			<TagWrapper>
				{tags &&
					tags?.map(({ display, icon, id, url }) => <Tag key={id} name={display} iconId={icon} url={url} width="13px" />)}
			</TagWrapper>
			<Note>Observações</Note>
			{note ? <Description>{note}</Description> : <Description>Sem observações</Description>}
		</Wrapper>
	)
}
export default Profile
