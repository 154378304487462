import styled from 'styled-components'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

export const FormContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin: 20px 0;
	padding: 20px 0;
	border-bottom: 1px solid var(--color-gray-mid);
	border-top: 1px solid var(--color-gray-mid);

	@media (min-width: 640px) {
		flex-wrap: nowrap;
	}
`

export const SelectorContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	margin-bottom: 10px;
	@media (min-width: 640px) {
		margin-bottom: 0;
	}
`

export const SelectorTitle = styled.h3`
	font-size: ${14 / 16}rem;
	line-height: ${18 / 16}rem;
	color: var(--color-black);
	font-weight: var(--font-weight-bold);
`

export const SelectorValue = styled.span`
	font-size: ${14 / 16}rem;
	line-height: ${18 / 16}rem;
`

interface IconContainerProps {
	rotate: boolean
}

export const IconContainer = styled.div<IconContainerProps>`
	${({ rotate }) => rotate && 'transform: rotate(180deg);'}
`

export const Selector = styled.button`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 46px;
	background-color: var(--color-white);
	border: 1px solid var(--color-gray-mid);
	border-radius: 3px;
	padding: 10px;
`

interface SubmitButtonProps {
	disabled: boolean
}

export const SubmitButton = styled.button<SubmitButtonProps>`
	${({ disabled }) =>
		disabled ? 'background-color: var(--color-gray-mid);' : 'background-color: var(--color-primary);'}
	width: 100%;
	border: none;
	border-radius: 3px;
	font-size: ${14 / 16}rem;
	line-height: ${18 / 16}rem;
	font-weight: var(--font-weight-bold);
	height: 46px;
	color: var(--color-white);
	cursor: pointer;
`

export const EmptyContainer = styled.div`
	height: 18px;
`

export const DateCalendar = styled(Calendar)``

export const ShiftContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 300px;
	margin: 20px;
	gap: 20px;
`
