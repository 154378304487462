import styled from 'styled-components/macro'
import { UnstyledButton } from '../../../../components/unstyled-button'

export const Wrapper = styled.div`
	--padding-x: 16px;
	--padding-y: 20px;
	position: relative;
	padding: var(--padding-y) var(--padding-x);
	font-size: ${14 / 16}rem;
	border: 1px solid var(--color-gray-mid);
	background: var(--color-white);
`

export const Header = styled.header`
	font-weight: var(--font-weight-bold);
`

export const ContentTabWrapper = styled.div`
	height: 248px;
	padding-right: 12px;
	margin-right: -6px;
	overflow-y: auto;
	padding-top: 10px;
`

export const Title = styled.div`
	margin-bottom: 10px;
`

export const Tabs = styled.nav`
	display: flex;
`

export const Tab = styled.li<{ active?: boolean }>`
	display: grid;
	place-content: center;
	cursor: pointer;
	font-size: inherit;
	flex: 1;
	height: 44px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	${({ active }) =>
		active
			? `
					border-left: 1px solid var(--color-gray-mid);
					border-top: 1px solid var(--color-gray-mid);
					border-right: 1px solid var(--color-gray-mid);
					color: var(--color-primary);
			  `
			: `
					border-bottom: 1px solid var(--color-gray-mid);
			  `}
`

export const Footer = styled.footer`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px 16px;
	border: 1px solid var(--color-gray-mid);
	background: var(--color-white);
`

// export const Button = styled(UnstyledButton)``

export const ButtonWrapper = styled.div`
	margin-left: calc(-1 * var(--padding-x));
	margin-right: calc(-1 * var(--padding-x));
	padding-left: var(--padding-x);
	padding-right: var(--padding-x);
	padding-top: var(--padding-y);
	border-top: 1px solid var(--color-gray-mid);
`

export const Button = styled(UnstyledButton)<{ disabled: boolean }>`
	padding: 6px 0;
	width: 100%;
	text-align: center;
	background: var(--color-primary);
	border: 2px solid var(--color-primary);
	color: var(--color-white);
	border-radius: 3px;
	${({ disabled }) => disabled && `opacity: 0.3;`}
`
