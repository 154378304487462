import { useState } from 'react'
import { InfoWrapper, Label, Radio } from './styled'
import { Delete } from '../../../../../assets/images'
import { Button, GiveUpButton, ImageWrapper, Info, Wrapper } from '../styled'
import { CancelReason } from '../../../../../types/cancel-reason.enum'

interface DeleteCustomerProps {
	name: string
	onClose: () => void
	onConfirm: (cancelReason: CancelReason) => void
}

const DeleteCustomer = ({ onClose, onConfirm, name }: DeleteCustomerProps) => {
	const [selectOption, setSelectOption] = useState<CancelReason>(CancelReason.CUSTOMER_REQUESTED)
	const color = 'var(--color-danger)'

	const handleSubmit = (event: any) => {
		event.preventDefault()
		onConfirm(selectOption)
	}

	const handleChange = (event: any) => {
		setSelectOption(event.target.value)
	}
	return (
		<Wrapper>
			<ImageWrapper>
				<Delete />
			</ImageWrapper>
			<InfoWrapper>
				<Info>Deseja excluir {name} da lista de espera?</Info>
			</InfoWrapper>
			<form onSubmit={handleSubmit} onChange={handleChange}>
				<Label>
					<Radio
						value={CancelReason.CUSTOMER_REQUESTED}
						checked={selectOption === CancelReason.CUSTOMER_REQUESTED}
					/>
					O cliente pediu para sair
				</Label>
				<Label>
					<Radio value={CancelReason.NO_SHOW} />
					No Show
				</Label>
				<Label>
					<Radio value={CancelReason.VENUE_ERROR} />
					Erro no cadastro
				</Label>
				<Label>
					<Radio value={CancelReason.OTHER} />
					Outros
				</Label>
				<Button color={color} onSubmit={handleSubmit}>
					Confirmar e excluir
				</Button>
			</form>
			<GiveUpButton color={color} onClick={onClose}>
				Desistir
			</GiveUpButton>
		</Wrapper>
	)
}

export default DeleteCustomer
