import styled from 'styled-components/macro'
import MaxWidthWrapper from '../../components/max-width-wrapper'

export const BackgroundWrapper = styled.div`
	background-color: var(--color-gray-light);
	@media (min-width: 1024px) {
		heigth: 100%;
		width: 450px;
	}
`

export const InnerWrapper = styled(MaxWidthWrapper)`
	padding: 20px 16px;
	height: 100%;

	@media (min-width: 1024px) {
		min-width: 373px;
	}
`

export const MainText = styled.div`
	font-size: 16px;
	font-weight: var(--font-weight-bold);
`

export const PreviewWrapper = styled.div`
	@media (min-width: 1024px) {
		width: 600px;
		padding: 68px 16px;
	}
`

export const Wrapper = styled.div`
	@media (min-width: 1024px) {
		display: flex;
		justify-content: center;
		background-color: #f8f8f8;
		height: 100%;
	}
`

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`
