import { WaitlistSettings } from '../types/waitlist-settings.interface'
import { getId } from '../utils/get-id'
import { DataModalContent } from '../types/data-modal-content-form.interface'
import { PartySize } from '../types/apply-filter.interface'
import { WaitlistSettingsForHostessFromApi } from '../service/waitlist-settings/types'
import { TagForHostessFromApi } from '../service/types'

export const waitlistSettingsAdapter = (
	waitlistSettings: WaitlistSettingsForHostessFromApi
): WaitlistSettings => {
	const tagsWaitingPlaces = waitlistSettings.tagsWaitingPlaces?.map(item =>
		dataModalContentAdapter(item, 'Espera')
	)
	const tagsAccommodationPlaces = waitlistSettings.tagsAccommodationPlaces?.map(item =>
		dataModalContentAdapter(item, 'Local')
	)

	return {
		tagsWaitingPlaces,
		tagsAccommodationPlaces,
		tagsPlaces: [...tagsWaitingPlaces, ...tagsAccommodationPlaces],
		partySizesForm: generatePartySizesFormWaitlist(waitlistSettings.maxPartySize),
		partySizesFilter: generatePartySizesFilter(waitlistSettings.partySizes),
		tagsPriorities: waitlistSettings.tagsPriorities?.map(item => dataModalContentAdapter(item)),
		customerTabs: {
			automatic: waitlistSettings.customerTabs.automatic,
			enabled: waitlistSettings.customerTabs.enabled,
		},
	}
}

const dataModalContentAdapter = (item: TagForHostessFromApi, prefix?: string): DataModalContent => {
	const displayName = prefix ? `${prefix}: ${item.name}` : item.name
	return {
		id: getId(),
		icon: item.icon,
		color: item.color,
		url: item.url,
		display: displayName,
		active: false,
		value: item.name,
		origin: prefix,
	}
}
const generatePartySizesFilter = (partySizes: PartySize[]): DataModalContent[] => {
	return partySizes?.map(value => ({
		id: getId(),
		icon: 'PERSON',
		display: `${value.min} - ${value.max} pessoas`,
		value: value,
		active: false,
	}))
}

const generatePartySizesFormWaitlist = (maxPartySize: number): DataModalContent[] => {
	// create an array with maxPartysize positions [1,2,3...]
	const arrayNumbers = Array.from(Array(maxPartySize), (_, i) => i + 1)
	return arrayNumbers?.map(number => ({
		id: getId(),
		display: number === 1 ? '1 pessoa' : `${number} pessoas`,
		value: number,
		active: false,
	}))
}
