export class ValidationInputAdapter {
	static checkNameIsValid(name: string): boolean {
		const regex = new RegExp(/^([A-zÁ-ú]){2,}/)
		return regex.test(name)
	}
	static checkPhoneIsValid(phone: string): boolean {
		const regex = new RegExp(/^\(\d{2}\)\s\d{4,5}-\d{4}$/)
		return regex.test(phone)
	}

	static checkEmailIsValid(email: string): boolean {
		const regex = new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)
		return regex.test(email)
	}
}
