import { Dispatch, SetStateAction, useRef, useState } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { Button } from '../../../../components/button'
import Icon from '../../../../components/icon'
import Modal from '../../../../components/modal'
import {
    ChooseImageText,
    DisplayIconOrImage,
    DisplayIconOrImageWrapper,
    HiddenFileInput,
    SecondaryText,
    Image,
    ModalContentWrapper,
    MainText,
    RangeInput,
    RangeWrapper,
    RangeLabelWrapper,
    RangeLabel,
    ImageCropWrapper,
    ButtonsWrapper,
    IconsCatalogueWrapper,
    IconWrapper,
    FooterWrapper,
    IconOrImageButtonsWrapper,
    WarningText,
} from './styled'
import { canvasPreview } from '../../../../components/crop-image/utils/canvas-preview'
import { blobToBase64 } from '../../../../components/crop-image/utils/generate-base64'
import { generateBlob } from '../../../../components/crop-image/utils/generate-blob'
import { useDebounceEffect } from '../../../../components/crop-image/utils/use-debounce'
import { HorizontalRule } from '../../../waitlist-settings/components/horinzontal-rule/styled'

export enum CropType {
    LOCKED_SQUARE = 'LOCKED_SQUARE',
    FREE_CROP = 'FREE_CROP',
}

interface CropImageProps {
    base64: string
    setBase64: Dispatch<SetStateAction<string>>
    icon: string
    setIcon: Dispatch<SetStateAction<string>>
    iconColor: string
    cropType?: CropType
}

const SelectImageOrIcon = ({ base64, setBase64, icon, setIcon, iconColor, cropType = CropType.LOCKED_SQUARE }: CropImageProps) => {
    const [imgSrc, setImgSrc] = useState('')
    const [showCropModal, setShowCropModal] = useState(false)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const [aspect, setAspect] = useState<number | undefined>(1)
    const imgRef = useRef<HTMLImageElement>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const [selectedIcon, setSelectedIcon] = useState<string>('')

    const iconsIdsCatalogue = ['ELDERLY', 'WALKER', 'WHEELCHAIR', 'PREGNANT', 'BABYSTROLLER', 'BABY', 'FILLEDCIRCLE', 'CHECK', 'CROSS', 'VIP', 'TABLE', 'CHAIR', 'SMILEBIG', 'SMILE', 'INDIFFERENT', 'SAD', 'TAG', 'VOUCHER', 'MEGAPHONE', 'GLOBE', 'PIN', 'EMPTYPIN', 'STAR', 'EMPTYSTAR', 'PARKING', 'CHEF', 'KEY', 'WATCH', 'AUTIST', 'LOCKER']

    const dimension = 200

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale)
            }
        },
        100,
        [completedCrop, scale]
    )

    const onImageLoadLockedSquare = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = e.currentTarget

        setCrop({
            unit: 'px',
            x: width / 2 - dimension / 2,
            y: height / 2 - dimension / 2,
            width: dimension,
            height: dimension,
        })
    }

    const onImageLoadFreeCrop = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }

        setAspect(undefined)
    }

    const handleCloseModal = async () => {
        if (imgRef?.current && completedCrop) {
            const blob = await generateBlob(imgRef.current, completedCrop, scale)
            const convertedBlob = (await blobToBase64(blob)) as string
            setBase64(convertedBlob)
        }

        setShowCropModal(false)
    }

    const handleCancelCrop = () => {
        setBase64('')
        setImgSrc('')
        setCompletedCrop(undefined)
        setShowCropModal(false)
    }

    const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
            reader.readAsDataURL(e.target.files[0])
            setShowCropModal(true)

            e.target.value = ''; // Reset the value of the file input to allow selecting the same file again
        }
    };

    const handleChooseImage = () => {
        fileInputRef.current?.click()
        setShowSelectIconModal(false)
    }

    const handleSetIcon = (iconId: string) => {
        setSelectedIcon(iconId)
        handleCancelCrop()
    }

    const handleConfirm = () => {
        if (selectedIcon)
            setIcon(selectedIcon)

        setShowSelectIconModal(false)
    }

    const [showSelectIconModal, setShowSelectIconModal] = useState<boolean>(false)

    return (
        <>
            <DisplayIconOrImageWrapper>
                <HiddenFileInput name="image" ref={fileInputRef} onChange={handleSelectFile} />
                <DisplayIconOrImage onClick={() => setShowSelectIconModal(true)}>
                    {completedCrop ? (
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                            }}
                        />
                    ) : (
                        <Icon id={icon} fill={iconColor} width="40px"></Icon>
                    )}
                </DisplayIconOrImage>
            </DisplayIconOrImageWrapper>

            <IconOrImageButtonsWrapper>
                <ChooseImageText onClick={() => setShowSelectIconModal(true)}>Selecionar ícone</ChooseImageText>
                {base64 && <WarningText onClick={handleCancelCrop}>Apagar Imagem</WarningText>}
            </IconOrImageButtonsWrapper>
            {showCropModal && (
                <Modal onClose={handleCancelCrop}>
                    <ModalContentWrapper>
                        <MainText>Recorte a foto</MainText>
                        {cropType === CropType.LOCKED_SQUARE ? (
                            <SecondaryText>Posicione o ângulo da imagem no quadrado central</SecondaryText>
                        ) : (
                            <SecondaryText>Recorte a foto na dimensão desejada</SecondaryText>
                        )}

                        <RangeWrapper>
                            <RangeInput value={scale} onChange={e => setScale(parseFloat(e.target.value))} />
                            <RangeLabelWrapper>
                                <RangeLabel>(-) Diminuir foto</RangeLabel>
                                <RangeLabel>Aumentar foto (+)</RangeLabel>
                            </RangeLabelWrapper>
                        </RangeWrapper>
                        <ImageCropWrapper>
                            {cropType === CropType.LOCKED_SQUARE ? (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(crop, _) => {
                                        setCrop({
                                            unit: 'px',
                                            x: crop.x,
                                            y: crop.y,
                                            width: dimension,
                                            height: dimension,
                                        })
                                    }}
                                    maxWidth={dimension}
                                    maxHeight={dimension}
                                    locked={true}
                                    onComplete={c => setCompletedCrop(c)}
                                    aspect={1}
                                >
                                    <Image
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        scale={scale}
                                        minHeight={dimension}
                                        minWidth={dimension}
                                        onLoad={onImageLoadLockedSquare}
                                    />
                                </ReactCrop>
                            ) : (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => {
                                        setCrop(percentCrop)
                                    }}
                                    aspect={aspect}
                                    onComplete={c => setCompletedCrop(c)}
                                >
                                    <Image ref={imgRef} alt="Crop me" src={imgSrc} scale={scale} onLoad={onImageLoadFreeCrop} />
                                </ReactCrop>
                            )}
                        </ImageCropWrapper>
                        <ButtonsWrapper>
                            <Button
                                width="100%"
                                height="30px"
                                onClick={handleCancelCrop}
                                bg="var(--color-white)"
                                color="var(--color-primary)"
                                border="2px solid var(--color-primary)"
                            >
                                Cancelar
                            </Button>
                            <Button
                                width="100%"
                                height="30px"
                                onClick={handleCloseModal}
                                color="var(--color-white)"
                                bg="var(--color-primary)"
                                border="none"
                            >
                                Salvar
                            </Button>
                        </ButtonsWrapper>
                    </ModalContentWrapper>
                </Modal>
            )}
            {showSelectIconModal && (<Modal onClose={() => setShowSelectIconModal(false)} >
                <ModalContentWrapper>
                    <MainText>Clique em uma imagem abaixo para selecionar uma de nossas opções do banco de ícones da Tagme</MainText>
                </ModalContentWrapper>
                <HorizontalRule />
                <ModalContentWrapper>
                    <IconsCatalogueWrapper>
                        {iconsIdsCatalogue?.map((iconId) => (<IconWrapper selected={selectedIcon === iconId} key={iconId} onClick={() => handleSetIcon(iconId)}>
                            <Icon id={iconId} width="56px" fill={"var(--color-black)"} />
                        </IconWrapper>))}
                    </IconsCatalogueWrapper>
                </ModalContentWrapper>
                <HorizontalRule />
                <ModalContentWrapper>
                    < ButtonsWrapper >
                        <Button
                            width="100%"
                            height="30px"
                            onClick={handleConfirm}
                            color="var(--color-white)"
                            bg="var(--color-primary)"
                            border="none"
                        >
                            Confirmar
                        </Button>
                    </ButtonsWrapper>
                    <FooterWrapper onClick={() => handleChooseImage()}>
                        <ChooseImageText>Escolher imagem do seu computador</ChooseImageText>
                    </FooterWrapper>
                </ModalContentWrapper>

            </Modal >)
            }
        </>
    )
}

export default SelectImageOrIcon

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    )
}
