import { customerApi } from '../../config/axios.config'
import {
	UpdateWaitlistSettingsDto,
	WaitlistSettingsForConfigurationFromApi,
	WaitlistSettingsForHostessFromApi,
} from './types'

export class WaitlistSettingsService {
	static async getWaitlistSettingsForHostess(venueId: string): Promise<WaitlistSettingsForHostessFromApi> {
		const response = await customerApi.get(`/waitlistSettings/${venueId}/hostess`)

		return response?.data
	}

	static async getWaitlistSettings(venueId: string): Promise<WaitlistSettingsForConfigurationFromApi> {
		const response = await customerApi.get(`/WaitlistSettings/${venueId}/hostess/config`)

		return response?.data
	}

	static async updateWaitlistSettings(
		venueId: string,
		payload: Partial<UpdateWaitlistSettingsDto>
	): Promise<UpdateWaitlistSettingsDto> {
		return await customerApi.put(`/WaitlistSettings/${venueId}`, payload)
	}
}
