import styled from 'styled-components'
import { PartySizeRangeProps } from '.'

export const Wrapper = styled.div`
	display: flex;
	gap: 8px;
`

export const HorizontalRule = styled.hr`
	margin: 10px 0;
	border: 1px solid var(--color-gray-mid);
`

export const ExcludePartySize = styled.span<Pick<PartySizeRangeProps, 'enabled'>>`
	display: flex;
	font-size: 12px;
	align-items: center;
	margin-left: 7px;
	font-weight: var(--font-weight-bold);
	${({ enabled }) => (enabled ? 'color: var(--color-primary)' : 'color: var(--color-gray-mid-2)')}
`

export const DropdownWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 10px 0;
`

export const Dropdown = styled.div`
	top: 30px;
	z-index: 2;
	width: 100%;
	max-height: 140px;
	overflow-y: scroll;
	position: absolute;
	border-radius: 3px;
	box-shadow: 0px 3px 5px rgb(200 200 200 / 50%);
`

export const DropdownItem = styled.div`
	font-size: 14px;
	padding: 5px 24px;
	border-bottom: 1px solid var(--color-gray-light);
	background-color: white;

	&:hover {
		cursor: pointer;
		background-color: #f8f9fa;
	}
`

export const PartySizeLableWrapper = styled.div`
	display: flex;
	padding-right: 44px;
`

export const PartySizeLabel = styled.span`
	width: 100%;
	font-size: 12px;
`

export const WarningWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	width: 104px;
	max-height: 30px;
`

export const Warning = styled.div`
	position: relative;
	color: var(--color-white);
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	margin-left: 10px;
	margin-top: 20px;
	border-radius: 3px;
	background-color: var(--color-danger);

	&::before {
		position: absolute;
		left: 0;
		top: 50%;
		width: 6px;
		height: 6px;
		content: '';
		background-color: var(--color-danger);
		transform: translate(-50%, -50%) rotate(-45deg);
	}
`

export const PartySizeWarningLabel = styled.span`
	width: 100%;
	font-size: 12px;
	margin-top: 5px;
	color: var(--color-danger);
`
