import { InputHTMLAttributes } from 'react'
import { StyledInputMask } from './styled'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	name?: string
	mask?: string | (string | RegExp)[]
	isValid?: boolean
	hasError?: boolean
	required?: boolean
}

const Input = ({ name, mask = '', isValid, hasError, required, ...delegated }: InputProps) => {
	return (
		<StyledInputMask
			name={name}
			mask={mask}
			$isValid={isValid}
			$hasError={hasError}
			$required={required}
			{...delegated}
		/>
	)
}

export default Input
