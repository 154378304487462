import styled from 'styled-components/macro'

export const InputWrapper = styled.div`
	position: relative;
	font-weight: var(--font-weight-light);
`

export const IconWrapper = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding-left: 8px;
`

export const Input = styled.input`
	width: 100%;
	height: 46px;
	padding-left: 29px;
	border-radius: 3px;
	border: 1px solid var(--color-gray-mid);
`
