import DisplayTag from '../../tag'
import { Plus } from '../styled'
import { Tag } from '../../../../../types/waitlist.interface'
import { useState } from 'react'
import { BREAKPOINTS } from '../../../../../utils/constants'
import { AppliedTags } from '../../../../../types/applied-tags.interface'

interface HandleTagsDisplayProps {
	tags: Tag[]
	appliedTags?: AppliedTags
}

const HandleTagsDisplay = ({ tags, appliedTags }: HandleTagsDisplayProps) => {
	const [size, setSize] = useState(2)
	const [sinal, setSinal] = useState('+')
	const { innerWidth: width } = window

	const handleClickPlus = () => {
		if (size === 2) {
			setSize(999)
			setSinal('-')
		} else {
			setSize(2)
			setSinal('+')
		}
	}

	if (tags && width <= BREAKPOINTS.tablet) {
		return (
			<>
				{tags.slice(0, size)?.map(({ icon, url, color, fill, display, value }, idx) => {
					const filter = appliedTags?.tags.some(tag => tag.value === value) ? true : false
					return (
						<DisplayTag
							iconId={icon}
							url={url}
							key={idx}
							fill={fill}
							color={color}
							name={display}
							height="1em"
							width="1em"
							filter={filter}
						/>
					)
				})}
				{tags.length > 2 && (
					<Plus onClick={handleClickPlus}>
						{sinal}
						{tags.length - 2}
					</Plus>
				)}
			</>
		)
	} else {
		return (
			<>
				{tags?.map(({ icon, url, fill, color, display, value }, idx) => {
					const filter = appliedTags?.tags.some(tag => tag.value === value) ? true : false
					return (
						<DisplayTag
							iconId={icon}
							url={url}
							color={color}
							key={idx}
							fill={fill}
							name={display}
							height="18px"
							width="18px"
							filter={filter}
						/>
					)
				})}
			</>
		)
	}
}

export default HandleTagsDisplay
