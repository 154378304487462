export class FullNameAdapter {
	private constructor(public fullName: string) {}

	static set(fullName: string) {
		return new FullNameAdapter(fullName)
	}

	private removeWhiteSpace() {
		this.fullName = this.fullName.replace(/\s+/g, ' ').trim()
	}

	getNameAndLastName(): [string, string] {
		this.removeWhiteSpace()
		const [name, ...lastName] = this.fullName.split(' ')
		return [name, lastName.join(' ')]
	}
}
