import ToggleSwitch from "../../../../components/toggle-switch"
import { WaitlistSettingsContext } from "../../../../contexts/waitlist-settings/waitlist-settings.context"
import { HorizontalRule } from "../horinzontal-rule/styled"
import { BackgroundWrapper, InnerWrapper } from "../section-wrapper"
import TagsList, { TagsListEnum } from "./components/tags-list"
import { memo, useContext } from 'react'


const MemoizedTagsList = memo(TagsList)

const TagsSettings = () => {
    const {
        waitingSpotsTags,
        setWaitingSpotsTags,
        accommodationSpotsTags,
        setAccommodationSpotsTags,
        priorityTags,
        setPriorityTags
    } = useContext(WaitlistSettingsContext)


    const handleToggleStatus = (type: TagsListEnum) => {
        switch (type) {
            case TagsListEnum.WAITING_SPOT:
                setWaitingSpotsTags({ ...waitingSpotsTags, enabled: !waitingSpotsTags.enabled })
                break
            case TagsListEnum.ACCOMMODATION_SPOT:
                setAccommodationSpotsTags({ ...accommodationSpotsTags, enabled: !accommodationSpotsTags.enabled })
                break
            case TagsListEnum.PRIORITY:
                setPriorityTags({ ...priorityTags, enabled: !priorityTags.enabled })
                break
        }
    }

    return (
        <BackgroundWrapper>
            <InnerWrapper>
                <ToggleSwitch state={priorityTags.enabled} handleChange={() => handleToggleStatus(TagsListEnum.PRIORITY)} margin="10px 0 20px">Prioridades</ToggleSwitch>
                {priorityTags.enabled && <>
                    <MemoizedTagsList
                        tagSettings={priorityTags}
                        setTagSettings={setPriorityTags}
                        type={TagsListEnum.PRIORITY}
                    />
                    <HorizontalRule margin="20px 0 0" />
                </>
                }


                <ToggleSwitch state={waitingSpotsTags.enabled} handleChange={() => handleToggleStatus(TagsListEnum.WAITING_SPOT)} margin="20px 0">Local de espera</ToggleSwitch>
                {waitingSpotsTags.enabled && <>
                    <MemoizedTagsList
                        tagSettings={waitingSpotsTags}
                        setTagSettings={setWaitingSpotsTags}
                        type={TagsListEnum.WAITING_SPOT}
                    />
                    <HorizontalRule margin="20px 0 0" />
                </>
                }


                <ToggleSwitch state={accommodationSpotsTags.enabled} handleChange={() => handleToggleStatus(TagsListEnum.ACCOMMODATION_SPOT)} margin="20px 0">Local de acomodação</ToggleSwitch>
                {accommodationSpotsTags.enabled && <MemoizedTagsList
                    tagSettings={accommodationSpotsTags}
                    setTagSettings={setAccommodationSpotsTags}
                    type={TagsListEnum.ACCOMMODATION_SPOT}
                />}
            </InnerWrapper>
        </BackgroundWrapper>
    )
}

export default TagsSettings