import styled from 'styled-components'

interface TextHereProps {
	disabled?: boolean
}

export const TextHere = styled.textarea<TextHereProps>`
	border: 1px solid var(--color-gray-mid);
	min-height: 82px;
	width: 100%;
	padding: 14px 10px;
	font-size: ${14 / 16}rem;
	${({ disabled }) => (disabled ? 'color: var(--color-gray-mid-2)' : 'color: var(--color-black)')};
`

export const LabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`
