import { useNavigate } from 'react-router-dom'
import Icon from '../icon'
import { GoBackText, GoBackWrapper } from './styled'

interface NavigateBackProps {
	path?: string
	handleClick?: () => {}
}

const NavigateBack = ({ path, handleClick }: NavigateBackProps) => {
	const navigate = useNavigate()

	const handleGoBack = () => {
		if (handleClick) {
			handleClick()
		}

		if (path)
			navigate(path)
	}

	return (
		<GoBackWrapper onClick={handleGoBack}>
			<Icon id="BACKARROW" height="18px" width="28px" fill="red" />
			<GoBackText>Voltar</GoBackText>
		</GoBackWrapper>
	)
}

export default NavigateBack
