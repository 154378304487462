import { ButtonIconWrapper, IconWrapper, Size } from './styled'
import Icon from '../icon'

interface ButtonIconProps {
	id: string
	height?: string
	width?: string
	mr?: string
	circle?: boolean
	bg?: string
	fill?: string
	children?: React.ReactNode
	primary?: boolean
	isValid?: boolean
	onClick?: () => void
	type?: 'button' | 'submit'
	size?: number
}

const ButtonIcon = ({
	id,
	children,
	width,
	height,
	fill,
	primary,
	isValid,
	onClick,
	type,
	size,
}: ButtonIconProps) => {
	const fillIfValid = isValid ? 'var(color-success);' : fill
	return (
		<ButtonIconWrapper primary={primary} onClick={onClick} isValid={isValid} type={type}>
			{size !== 0 && size !== undefined && <Size>{size}</Size>}
			<IconWrapper>
				<Icon id={id} fill={fillIfValid} width={width} height={height} />
			</IconWrapper>
			{children}
		</ButtonIconWrapper>
	)
}

export default ButtonIcon
