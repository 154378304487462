import styled from 'styled-components'

export const PageSelectorContainer = styled.div`
	display: flex;
	padding: 20px 0;
	gap: 10px;
`

interface PageSelectorButtonProps {
	isSelected: boolean
}

export const PageSelectorButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 30px;
	border: ${({ isSelected }: PageSelectorButtonProps) =>
		isSelected ? '2px solid var(--color-primary)' : '1px solid var(--color-gray-mid)'};
	border-radius: 5px;
	background-color: var(--color-white);
	font-size: ${12 / 16}rem;
	color: var(--color-primary);
	cursor: pointer;

	&:hover {
		scale: 1.1;
	}
`

interface ArrowContainerProps {
	left: boolean
}

export const ArrowContainer = styled.div`
	transform: ${({ left }: ArrowContainerProps) => (left ? 'rotate(90deg)' : 'rotate(-90deg)')};
	margin: 0 10px;
	cursor: pointer;

	&:hover {
		scale: 1.1;
	}
`
