import { UserRole } from '../types/user-role.type'

interface IsAuthorized {
	authorizedRole: UserRole
	userRole: UserRole
}

export const isAuthorized = ({ authorizedRole, userRole }: IsAuthorized) => {
	const hierarchySequence = [UserRole.VIEWER, UserRole.OPERATOR, UserRole.MANAGER, UserRole.ADMIN]

	const authorizedRoleHierarchy = hierarchySequence.indexOf(authorizedRole)
	const userRoleHieranchy = hierarchySequence.indexOf(userRole)

	return userRoleHieranchy >= authorizedRoleHierarchy
}
