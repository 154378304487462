export const lessThanSeconds = (arrivedAt: Date | undefined, seconds: number): boolean => {
	if (arrivedAt) {
		const secondsArrivedAt = arrivedAt.getTime() / 1000 // convert milliseconds to seconds
		const current = Date.now() / 1000
		const difference = current - secondsArrivedAt
		return difference <= seconds
	}

	return false
}
