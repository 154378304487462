import styled from 'styled-components/macro'
import { UnstyledButton } from '../unstyled-button'

export const ButtonIconWrapper = styled(UnstyledButton)<{ primary?: boolean; isValid?: boolean }>`
	width: 100%;
	position: relative;
	padding: 6px 8px;
	border-radius: 3px;
	outline: 1px solid ${({ primary }) => (primary ? `var(--color-primary);` : `var(--color-gray-mid);`)};
	${({ isValid }) => isValid && `outline: 1px solid var(--color-success);`};
	font-size: ${14 / 16}rem;

	&:focus {
		outline: 1px solid var(--color-primary);
	}
`

export const IconWrapper = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 10px;
	pointer-events: none;
`

export const Size = styled.div`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 37px;
	pointer-events: none;
	border-radius: 100%;
	color: white;
	background: var(--color-primary);
	font-weight: var(--font-weight-bold);
	font-size: ${12 / 16}rem;
	text-align: center;
	width: 17px;
	height: 17px;
`
