import styled from 'styled-components/macro'

export const Wrapper = styled.div`
	background-color: var(--color-white);
	border: 1px solid var(--color-gray-mid);
	box-sizing: border-box;
	border-radius: 3px;
	padding-top: 12px;
	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 18px;
`

export const Name = styled.div`
	font-size: 1rem;
	font-weight: var(--font-weight-bold);
`
export const Phone = styled.div`
	margin-top: 2px;
	max-width: 229px;
	font-weight: var(--font-weight-light);
	font-size: ${12 / 16}rem;
`
export const Email = styled.div`
	margin-top: -3px;
	max-width: 229px;
	height: 18px;
	font-weight: var(--font-weight-light);
	font-size: ${12 / 16}rem;
`

export const Note = styled.div`
	margin-top: 12px;
	font-weight: var(--font-weight-bold);
	font-size: ${14 / 16}rem;
	width: 100%;
`

export const Description = styled.div`
	margin-top: 5px;
	font-weight: var(--font-weight-light);
	font-size: ${14 / 16}rem;
	width: 100%;
`
export const Logo = styled.div`
	height: 80px;
	aspect-ratio: 1;
	border-radius: 5px;
	background-color: var(--color-primary);
	color: var(--color-white);
	font-size: ${34 / 16}rem;
	font-weight: var(--font-weight-bold);
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
`

export const NameWrapper = styled.div``

export const ProfileWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`

export const TagWrapper = styled.div`
	margin-top: 12px;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
`
