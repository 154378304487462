import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

interface TextWrapperInterface {
	isActive?: boolean
}

export const TextWrapper = styled.span<TextWrapperInterface>`
	${({ isActive }) => (isActive ? 'color: var(--color-success)' : 'color: var(--color-gray-mid-2)')};
`
