import styled from 'styled-components'

export const DailyScheduleCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	border: 1px solid var(--color-gray-mid);
	border-radius: 3px;
	padding: 10px;
`
