import styled from 'styled-components/macro'

export const CardWrapper = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 0;
	list-style: none;
`

export const NotFound = styled.p`
	display: flex;
	align-items: center;
	padding: 10px;
	font-size: ${14 / 16}rem;
	background: var(--color-red-light);
	color: var(--color-danger);
	border: 1px solid var(--color-red-mid);
	border-radius: 3px;
	height: 38px;
`
