import { Button } from '../button'

import Modal from '../modal'
import { Container, MessageText } from './styled'

interface FailModalProps {
    message?: string
    onClose: () => void
}



const FailModal = ({ message, onClose }: FailModalProps) => {
    return (
        <Modal onClose={onClose}>
            <Container>
                <MessageText>
                    {message ? message : 'Algo deu errado. Entre em contato com o suporte.'}
                </MessageText>
                <Button width='70px' height='40px' bg='var(--color-primary)' color='var(--color-white)' onClick={onClose}>Ok</Button>
            </Container>
        </Modal>

    )
}

export default FailModal
