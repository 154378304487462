import { CharactersCounter } from './styled'

interface CharacterCounterProps {
	disabled?: boolean
	maxLength: number
	state: string
}

const CharacterCounter = ({ disabled, maxLength, state }: CharacterCounterProps) => {
	return (
		<CharactersCounter disabled={disabled}>
			{state?.length || 0}/{maxLength} caracteres
		</CharactersCounter>
	)
}

export default CharacterCounter
