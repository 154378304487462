import styled from 'styled-components/macro'
import { IconProps } from '.'

// Component created in order to encapsulated images and defined sizes
// and optionally included a circle around

export const Wrapper = styled.div<Omit<IconProps, 'id'>>`
	${({ height }) => height && `height: ${height};`};
	${({ width }) => width && `width: ${width};`};
	${({ circle, bg }) => circle && `border-radius: 50%; background-color: ${bg || 'var(--color-gray-light)'};`}
	color: ${({ fill }) => (fill === 'primary' ? 'var(--color-primary);' : fill)};
	${({ mr }) => mr && `margin-right: ${mr};`};
	${({ py }) => py && `padding-top: ${py}; padding-bottom: ${py};`};
	${({ px }) => px && `padding-left: ${px}; padding-right: ${px};`};
	display: flex;
	justify-content: center;
	align-items: center;
	& > svg {
		${({ strokeWidth }) => strokeWidth && `stroke-width: ${strokeWidth};`};
	}
`
