import TimeRange from '../time-range'
import { DailyScheduleCardWrapper } from './styled'
import { TimeRangeInterface } from '../../../../../../types/time-range.interface'
import ToggleSwitch, { Size } from '../../../../../../components/toggle-switch'
import { WeekDay } from '../../../../../../types/week-day.enum'
import { DailySchedule } from '../../../../../../contexts/waitlist-settings/types'



interface DailyScheduleCardPropsInterface {
    startRange: string
    finishRange: string
    active: boolean
    day: WeekDay,
    onChange: ({ timeRange, active }: DailySchedule) => void
}

const getDayName = (day: WeekDay) => {
    let dayOfTheWeek = ''

    switch (day) {
        case WeekDay.MONDAY:
            dayOfTheWeek = 'Segunda-feira'
            break
        case WeekDay.TUESDAY:
            dayOfTheWeek = 'Terça-feira'
            break
        case WeekDay.WEDNESDAY:
            dayOfTheWeek = 'Quarta-feira'
            break
        case WeekDay.THURSDAY:
            dayOfTheWeek = 'Quinta-feira'
            break
        case WeekDay.FRIDAY:
            dayOfTheWeek = 'Sexta-feira'
            break
        case WeekDay.SATURDAY:
            dayOfTheWeek = 'Sábado'
            break
        case WeekDay.SUNDAY:
            dayOfTheWeek = 'Domingo'
            break
    }

    return dayOfTheWeek
}

const DailyScheduleCard = ({
    startRange,
    finishRange,
    active,
    day,
    onChange
}: DailyScheduleCardPropsInterface) => {

    const handleTimeRangeChange = ({ startRange, finishRange }: TimeRangeInterface) => {
        onChange({ timeRange: { openingTime: startRange, closingTime: finishRange }, active })
    }

    const handleActiveChange = () => {
        onChange({ timeRange: { openingTime: startRange, closingTime: finishRange }, active: !active })
    }

    return (
        <DailyScheduleCardWrapper>
            <ToggleSwitch state={active} handleChange={handleActiveChange} size={Size.SMALL}>{getDayName(day)}</ToggleSwitch >
            {active && <TimeRange
                startRange={startRange}
                finishRange={finishRange}
                onChange={handleTimeRangeChange}
            />}
        </DailyScheduleCardWrapper>
    )
}

export default DailyScheduleCard
