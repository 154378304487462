import { useContext } from 'react'
import { WaitlistSettingsContext } from '../../../../contexts/waitlist-settings/waitlist-settings.context'
import { OrderTabsMode } from '../../../../contexts/waitlist-settings/types'
import ToggleSwitch from '../../../../components/toggle-switch'
import { BackgroundWrapper, InnerWrapper } from '../section-wrapper'
import Dropdown from '../../../../components/dropdown'
import { SettingsLabel } from '../label-wrapper'
import { Input, WarningLabel } from './styled'
import { MarginBottom } from '../margin-botton/styled'
import { generateTimeOptions } from '../../../../utils/generate-time-options'
import { validateCustomerTabs } from '../../../../utils/validate-customer-tabs'

const CustomerTabsSettings = () => {
	const {
		tabsControl,
		setTabsControl,
		tabsOperationMode,
		setTabsOperationMode,
		firstTabNumber,
		setFirstTabNumber,
		lastTabNumber,
		setLastTabNumber,
		finishFirstShift,
		setFinishFirstShift,
		finishSecondShift,
		setFinishSecondShift,
	} = useContext(WaitlistSettingsContext)

	const validation = validateCustomerTabs(firstTabNumber, lastTabNumber)

	const hasInitialTabNumberInconsistency = validation.hasInitialTabNumberInconsistency
	const hasFinalTabNumberInconsistency = validation.hasFinalTabNumberInconsistency

	return (
		<BackgroundWrapper>
			<InnerWrapper>
				<MarginBottom margin="10px;" />
				<ToggleSwitch state={tabsControl} setState={setTabsControl}>
					Controle de comandas
				</ToggleSwitch>
				<MarginBottom margin="10px;" />

				{tabsControl && (
					<>
						<SettingsLabel>Tipo de comanda</SettingsLabel>
						<Dropdown
							state={tabsOperationMode}
							setState={setTabsOperationMode}
							options={Object.values(OrderTabsMode)}
							height="46px"
							enabled={true}
							placeHolder="Selecione horário"
						/>
						<MarginBottom margin="20px;" />
						{tabsOperationMode === OrderTabsMode.AUTOMATIC && (
							<>
								<SettingsLabel>Número inicial das Comandas (Obrigatório)</SettingsLabel>
								<Input
									type="number"
									step={1}
									value={firstTabNumber}
									placeholder="Número inicial"
									alert={hasInitialTabNumberInconsistency}
									onChange={(e: any) => setFirstTabNumber(e.target.value)}
								/>
								{hasInitialTabNumberInconsistency && <WarningLabel>Número inicial das comandas é obrigatório.</WarningLabel>}
								<MarginBottom margin="10px;" />

								<SettingsLabel>Número final das Comandas (Opcional)</SettingsLabel>
								<Input
									type="number"
									min={firstTabNumber}
									step={1}
									value={lastTabNumber || ''}
									placeholder="Número final"
									alert={hasFinalTabNumberInconsistency}
									onChange={(e: any) => setLastTabNumber(e.target.value)}
								/>
								{hasFinalTabNumberInconsistency && <WarningLabel>Número final das comandas precisa ser superior ao número inicial. (Mínimo {firstTabNumber})</WarningLabel>}
								<MarginBottom margin="10px;" />

								<SettingsLabel>Horário Final do Primeiro Turno (Obrigatório)</SettingsLabel>
								<Dropdown
									state={finishFirstShift}
									setState={setFinishFirstShift}
									options={generateTimeOptions({ max: finishSecondShift === 'Não configurado' ? '24:00' : finishSecondShift })}
									height="46px"
									enabled={true}
									placeHolder="Selecione horário"
								/>

								<MarginBottom margin="10px;" />

								{finishFirstShift && < SettingsLabel>Horário Final do Segundo Turno (Opcional)</SettingsLabel>}

								{finishFirstShift && <Dropdown
									state={finishSecondShift}
									setState={setFinishSecondShift}
									options={['Não configurado', ...generateTimeOptions({ min: finishFirstShift })]}
									height="46px"
									enabled={true}
									placeHolder="Selecione modelo de exibição"
								/>}
							</>
						)}

					</>
				)}
			</InnerWrapper>
		</BackgroundWrapper>
	)
}

export default CustomerTabsSettings
