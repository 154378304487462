import styled from 'styled-components/macro'
import MaxWidthWrapper from '../../components/max-width-wrapper'

export const HistoryCustomersWrapper = styled(MaxWidthWrapper)`
	margin-top: 19px;
`

export const Title = styled.h1`
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: ${20 / 16}rem;
	font-weight: var(--font-weight-bold);
`

export const DateWrapper = styled.div`
	position: relative;
	padding: 6px 8px;
	background-color: var(--color-gray-mid);
	border-radius: 3px;
	font-size: ${14 / 16}rem;
	margin-bottom: 10px;
`

export const IconWrapper = styled.div`
	position: absolute;
	right: 8px;
`

export const TodaysDate = styled.p``
