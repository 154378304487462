import styled from 'styled-components'

interface TagCardWrapperProps {
	enabled: boolean
}

export const TagCardWrapper = styled.div<TagCardWrapperProps>`
	display: flex;
	align-items: center;
	position: relative;
	flex-direction: column;
	width: 92px;
	height: 114px;
	border-radius: 3px;
	border: 1px solid var(--color-gray-mid);
	${({ enabled }) => enabled && 'border-color: var(--color-success)'};
`

export const IconWrapper = styled.div<TagCardWrapperProps>`
	display: flex;
	justify-content: center;
	margin-top: 11px;
	align-items: center;
	width: 39px;
	height: 39px;
	border-radius: 50%;
	border: 1px solid var(--color-gray-mid);
	${({ enabled }) => enabled && 'border-color: var(--color-success)'};
	overflow: hidden;
`

export const SwitchWrapper = styled.div`
	height: 25px;
	position: absolute;
	display: flex;
	justify-content: center;
	bottom: 0;
	width: 100%;
	border-top: 1px solid var(--color-gray-mid);
	color: red;
	gap: 5px;
`

export const TextWrapper = styled.span`
	color: var(--color-black);
	text-align: center;
	font-size: ${12 / 16}rem;
`

export const SmallTextWrapper = styled.span`
	color: var(--color-black);
	font-size: ${10 / 16}rem;
	display: flex;
	align-items: center;
`

export const EmptyDiv = styled.div`
	display: flex;
	align-items: center;
`

export const CloseIconWrapper = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	background-color: var(--color-danger);
	border-radius: 50%;
	transform: translate(40%, -40%);
`

export const Image = styled.img``
