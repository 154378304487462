import { useState } from "react"
import { DateCalendar, EmptyContainer, FormContainer, IconContainer, Selector, SelectorContainer, SelectorTitle, SelectorValue, ShiftContainer, SubmitButton } from "./styled"
import Icon from "../../../../components/icon"
import { Form, Shift } from "../../types"
import Modal from "../../../../components/modal"
import { Value } from "react-calendar/dist/cjs/shared/types"
import CheckRadio from "../../../../components/check-radio"
import { formatDate } from "../../../../utils/format-date"

const formatShift = (shift: Shift) => {
    let formattedShiftName: string

    switch (shift) {
        case Shift.MORNING:
            formattedShiftName = "Manhã"
            break;
        case Shift.AFTERNOON:
            formattedShiftName = "Almoço"
            break;
        case Shift.EVENING:
            formattedShiftName = "Jantar"
            break;
        default:
            formattedShiftName = "Todos"
            break;
    }

    return formattedShiftName
}
interface ReportsFormProps {
    onSubmit: (form: Form) => void
}

const ReportsForm = (props: ReportsFormProps) => {
    const { onSubmit } = props

    const [shift, setShift] = useState<Shift>(Shift.ALL)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [openShiftModal, setOpenShiftModal] = useState<boolean>(false)
    const [openCalendar, setOpenCalendar] = useState<boolean>(false)

    const formattedStartDate = formatDate(startDate)
    const formattedEndDate = formatDate(endDate)
    const formattedShift = formatShift(shift)
    const isButtonDisabled = !startDate || !endDate || !shift

    const handleDatesRangeChange = (value: Value) => {
        if (value instanceof Date || value === null) {
            return
        }
        const [startDateRange, endDateRange] = value
        if (startDateRange instanceof Date && endDateRange instanceof Date) {
            setStartDate(startDateRange);
            setEndDate(endDateRange);
        }
    }

    const handleSubmit = () => {
        if (!startDate || !endDate || !shift) return
        onSubmit({ shift, startDate, endDate })
    }

    return <FormContainer>
        <SelectorContainer>
            <SelectorTitle>Tipo</SelectorTitle>
            <Selector>
                <SelectorValue>Lista de espera</SelectorValue>
                <IconContainer rotate={false}>
                    <Icon id="ARROWDOWN" fill="var(--color-primary)" width="15px" />
                </IconContainer>
            </Selector>
        </SelectorContainer>
        <SelectorContainer>
            <SelectorTitle>Turno</SelectorTitle>
            <Selector onClick={() => setOpenShiftModal(!openShiftModal)}>
                <SelectorValue>{formattedShift}</SelectorValue>
                <IconContainer rotate={openShiftModal}>
                    <Icon id="ARROWDOWN" fill="var(--color-primary)" width="15px" />
                </IconContainer>
            </Selector>
        </SelectorContainer>
        <SelectorContainer>
            <SelectorTitle>Data de início</SelectorTitle>
            <Selector onClick={() => setOpenCalendar(true)}>
                <SelectorValue>{formattedStartDate}</SelectorValue>
                <IconContainer rotate={openCalendar}>
                    <Icon id="ARROWDOWN" fill="var(--color-primary)" width="15px" />
                </IconContainer>
            </Selector>
        </SelectorContainer>
        <SelectorContainer>
            <SelectorTitle>Data do fim</SelectorTitle>
            <Selector onClick={() => setOpenCalendar(true)}>
                <SelectorValue>{formattedEndDate}</SelectorValue>
                <IconContainer rotate={openCalendar}>
                    <Icon id="ARROWDOWN" fill="var(--color-primary)" width="15px" />
                </IconContainer>
            </Selector>
        </SelectorContainer>
        <SelectorContainer>
            <EmptyContainer />
            <SubmitButton onClick={handleSubmit} disabled={isButtonDisabled}>
                Buscar Relatório
            </SubmitButton>
        </SelectorContainer>
        {openCalendar && <Modal onClose={() => setOpenCalendar(false)} fitContent={true}>
            <DateCalendar value={[startDate, endDate]} selectRange={true} onChange={handleDatesRangeChange} maxDate={new Date()} />
        </Modal>}
        {openShiftModal && <Modal onClose={() => setOpenShiftModal(false)} fitContent>
            <ShiftContainer>
                <SelectorTitle>Selectione um turno</SelectorTitle>
                <CheckRadio selected={shift === Shift.ALL} onClick={() => setShift(Shift.ALL)} > Todos</CheckRadio>
                <CheckRadio selected={shift === Shift.MORNING} onClick={() => setShift(Shift.MORNING)} > Manhã (5:00h até 11:59h)</CheckRadio>
                <CheckRadio selected={shift === Shift.AFTERNOON} onClick={() => setShift(Shift.AFTERNOON)} > Almoço (12:00h até 16:59h)</CheckRadio>
                <CheckRadio selected={shift === Shift.EVENING} onClick={() => setShift(Shift.EVENING)} > Jartar (17:00h até 4:59h)</CheckRadio>
            </ShiftContainer>

        </Modal>}
    </FormContainer>

}

export default ReportsForm