import styled from 'styled-components'

export const DataSavedContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const DynamicIconWrapper = styled.div`
	margin: 20px auto;
`

export const TextWrapper = styled.span`
	font-size: ${18 / 16}rem;
	font-weight: var(--font-weight-bold);
	margin-bottom: 57px;
`
