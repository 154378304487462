import React from 'react'
import ReactDOM from 'react-dom'
import Icon from '../icon'
import { Backdrop, IconWrapper, ModalContent, ModalWrapper } from './styled'

interface ModalProps {
	children?: React.ReactNode
	onClose: () => void
	fitContent?: boolean
}

const Modal = ({ children, onClose, fitContent }: ModalProps) => {
	const backdropDiv = document.getElementById('backdrop-root')!
	const modalDiv = document.getElementById('modal-root')!
	return (
		<>
			{ReactDOM.createPortal(<Backdrop onClick={onClose} />, backdropDiv)}
			{ReactDOM.createPortal(
				<ModalWrapper fitContent={fitContent}>
					<IconWrapper onClick={onClose}>
						<Icon id='CLOSE' fill='white' width='28px' />
					</IconWrapper>
					<ModalContent>{children}</ModalContent>
				</ModalWrapper>,
				modalDiv
			)}
		</>
	)
}

export default Modal
