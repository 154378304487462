import styled from 'styled-components/macro'
import MaxWidthWrapper from '../max-width-wrapper'

// const fadeIn = keyframes``

export const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
`
interface ModalWrapperProps {
	fitContent?: boolean
}

export const ModalWrapper = styled(MaxWidthWrapper)<ModalWrapperProps>`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 90%;
	padding: 0;
	border-radius: 3px;
	background-color: var(--color-gray-light);
	${({ fitContent }) => fitContent && 'width: fit-content;'}

	@media (min-width: 768px) {
		max-width: 70%;
	}
`

export const IconWrapper = styled.div`
	position: absolute;
	top: -35px;
	right: 3px;
`

export const ModalContent = styled.div`
	max-height: 80vh;
	border-radius: 3px;
	overflow-y: auto;
`
