// Socket.io
import io from 'socket.io-client'

interface WebsocketOptions {
	query: {
		venueId: string
	}
	path: string
	transports: string[]
}

export function connectWebsocket(url: string, options: WebsocketOptions) {
	return io(url, options)
}
