import styled from 'styled-components/macro'
import MaxWidthWrapper from '../max-width-wrapper'
import { UnstyledButton } from '../unstyled-button'

export const Wrapper = styled(MaxWidthWrapper).attrs({ as: 'header' })`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	color: var(--color-black);
	padding: 16px;
	gap: 20px;
	background-color: var(--color-white);
`

export const Left = styled.div`
	margin-right: auto;
`

export const WrapperTitle = styled.div`
	display: flex;
	align-items: baseline;
	gap: 15px;
`

export const Venue = styled.h1`
	font-size: ${20 / 16}rem;
	font-weight: var(--font-weight-bold);
	padding-bottom: 5px;
`

export const TodaysDate = styled.p`
	font-size: ${12 / 16}rem;
	font-weight: var(--font-weight-light);
`

export const FilterWrapper = styled(UnstyledButton)`
	position: relative;
`

export const NumberTags = styled.div`
	display: grid;
	place-items: center;
	position: absolute;
	top: 0;
	right: 0;
	transform: translateY(-50%) translateX(25%);
	background: var(--color-primary);
	border-radius: 50%;
	width: 15px;
	aspect-ratio: 1;
	color: var(--color-white);
	font-size: ${12 / 16}rem;
	font-weight: var(--font-weight-bold);
`

export const NavigateHeader = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
`

export const NavigateHeaderText = styled.p`
	font-size: ${12 / 16}rem;
	margin-top: 10px;
	color: var(--color-gray-mid-2);
`

export const NavigateOptionsContainer = styled.ul`
	display: flex;
	flex-direction: column;
	padding: 0;
	gap: 10px;
	width: 100%;
	margin: 20px 0;
`

interface NavigateOptionCardProps {
	selected?: boolean
}

export const NavigateOptionCard = styled.li<NavigateOptionCardProps>`
	display: flex;
	position: relative;
	align-items: center;
	justify-content: start;
	height: 70px;
	gap: 20px;
	padding: 10px 20px;
	margin: 0px 20px;
	${({ selected }) =>
		selected ? 'border: 1px solid var(--color-primary);' : 'border: 1px solid var(--color-gray-mid);'};
	border-radius: 3px;
	cursor: pointer;
`

export const NavigateOptionText = styled.p`
	font-size: ${14 / 16}rem;
	font-weight: var(--font-weight-bold);
	max-width: 130px;
`

export const NavigateAnchor = styled.a`
	text-decoration: none;
	color: inherit;
	outline: 0;
	cursor: auto;
`

export const TagContainer = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	right: -10px;
`

export const TagArrow = styled.div`
	min-width: 10px;
	min-height: 10px;
	transform: rotate(45deg);
	background-color: var(--color-primary);
	margin-right: -6px;
	z-index: -1;
`

export const TagBody = styled.div`
	display: flex;
	padding: 2px 5px;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary);
	border-radius: 3px;
	min-width: 95px;
`

export const TagText = styled.p`
	font-size: ${10 / 16}rem;
	color: var(--color-white);
`

export const VenueOptionText = styled.p`
	font-size: ${14 / 16}rem;
	font-weight: var(--font-weight-bold);
`

interface IconContainerProps {
	rotate: boolean
}

export const IconContainer = styled.div<IconContainerProps>`
	display: flex;
	cursor: pointer;
	${({ rotate }) => rotate && 'transform: rotate(180deg);'};
`
