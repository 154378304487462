export class AlertFactory {
	static generalInformation() {
		return {
			enabled: false,
			message: {
				pt: '',
				en: '',
			},
		}
	}

	static generalInformationWidget() {
		return {
			enabled: false,
			message: {
				pt: '',
				en: '',
			},
		}
	}

	static priorityClient() {
		return {
			enabled: false,
			message: {
				pt: '',
				en: '',
			},
		}
	}

	static standartClient() {
		return {
			enabled: false,
			message: {
				pt: '',
				en: '',
			},
		}
	}

	static callSoon() {
		return {
			enabled: false,
			message: {
				pt: '',
				en: '',
			},
		}
	}
}
