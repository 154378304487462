import styled from 'styled-components/macro'
import { Player } from '@lottiefiles/react-lottie-player'
import { Info, Wrapper } from '../styled'
import okSimple from '../../../../../assets/lotties/ok-simple.json'

interface ConfirmMessageProps {
	message: string
}

const ConfirmMessage = ({ message }: ConfirmMessageProps) => {
	return (
		<Wrapper>
			<Player autoplay loop src={okSimple}></Player>
			<Info>{message}</Info>
			<Space />
		</Wrapper>
	)
}

export default ConfirmMessage

const Space = styled.div`
	padding-bottom: 47px;
`
