import { TagDB } from '../service/types'
import { WaitlistFromApi } from '../service/waitlist/types'
import { getId } from '../utils/get-id'
import { DataModalContent } from '../types/data-modal-content-form.interface'
import { Tag, Waitlist } from '../types/waitlist.interface'

export const tagsAdapterToFront = (tags: TagDB[]): Tag[] => {
	return tags?.map(tag => {
		return {
			id: getId(),
			icon: tag.logo.icon,
			url: tag.logo.url,
			color: tag.logo.color,
			display: tag.name,
			value: tag.name,
			active: true,
		}
	})
}

export const tagsAdapterToBd = (tags: DataModalContent[]): TagDB[] => {
	return tags?.map(tag => {
		return {
			name: tag.value,
			logo: { icon: tag.icon, url: tag.url, color: tag.color },
		}
	})
}

export const phoneAdapter = (phoneBd: string | undefined) => {
	if (!phoneBd) return

	const phoneWithoutCountry = phoneBd.slice(3)
	const phoneFormatted = `(${phoneWithoutCountry.slice(0, 2)}) ${phoneWithoutCountry.slice(
		2,
		7
	)}-${phoneWithoutCountry.slice(7)}`
	return phoneFormatted
}

export const waitlistAdapter = (waitlist: WaitlistFromApi): Waitlist => {
	const tagsPriorities = waitlist?.tagsPriorities ? tagsAdapterToFront(waitlist.tagsPriorities) : []
	const tagsWaitingPlaces = waitlist?.tagsWaitingPlaces ? tagsAdapterToFront(waitlist.tagsWaitingPlaces) : []
	const tagsAccommodationPlaces = waitlist?.tagsAccommodationPlaces
		? tagsAdapterToFront(waitlist.tagsAccommodationPlaces)
		: []
	const tags: Tag[] = [...tagsPriorities, ...tagsWaitingPlaces, ...tagsAccommodationPlaces]

	return {
		id: waitlist._id,
		globalPosition: waitlist.globalPosition,
		position: waitlist.position,
		fullName: waitlist.customer.fullName,
		phone: phoneAdapter(waitlist.customer?.phone),
		email: waitlist.customer?.email,
		partySize: waitlist.partySize,
		customerTab: waitlist.customerTab,
		note: waitlist.note,
		table: waitlist.table,
		origin: waitlist.origin.label === 'Restaurant' ? 'Restaurante' : waitlist.origin.label,
		tags: tags,
		tagsPriorities,
		tagsPlaces: [...tagsWaitingPlaces, ...tagsAccommodationPlaces],
		priority: waitlist?.priority,
		arrivedAt: new Date(waitlist.arrivedAt),
		logs: waitlist.logs?.map(log => ({ ...log, created_at: new Date(log.created_at) })),
		notifiedAt: waitlist?.notifiedAt ? new Date(waitlist.notifiedAt) : undefined,
		canceledAt: waitlist?.canceledAt ? new Date(waitlist.canceledAt) : undefined,
		cancelReason: waitlist?.cancelReason,
		seatedAt: waitlist?.seatedAt ? new Date(waitlist.seatedAt) : undefined,
		history: waitlist?.history,
	}
}

export const waitlistListAdapter = (waitlistList: WaitlistFromApi[]): Waitlist[] =>
	waitlistList?.map(item => waitlistAdapter(item))
