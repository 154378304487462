import { Dispatch, SetStateAction } from 'react'
import CharacterCounter from '../character-counter'
import LanguageToggle from '../language-toggle'
import { SettingsLabel } from '../../pages/waitlist-settings/components/label-wrapper'
import { SelectedLanguageEnum } from '../../pages/waitlist-settings/components/section-iterface-alerts'
import { LabelWrapper, TextHere } from './styled'

interface SettingsTextAreaProps {
	state: string
	setState?: Dispatch<SetStateAction<string>>
	language?: SelectedLanguageEnum
	setLanguage?: Dispatch<SetStateAction<SelectedLanguageEnum>>
	children?: string
	disabled?: boolean
	maxLength?: number
	hasCharacterCounter?: boolean
	handleChange?: (e: any) => void
}

const WaitlistSettingsTextArea = ({
	state,
	setState,
	language,
	setLanguage,
	children: label,
	disabled,
	maxLength,
	hasCharacterCounter,
	handleChange,
}: SettingsTextAreaProps) => {
	const handleTextChange = (e: any) => {
		if (setState) {
			setState(e.target.value)
		}

		if (handleChange) {
			handleChange(e)
		}
	}

	return (
		<>
			<LabelWrapper>
				<SettingsLabel disabled={disabled}>{label}</SettingsLabel>
				{language && setLanguage && <LanguageToggle language={language} setLanguage={setLanguage} />}
			</LabelWrapper>
			<TextHere onChange={e => handleTextChange(e)} value={state} maxLength={maxLength} disabled={disabled} />
			{hasCharacterCounter && maxLength && (
				<CharacterCounter disabled={disabled} maxLength={maxLength} state={state} />
			)}
		</>
	)
}

export default WaitlistSettingsTextArea
