import { Dispatch, SetStateAction, useContext } from 'react'
import { WaitlistSettingsContext } from '../../../../contexts/waitlist-settings/waitlist-settings.context'
import { EmailTemplateInterface } from '../../../../contexts/waitlist-settings/types'
import { BackgroundWrapper, InnerWrapper } from '../section-wrapper'
import ToggleSwitch from '../../../../components/toggle-switch'
import WaitlistSettingsTextArea from '../../../../components/text-area'
import { HorizontalRule } from '../horinzontal-rule/styled'
import { MainText, StyledLink, ChildrenWrapper } from './styled'


export enum SelectedLanguageEnum {
	PT = 'pt',
	EN = 'en',
}

export enum EmailTemplatesType {
	ENTRY = 'welcome',
	CALL = 'call',
	DELAY = 'delay',
	SEAT = 'seat',
	CANCEL = 'cancel',
}

const MessageSettings = () => {
	const {
		smsActive,
		setSmsActive,
		smsEntry,
		setSmsEntry,
		smsEntryMessage,
		setSmsEntryMessage,
		smsCall,
		setSmsCall,
		smsCallMessage,
		setSmsCallMessage,
		smsDelay,
		setSmsDelay,
		smsDelayMessage,
		setSmsDelayMessage,
		smsCancel,
		setSmsCancel,
		smsCancelMessage,
		setSmsCancelMessage,
		smsSeat,
		setSmsSeat,
		smsSeatMessage,
		setSmsSeatMessage,
		emailActive,
		setEmailActive,
		entryEmailTemplate,
		setEntryEmailTemplate,
		callEmailTemplate,
		setCallEmailTemplate,
		delayEmailTemplate,
		setDelayEmailTemplate,
		seatEmailTemplate,
		setSeatEmailTemplate,
		cancelEmailTemplate,
		setCancelEmailTemplate,
	} = useContext(WaitlistSettingsContext)

	const handleToggleSwitchForEmails = (setState: Dispatch<SetStateAction<EmailTemplateInterface>>) => {
		setState(prevState => ({ ...prevState, enabled: !prevState.enabled }))
	}

	return (
		<BackgroundWrapper>
			<InnerWrapper>
				<ToggleSwitch state={smsActive} setState={setSmsActive} margin="10px 0 0">
					Envio de SMS
				</ToggleSwitch>
				{smsActive && (<>

					<HorizontalRule margin="20px 0 0" />

					<ToggleSwitch state={smsEntry} setState={setSmsEntry} disabled={!smsActive} margin="20px 0 0">
						Mensagem para o cliente que entrou na lista
					</ToggleSwitch>
					<WaitlistSettingsTextArea
						state={smsEntryMessage}
						setState={setSmsEntryMessage}
						maxLength={100}
						hasCharacterCounter={true}
						disabled={!smsActive || !smsEntry}
					>
						Texto máximo 100 caracteres
					</WaitlistSettingsTextArea>

					<HorizontalRule margin="20px 0" />

					<ToggleSwitch state={smsCall} setState={setSmsCall} disabled={!smsActive}>
						Mensagem para chamar o cliente
					</ToggleSwitch>
					<WaitlistSettingsTextArea
						state={smsCallMessage}
						setState={setSmsCallMessage}
						maxLength={130}
						hasCharacterCounter={true}
						disabled={!smsActive || !smsCall}
					>
						Texto máximo 130 caracteres
					</WaitlistSettingsTextArea>

					<HorizontalRule margin="20px 0" />

					<ToggleSwitch state={smsDelay} setState={setSmsDelay} disabled={!smsActive}>
						Mensagem para o cliente atrasado
					</ToggleSwitch>
					<WaitlistSettingsTextArea
						state={smsDelayMessage}
						setState={setSmsDelayMessage}
						maxLength={130}
						hasCharacterCounter={true}
						disabled={!smsActive || !smsDelay}
					>
						Texto máximo 130 caracteres
					</WaitlistSettingsTextArea>

					<HorizontalRule margin="20px 0" />

					<ToggleSwitch state={smsCancel} setState={setSmsCancel} disabled={!smsActive}>
						Mensagem para o cliente cancelado
					</ToggleSwitch>
					<WaitlistSettingsTextArea
						state={smsCancelMessage}
						setState={setSmsCancelMessage}
						maxLength={130}
						hasCharacterCounter={true}
						disabled={!smsActive || !smsCancel}
					>
						Texto máximo 130 caracteres
					</WaitlistSettingsTextArea>

					<HorizontalRule margin="20px 0" />

					<ToggleSwitch state={smsSeat} setState={setSmsSeat} disabled={!smsActive}>
						SMS cliente sentado
					</ToggleSwitch>
					<WaitlistSettingsTextArea
						state={smsSeatMessage}
						setState={setSmsSeatMessage}
						maxLength={130}
						hasCharacterCounter={true}
						disabled={!smsActive || !smsSeat}
					>
						Texto máximo 130 caracteres
					</WaitlistSettingsTextArea>
				</>)}

			</InnerWrapper>
			<HorizontalRule />
			<InnerWrapper>
				<ToggleSwitch state={emailActive} setState={setEmailActive} margin="10px 0 0">
					Envio de Emails
				</ToggleSwitch>
				{emailActive && (
					<>
						<HorizontalRule margin="20px 0 0" />
						<ToggleSwitch state={entryEmailTemplate.enabled} handleChange={() => handleToggleSwitchForEmails(setEntryEmailTemplate)} disabled={!emailActive} margin="20px 0 10px">
							<ChildrenWrapper>
								<MainText>Cliente entrou na lista de espera</MainText>
								<StyledLink to={`/email/${EmailTemplatesType.ENTRY}`}>Editar Visual</StyledLink>
							</ChildrenWrapper>
						</ToggleSwitch>
						<ToggleSwitch state={callEmailTemplate.enabled} handleChange={() => handleToggleSwitchForEmails(setCallEmailTemplate)} disabled={!emailActive} margin="10px 0">
							<ChildrenWrapper>
								<MainText>Chamar cliente</MainText>
								<StyledLink to={`/email/${EmailTemplatesType.CALL}`}>Editar Visual</StyledLink>
							</ChildrenWrapper>
						</ToggleSwitch>
						<ToggleSwitch state={delayEmailTemplate.enabled} handleChange={() => handleToggleSwitchForEmails(setDelayEmailTemplate)} disabled={!emailActive} margin="10px 0">
							<ChildrenWrapper>
								<MainText>Cliente atrasado</MainText>
								<StyledLink to={`/email/${EmailTemplatesType.DELAY}`}>Editar Visual</StyledLink>
							</ChildrenWrapper>
						</ToggleSwitch>
						<ToggleSwitch state={seatEmailTemplate.enabled} handleChange={() => handleToggleSwitchForEmails(setSeatEmailTemplate)} disabled={!emailActive} margin="10px 0">
							<ChildrenWrapper>
								<MainText>Cliente sentou</MainText>
								<StyledLink to={`/email/${EmailTemplatesType.SEAT}`}>Editar Visual</StyledLink>
							</ChildrenWrapper>
						</ToggleSwitch>
						<ToggleSwitch state={cancelEmailTemplate.enabled} handleChange={() => handleToggleSwitchForEmails(setCancelEmailTemplate)} disabled={!emailActive} margin="10px 0 20px">
							<ChildrenWrapper>
								<MainText>Saiu da fila (cancelamento)</MainText>
								<StyledLink to={`/email/${EmailTemplatesType.CANCEL}`}>Editar Visual</StyledLink>
							</ChildrenWrapper>
						</ToggleSwitch>
					</>
				)}
			</InnerWrapper>
		</BackgroundWrapper >
	)
}

export default MessageSettings
