import styled from 'styled-components'

interface CharactersCounterProps {
	disabled?: boolean
}

export const CharactersCounter = styled.span<CharactersCounterProps>`
	margin-top: 5px;
	font-style: italic;
	font-size: ${12 / 16}rem;
	${({ disabled }) => (disabled ? 'color: var(--color-gray-mid-2)' : 'color: var(--color-black)')};
`
