import styled from 'styled-components/macro'

export const Label = styled.label`
	--form-control-color: rebeccapurple;
	font-family: system-ui, sans-serif;
	/* font-size: 2rem; */
	font-size: ${12 / 16}rem;
	display: grid;
	grid-template-columns: 30px 1fr;
	align-items: center;
	gap: 10px;

	& + & {
		margin-top: 10px;
	}

	&:first-of-type {
		margin-top: 30px;
	}

	&:last-of-type {
		margin-bottom: 30px;
	}

	@media (min-width: 1000px) {
		& + & {
			margin-top: 5px;
		}
	}
`

export const Radio = styled.input.attrs({ type: 'radio', name: 'radio' })`
	appearance: none;
	background: var(--color-white);
	font: inherit;
	color: currentColor;
	width: 30px;
	height: 30px;
	border: 1px solid var(--color-gray-mid);
	border-radius: 50%;

	display: grid;
	place-content: center;

	&::before {
		content: '';
		width: 16px;
		height: 16px;
		border-radius: 50%;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em var(--color-danger);
	}

	&:checked:before {
		transform: scale(1);
	}

	&:hover {
		cursor: pointer;
	}
`

export const InfoWrapper = styled.div`
	width: 60%;
`
