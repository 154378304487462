import styled from 'styled-components'
import MaxWidthWrapper from '../../components/max-width-wrapper'

export const CreateNewTagWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: var(--color-gray-light);
`

export const BackgroundWrapper = styled.div`
	background-color: var(--color-white);
	border-radius: 3px;
	margin: 0px 16px;
`

export const InnerWrapper = styled(MaxWidthWrapper)`
	padding: 20px 16px;
	display: flex;
	flex-direction: column;
	font-size: ${14 / 16}rem;
	flex: 1;
	height: 100%;
`

export const MainText = styled.span`
	font-size: ${16 / 16}rem;
	font-weight: var(--font-weight-bold);
	color: var(--color-black);
	margin-bottom: 20px;
`

export const TagNameText = styled.span`
	font-size: ${12 / 16}rem;
	font-weight: var(--font-weight-bold);
	height: ${14 / 16}rem;
	margin-top: 5px;
`

export const InputLabelWrapper = styled.div`
	margin-top: 25px;
	display: flex;
`

export const Input = styled.input`
	margin-top: 15px;
	border: 1px solid var(--color-gray-mid);
	border-radius: 3px;
	height: 46px;
	padding: 0 10px;
	font-size: 14px;
`

export const ButtonWrapper = styled.div`
	margin-top: 30px;
	display: flex;
	height: 46px;
`

export const IconWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 3px;
	align-items: center;
	height: 78px;
	width: 78px;
	border: 1px dashed var(--color-gray-mid-2);
	margin-right: 10px;
`
