import styled from 'styled-components/macro'
import InputMask from 'react-input-mask'

export const StyledInputMask = styled(InputMask)<{
	$required?: boolean
	$hasError?: boolean
	$isValid?: boolean
}>`
	border: none;
	border-radius: 3px;
	padding: 6px 8px;
	font-size: ${14 / 16}rem;
	width 100%;
	min-width: 100px;
	outline: 1px solid	${({ $required }) => ($required ? `var(--color-primary);` : `var(--color-gray-mid);`)}
	${({ $hasError, $required }) => $hasError && $required && `outline: 2px solid var(--color-danger);`}
	${({ $isValid }) => $isValid && `outline: 1px solid var(--color-success);`}

	&::placeholder {
		font-style: italic;
		font-weight: var(--font-weight-light);
	}

	/* &:focus { */
	/* 	outline: 1px solid var(--color-primary); */
	/* } */
`
