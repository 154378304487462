import { PartySize } from '../types/apply-filter.interface'
import { Waitlist } from '../types/waitlist.interface'

export class FilterWaitlistAdapter {
	private waitlistList: Waitlist[]
	constructor(waitlistList: Waitlist[]) {
		this.waitlistList = [...waitlistList]
	}

	get waitlist() {
		return this.waitlistList
	}

	filterByTags(tagsFilterValues: string[]) {
		if (tagsFilterValues.length === 0) return this

		const filteredByTags: Waitlist[] = []
		for (const waitlist of this.waitlistList) {
			const waitlistTagValues: string[] = waitlist.tags?.map(({ value }) => value)

			if (tagsFilterValues.every((tag: string) => waitlistTagValues.includes(tag))) {
				filteredByTags.push(waitlist)
			}
		}
		return new FilterWaitlistAdapter(filteredByTags)
	}

	filterByName(name: string) {
		if (name.length === 0) return this

		const nameLowerCase = name.toLowerCase()

		return new FilterWaitlistAdapter(
			this.waitlistList.filter(({ fullName }) => fullName.toLowerCase().includes(nameLowerCase))
		)
	}

	filterByPartySizes(partySizes: PartySize[]) {
		if (partySizes.length === 0) return this

		const filteredByPartySizes: Waitlist[] = []
		for (const waitlist of this.waitlistList) {
			const { partySize } = waitlist
			if (partySizes.some(({ min, max }) => partySize >= min && partySize <= max)) {
				filteredByPartySizes.push(waitlist)
			}
		}

		return new FilterWaitlistAdapter(filteredByPartySizes)
	}
}
