import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'react-image-crop/dist/ReactCrop.css'
import { ChromePicker } from 'react-color'
import { customerApi } from '../../config/axios.config'
import { WaitlistSettingsContext } from '../../contexts/waitlist-settings/waitlist-settings.context'
import { VenueContext } from '../../contexts/venue/venue.context'
import { Button } from '../../components/button'
import CharacterCounter from '../../components/character-counter'
import NavigateBack from '../../components/navigate-back'
import {
	BackgroundWrapper,
	CreateNewTagWrapper,
	InnerWrapper,
	Input,
	InputLabelWrapper,
	TagNameText,
	ButtonWrapper,
	MainText,
	IconWrapper,
} from './styled'
import { TagsListEnum } from '../waitlist-settings/components/section-tags/components/tags-list'
import Icon from '../../components/icon'
import { MarginBottom } from '../waitlist-settings/components/margin-botton/styled'
import { AlertWrapper } from '../../components/alert-wrapper'
import Modal from '../../components/modal'
import SelectImageOrIcon from './components/select-icon-or-image'

const CreateNewTag: React.FC = () => {
	const { type } = useParams()
	const { venueId } = useContext(VenueContext)

	const [icon, setIcon] = useState<string>('')


	let tagLabel: string

	const handleDefaultIcon = () => {
		switch (type) {
			case TagsListEnum.WAITING_SPOT:
				setIcon('EMPTYPIN')
				break
			case TagsListEnum.ACCOMMODATION_SPOT:
				setIcon('GLOBE')
				break
			default:
				setIcon('TAG')
				break
		}
	}

	switch (type) {
		case TagsListEnum.WAITING_SPOT:
			tagLabel = 'local de espera'
			break
		case TagsListEnum.ACCOMMODATION_SPOT:
			tagLabel = 'local de acomodação'
			break
		default:
			tagLabel = 'prioridade'
	}

	if (!icon) handleDefaultIcon()

	const [openSelectIconModal, setOpenSelectIconModal] = useState<boolean>(false)

	const [iconColor, setIconColor] = useState<string>('#000')

	const handleColorChanged = (color: { hex: string }) => {
		setIconColor(color.hex)
	}

	const [tagName, setTagName] = useState('')
	const navigate = useNavigate()
	const { waitingSpotsTags, setWaitingSpotsTags, accommodationSpotsTags, setAccommodationSpotsTags, priorityTags, setPriorityTags } =
		useContext(WaitlistSettingsContext)


	const [base64, setBase64] = useState<string>('')

	const handleGoBack = () => {
		navigate(`/settings/${venueId}`)
	}

	const handleSubmit = async () => {
		switch (type) {
			case TagsListEnum.WAITING_SPOT:
				const newWaitingSpotsTagsSttings = {
					...waitingSpotsTags,
					tags: [...waitingSpotsTags.tags, { label: tagName, icon, color: iconColor, active: true }],
				}
				setWaitingSpotsTags(newWaitingSpotsTagsSttings)
				break
			case TagsListEnum.ACCOMMODATION_SPOT:
				const newAccomModationSpotsTagsSettings = {
					...accommodationSpotsTags,
					tags: [...accommodationSpotsTags.tags, { label: tagName, icon, color: iconColor, active: true }],
				}
				setAccommodationSpotsTags(newAccomModationSpotsTagsSettings)
				break
			case TagsListEnum.PRIORITY:
				if (base64) {
					const response = await customerApi.post(`/image-upload/${venueId}`, {
						image: base64,
						folder: 'TagImage',
					})

					const newPriorityTagsList = {
						...priorityTags,
						tags: [...priorityTags.tags, { label: tagName, url: response?.data.url, active: true }],
					}

					setPriorityTags(newPriorityTagsList)
					break
				}

				const newPriorityTagsList = {
					...priorityTags,
					tags: [...priorityTags.tags, { label: tagName, icon, color: iconColor, active: true }],
				}
				setPriorityTags(newPriorityTagsList)
				break
		}
		handleGoBack()
	}

	const handleIconDisplay = () => {
		if (type === TagsListEnum.PRIORITY) {
			return <SelectImageOrIcon base64={base64} setBase64={setBase64} icon={icon} setIcon={setIcon} iconColor={iconColor}></SelectImageOrIcon>
		}

		return <IconWrapper><Icon id={icon} width="56px" fill={iconColor} /></IconWrapper>
	}

	return (
		<>
			<CreateNewTagWrapper>
				<NavigateBack path={`/settings/${venueId}`} />

				<BackgroundWrapper>
					<InnerWrapper>
						<MainText>Criar nova Tag de {tagLabel}</MainText>
						{handleIconDisplay()}


						{base64 ? <AlertWrapper>
							Não é possível alterar a cor de imagens enviadas pelo seu computador.
						</AlertWrapper> : <>
							<InputLabelWrapper>
								<TagNameText>Cor do ícone</TagNameText>
							</InputLabelWrapper>
							<MarginBottom margin="20px" />

							<ChromePicker color={iconColor} onChangeComplete={handleColorChanged} />

						</>
						}

						<InputLabelWrapper>
							<TagNameText>Nome da Tag</TagNameText>
						</InputLabelWrapper>
						<Input
							maxLength={20}
							value={tagName}
							placeholder="Digite o nome da Tag"
							onChange={(e: any) => setTagName(e.target.value)}
						/>
						<CharacterCounter state={tagName} maxLength={20} />
						<ButtonWrapper>
							<Button
								disabled={!tagName || !icon}
								width="100%"
								height="100%"
								primary={true}
								border="none"
								onClick={handleSubmit}
							>
								Salvar
							</Button>
						</ButtonWrapper>
					</InnerWrapper>
				</BackgroundWrapper>
			</CreateNewTagWrapper>
			{openSelectIconModal && <Modal onClose={() => setOpenSelectIconModal(false)} >teste</Modal>}
		</>
	)
}

export default CreateNewTag
