import Icon from '../../../../../../components/icon'
import ToggleSwitch, { Size } from '../../../../../../components/toggle-switch'
import {
	CloseIconWrapper,
	EmptyDiv,
	IconWrapper,
	SmallTextWrapper,
	SwitchWrapper,
	TagCardWrapper,
	TextWrapper,
	Image,
} from './styled'

interface TagCardProps {
	item: {
		url?: string
		icon?: string
		color?: string
		label: string
		active: boolean
	}
	handleToggleTag: () => void
	handleDeleteTag: () => void
}

const TagCard = ({ item: { active, icon, color, url, label }, handleToggleTag, handleDeleteTag }: TagCardProps) => {
	return (
		<TagCardWrapper enabled={active}>
			<IconWrapper enabled={active}>
				{icon && <Icon id={icon} fill={color} width="25px" />}
				{url && <Image src={url} alt='Tag Logo' />}
			</IconWrapper>
			<TextWrapper>{label}</TextWrapper>
			<CloseIconWrapper onClick={handleDeleteTag}>
				<Icon id={'CLOSE'} fill='var(--color-white)' width='10px' height='10px'></Icon>
			</CloseIconWrapper>
			<SwitchWrapper>
				<SmallTextWrapper>off</SmallTextWrapper>
				<EmptyDiv>
					<ToggleSwitch state={active} size={Size.SMALL} handleChange={handleToggleTag} />
				</EmptyDiv>
				<SmallTextWrapper>on</SmallTextWrapper>
			</SwitchWrapper>
		</TagCardWrapper >
	)
}

export default TagCard
